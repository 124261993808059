import React from "react";
import {WarningIcon} from "./WarningIcon";
import {CrossIcon} from "./CrossIcon";


type PropTypes = {
    title: string
    copy?: React.ReactNode
    onClose?: () => void
}

export function Warning(props: PropTypes) {
    return <div className={["flex rounded flex-row bg-red-100 p-3", !props.copy ? "items-center" : ""].join(" ")}>
        <div className={"rounded-full flex-shrink-0 text-red-600 bg-red-200 mb-auto p-1.5 mr-2 items-center justify-center"}>
            <WarningIcon className={"h-6 w-6"} />
        </div>
        <div className={"flex-1"}>
            <div className={"flex items-center justify-between"}>
                <h3 className={["text-red-600 font-bold", !props.copy ? "m-0" : ""].join(" ")}>{props.title}</h3>
                {props.onClose ? <CrossIcon

                    onClick={props.onClose}
                    className={"ml-2 w-5 h-5 text-red-600 font-bold cursor-pointer"}/> : null}
            </div>
            {props.copy ? <p className={"text-red-600 m-0"}>
                {props.copy}
            </p> : null}
        </div>
    </div>
}