import {IApolloCredentials} from "./IApolloCredentials";
import {ApolloLink} from "@apollo/client";
import {getFirebaseCurrentUser} from "../../auth/user/getFirebaseUser";
import {setContext} from "@apollo/client/link/context";

export class FirebaseUserCredentials implements IApolloCredentials {
    getHash(): string {
        return "firebase-user-credentials";
    }

    injectAuth(link: ApolloLink): ApolloLink {
        const setAuthorizationLink = setContext(async (request, previousContext) => {
            const user = await getFirebaseCurrentUser()
            return {
                headers: {
                    ...previousContext.headers || {},
                    authorization: user ? await user.getIdToken(true) : undefined
                }
            }
        });


        return setAuthorizationLink.concat(link)
    }

}