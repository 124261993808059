import React, {useState} from "react";
import {SlotRangeInput} from "../TakeAwaySettings/SlotRangeInput/SlotRangeInput";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import {Moment} from "moment-timezone";
import {Time} from "../../../ristopub/Time";
import _ from "lodash";
import {nanoid} from "nanoid";
import {TimeRange} from "./TimeRange";


type DeleteIconProps = {
    onClick?: () => void
}

function DeleteIcon(props: DeleteIconProps) {
    return <svg onClick={props.onClick} className={"hover:text-red-800 text-red-600 w-4 cursor-pointer"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
}


type PropTypes = {
    initialRanges?: TimeRange[]
    initialAllDayClosed?: boolean
    dayOfWeek: number
    dayName: string
    onChangeRanges?: (ranges: TimeRange[]) => void
    onChangeAllDayClosed?: (val: boolean) => void
}

export function OpenDayCard(props: PropTypes) {

    const [ranges, setRanges] = useState<TimeRange[]>(props.initialRanges || [])
    const [allDayClosed, setAllDayClosed] = useState(props.initialAllDayClosed || false)


    function updateRanges(newRanges: TimeRange[]) {
        const ranges = sortRanges(newRanges)
        setRanges(ranges)

        if (props.onChangeRanges) props.onChangeRanges(ranges)
    }

    function newRange() {
        const range: TimeRange = {
            start: Time.create({
                time: "00:00:00",
            }),
            end: Time.create({
                time: "00:00:00",
            }),
            id: nanoid()
        }



        const newRanges = _.cloneDeep(ranges)

        updateRanges([
            ...newRanges,
            range
        ])


    }

    function sortRanges(ranges: TimeRange[]) {
        return _.sortBy(ranges, r => {
            return r.start.valueOf()
        })
    }



    function deleteRange(id: string) {
        const newRanges = _.cloneDeep(ranges)

        _.remove(newRanges, r => r.id === id)

        updateRanges(newRanges)
    }

    function onChangeRange([start, end]: [Moment, Moment], rangeIndex: number) {


        const startTime = Time.fromMoment(start)
        const endTime = Time.fromMoment(end)

        const newRanges = _.cloneDeep(ranges)

        newRanges[rangeIndex].start = startTime
        newRanges[rangeIndex].end = endTime


        updateRanges([
            ...newRanges
        ])
    }


    function onChangeAllDayClosed(e: React.ChangeEvent<HTMLInputElement>) {
        setAllDayClosed(e.target.checked)
        if (props.onChangeAllDayClosed) props.onChangeAllDayClosed(e.target.checked)
    }

    return <div className={"p-2 rounded bg-gray-200 h-full flex flex-col"}>
        <h4 className={"text-lg font-bold text-center"}>{props.dayName}</h4>
        <div className={"flex items-center justify-between"}>
            <label>Chiudi tutto il giorno</label>
            <input checked={allDayClosed} onChange={onChangeAllDayClosed} type="checkbox" className="form-checkbox" />
        </div>
        <div className={"h-px bg-black opacity-10 mt-1 mb-2"}/>
        {
            _.map(
                ranges,
                ({start, end, id}, rangeIndex) => {
                    return <div className={"mb-1 flex items-center"}  key={id}>
                        <div className={"w-full"}>
                            <SlotRangeInput

                                defaultValue={[
                                    start.getMoment(),
                                    end.getMoment()
                                ]}

                                onChange={(range) => {
                                    onChangeRange(range, rangeIndex)
                                }}
                            />
                        </div>

                        <span className={"ml-1"}>
                            <DeleteIcon onClick={() => {
                                deleteRange(id)
                            }}/>
                        </span>

                    </div>

                }
            )
        }

        <div className={"flex justify-center mt-auto"}>
            <SwippyButton disabled={allDayClosed} onClick={newRange} type={"link"}>
                Aggiungi fascia oraria
            </SwippyButton>
        </div>

    </div>
}