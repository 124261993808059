import {gql, useMutation} from "@apollo/client";
import {Product} from "./classes/Product";
import {GET_PRODUCTS_QUERY} from "./ProductsList/GetProductsQuery";
import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import {Category} from "../CategoriesScreen/classes/Category";
import {useState} from "react";

const DELETE_PRODUCT_MUTATION = gql`
    mutation deleteProduct($input: DeleteProductInput!) {
        deleteProduct(input: $input)
    }
`


export function useDeleteProduct() {
    const [deleteProductMutation] = useMutation(DELETE_PRODUCT_MUTATION)
    const [deletingProduct, setDeletingProduct] = useState(false)
    async function deleteProduct(product: Product, category: Category) {
        try {
            setDeletingProduct(true)
            await deleteProductMutation({
                variables: {
                    input: {
                        productId: product.id
                    }
                },
                update: (cache) => {

                    const data = cache.readQuery<{
                        getProducts: Product[]
                    }>({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            input: {
                                categoryId: category.id
                            }
                        }
                    });



                    const newData = {
                        getProducts: data?.getProducts.filter((p) => p.id !== product.id)
                    }
                    cache.writeQuery({
                        query: GET_PRODUCTS_QUERY,
                        data: newData,
                        variables: {
                            input: {
                                categoryId: category.id
                            }
                        }
                    });
                }
            })
        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            console.error(e)
            throw e
        } finally {
            setDeletingProduct(false)
        }
    }


    return {
        deleteProduct,
        deletingProduct
    }
}