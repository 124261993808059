import {gql, useApolloClient} from "@apollo/client";
import {CustomerEmailType} from "./CustomerEmailType";
import {EmailConfigDTO, RistopubEmailConfigDTO} from "./RistopubEmailConfigDTO";
import {useState} from "react";
import {GraphqlServiceName} from "../../apolloClient/GraphqlServiceName";

const CUSTOMER_EMAIL_PREVIEW_QUERY = gql`
    query renderTestPreview($input: CustomerEmailTestPreviewInput!) {
        renderTestPreview(input: $input)
    }
`

export function useRenderCustomerEmailTestPreview() {
    const client = useApolloClient()
    const [loading, setLoading] = useState(false)

    async function renderTestPreview (
        ristopubName: string,
        type: CustomerEmailType,
        emailConfig: RistopubEmailConfigDTO
    ): Promise<string> {
        setLoading(true)
        try {
            const res = await client.query<{
                renderTestPreview: string
            }, {
                input: {
                    ristopubName: string,
                    type: CustomerEmailType,
                    emailConfig: RistopubEmailConfigDTO
                }
            }>({
                fetchPolicy: "network-only",
                query: CUSTOMER_EMAIL_PREVIEW_QUERY,
                variables: {
                    input: {
                        ristopubName,
                        type,
                        emailConfig
                    }
                },
                context: {
                    serviceName: GraphqlServiceName.NOTIFICATION_SERVER
                }
            })


            return res.data.renderTestPreview
        } finally {
            setLoading(false)
        }
    }


    return {
        renderTestPreview,
        loading
    }
}