import {gql, useQuery} from "@apollo/client";
import {CustomerEmailTemplateRendererDTO} from "./CustomerEmailTemplateRendererDTO";
import {useRef} from "react";
import {CustomerEmailTemplateRendererDataMapper} from "./CustomerEmailTemplateRendererDataMapper";
import _ from "lodash";

const GET_ALL_CUSTOMER_TEMPLATE_RENDERERS_QUERY = gql`

    query getAllCustomerEmailTemplateRenderers {
    
        getAllCustomerEmailTemplateRenderers {
            customerEmailType
            variables {
                name
                description
            }
        }
      
    }
`


export function useAllCustomerEmailTemplateRenderers() {
    const {data, loading} = useQuery<{
        getAllCustomerEmailTemplateRenderers: CustomerEmailTemplateRendererDTO[]
    }>(GET_ALL_CUSTOMER_TEMPLATE_RENDERERS_QUERY)



    const {current: dataMapper} = useRef(new CustomerEmailTemplateRendererDataMapper())



    return {
        renderers: _.map(data?.getAllCustomerEmailTemplateRenderers, tr => {
            return dataMapper.toEntity(tr)
        }),
        loading: loading
    }
}