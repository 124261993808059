import {gql, useQuery} from "@apollo/client";
import {RistopubEmailConfigDTO} from "./RistopubEmailConfigDTO";
import {useRef} from "react";
import {RistopubEmailConfigDataMapper} from "./RistopubEmailConfigDataMapper";

const GET_EMAIL_CONFIG_QUERY = gql`
    query getRistopubEmailConfig {
        getRistopubEmailConfig {
            mailConfigs {
                wysiwygJsonState
                type
            }
            ristopubId
            logoSrc
        }
    }
`
export function useEmailConfig() {
    const {data, loading} = useQuery<{
        getRistopubEmailConfig: RistopubEmailConfigDTO
    }>(GET_EMAIL_CONFIG_QUERY)


    const {current: dataMapper} = useRef(new RistopubEmailConfigDataMapper())


    return {
        emailConfig: data?.getRistopubEmailConfig ? dataMapper.toEntity(data.getRistopubEmailConfig) : undefined,
        loading
    }

}