import {ProductModifier} from "./ProductModifier";
import React, {useEffect, useState} from "react";
import {ProductModifierChoice} from "./ProductModifierChoice";
import _ from "lodash";
import {SwippyModal} from "../components/SwippyModal/SwippyModal";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";
import {SwippyInput} from "../components/SwippyInput/SwippyInput";
import {SwippyInputNumber} from "../components/SwippyInputNumber/SwippyInputNumber";
import {SwippySelect} from "../components/SwippySelect/SwippySelect";
import {Select} from "antd";
import {ProductModifierChoicesInput} from "./ProductModifierChoicesInput";
import {ProductModifierType} from "./ProductModifierType";

type PropTypes = {
    mod?: ProductModifier
    onClose?: () => void
    onSave?: (modifier: ProductModifier) => void
    loading?: boolean
    deleting?: boolean
    enableTypeSwitch?: boolean
}

export function EditModifierModal(props: PropTypes) {


    const [type, setType] = useState<ProductModifierType>(ProductModifierType.MULTIPLE_CHOICE)
    const [name, setName] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [required, setRequired] = useState(false)
    const [maxOptions, setMaxOptions] = useState<number | undefined>()
    const [choices, setChoices] = useState<ProductModifierChoice[]>([])
    const [unlimitedChoices, setUnlimitedChoices] = useState(false)

    const [showNameRequiredBorder, setShowNameRequiredBorder] = useState(false)

    useEffect(() => {
        if (!props.mod) return
        setName(props.mod.name)
        setDescription(props.mod.description)
        setRequired(!!props.mod.required)
        setMaxOptions(props.mod.maxSelectableChoices)
        setChoices(props.mod.choices)
        setType(props.mod.type)
        const maxQty = props.mod.maxSelectableChoices ?? 0
        setUnlimitedChoices(maxQty <= 0)
    }, [props.mod])

    async function save() {
        const modifier = _.cloneDeep(props.mod)
        if (!modifier) throw new Error(`Missing modifier in save`)
        if (!name || !name.length) return setShowNameRequiredBorder(true)

        modifier.name = name
        modifier.type = type
        modifier.description = description
        modifier.choices = choices
        modifier.required = required
        modifier.maxSelectableChoices = unlimitedChoices ? 0 : maxOptions


        if (props.onSave) props.onSave(modifier)
    }

    const emptyChoices = !choices || choices.length <= 0

    return <SwippyModal

        title={"Modificatore di prodotto"}
        footer={<div className={"flex justify-end"}>
            <SwippyButton
                disabled={emptyChoices}
                onClick={save}
                loading={props.loading}
                type={"primary"}>
                Salva
            </SwippyButton>
        </div>}
        onCancel={props.onClose}
        destroyOnClose={true}
        visible={!!props.mod}>
        <div className={""}>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 flex flex-col font-bold"}>
                    <span>Tipo modificatore</span>
                    { !props.enableTypeSwitch ? <span className={"text font-normal text-red-600"}>Non è possibile modificare il tipo di un modificatore già esistente</span> : null }
                </label>
                <SwippySelect
                    disabled={!props.enableTypeSwitch}
                    onChange={v => setType(v)}
                    value={type}>
                    <Select.Option value={ProductModifierType.MULTIPLE_CHOICE}>Scelta multipla</Select.Option>
                    <Select.Option value={ProductModifierType.MULTIPLE_CHOICE_WITH_QTY}>Scelta multipla con quantità</Select.Option>
                </SwippySelect>
            </div>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 font-bold"}>
                    Nome <span className={"text-red-600"}>*</span>
                </label>
                <SwippyInput
                    className={
                        showNameRequiredBorder ? "border-red-600" : undefined
                    }
                    onChange={e => {
                        if (showNameRequiredBorder) setShowNameRequiredBorder(false)
                        setName(e.target.value)
                    }}
                    value={name}/>
            </div>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 font-bold"}>
                    Descrizione
                </label>
                <SwippyInput
                    onChange={e => setDescription(e.target.value)}
                    value={description}/>
            </div>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 font-bold"}>
                    Scelte illimitate
                </label>
                <SwippySelect
                    onChange={v => setUnlimitedChoices(v === "yes")}
                    value={unlimitedChoices ? "yes" : "no"}>
                    <Select.Option value={"yes"}>Sì</Select.Option>
                    <Select.Option value={"no"}>No</Select.Option>
                </SwippySelect>
            </div>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 font-bold"}>
                    Massimo numero di scelte
                </label>
                <SwippyInputNumber
                    disabled={unlimitedChoices}
                    onChange={v => setMaxOptions(parseInt(v as string))}
                    value={maxOptions}
                />
            </div>
            <div className={"flex-col flex mb-4"}>
                <label className={"mb-1 font-bold"}>
                    Obbligatorio
                </label>
                <SwippySelect
                    onChange={v => setRequired(v === "yes")}
                    value={required ? "yes" : "no"}>
                    <Select.Option value={"yes"}>Sì</Select.Option>
                    <Select.Option value={"no"}>No</Select.Option>
                </SwippySelect>
            </div>
            <div className={"flex flex-col"}>
                <label className={"mb-1 font-bold"}>
                    Opzioni
                </label>

                <ProductModifierChoicesInput
                    withMaxQty={type === ProductModifierType.MULTIPLE_CHOICE_WITH_QTY}
                    value={choices || []} onChange={(newChoices) => {
                    setChoices(newChoices)
                }}/>
            </div>

        </div>
    </SwippyModal>
}