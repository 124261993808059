import {useGetModifiersOfProduct} from "./useGetModifiersOfProduct";
import {Product} from "../classes/Product";
import {useAllModifiers} from "../../../ProductModifier/useAllModifiers";
import {SwippySelect} from "../../../components/SwippySelect/SwippySelect";
import React, {useState} from "react";
import {Select} from "antd";
import {ProductModifier} from "../../../ProductModifier/ProductModifier";
import {Loading} from "../../../components/Loading/Loading";
type ProductModifiersInputComponentProps = {
    allModifiers: ProductModifier[],
    productModifiers: ProductModifier[]
    onChange: (newModifiers: string[]) => void
}


function ProductModifiersInputComponent(props: ProductModifiersInputComponentProps) {

    const [selectedModifiers, setSelectedModifiers] = useState<string[]>(props.productModifiers.map(m => m.id))

    function onChange(v: string[]) {
        setSelectedModifiers(v)
        props.onChange(v)
    }

    return <SwippySelect
        mode="multiple"
        onChange={onChange}
        value={selectedModifiers}
    >
        {
            props.allModifiers.map(m => {
                return <Select.Option key={m.id} value={m.id}>
                    {m.name}
                </Select.Option>
            })
        }
    </SwippySelect>
}

type Props = {
    product?: Product
    onChange: (newModifiers: string[]) => void
}


export function ModifiersOfProductInput(props: Props) {
    const {modifiers: productModifiers, loading: loadingModifiersOfProduct} = useGetModifiersOfProduct(props.product?.id)
    const {modifiers: allModifiers, loading: loadingAllModifiers} = useAllModifiers()

    const loading = loadingAllModifiers || loadingModifiersOfProduct

    if (loading) return <div className={"flex justify-center"}>
        <Loading size={30}/>
    </div>

    return <ProductModifiersInputComponent
        onChange={props.onChange}
        allModifiers={allModifiers}
        productModifiers={productModifiers}/>


}