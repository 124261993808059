import {ProductDTO} from "./ProductDTO";
import {Product} from "./Product";
import {CategoryDataMapper} from "../../CategoriesScreen/classes/CategoryDataMapper";

export class ProductDataMapper {


    private readonly categoryDataMapper: CategoryDataMapper
    constructor(categoryDataMapper?: CategoryDataMapper) {
        this.categoryDataMapper = categoryDataMapper || new CategoryDataMapper()
    }

    toProduct(dto: ProductDTO) {
        return Product.create({
            subTitle: dto.subTitle,
            name: dto.name,
            id: dto.id,
            image: dto.image,
            price: dto.price,
            published: dto.published,
            description: dto.description,
            pos: dto.pos,
            category: this.categoryDataMapper && dto.category ? this.categoryDataMapper.toCategory(dto.category) : undefined
        })
    }
}