import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from "moment-timezone"
import 'moment/locale/it';
import "dayjs/locale/it"
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import it from 'date-fns/locale/it';
import {registerLocale} from "react-datepicker";

registerLocale('it', it)
dayjs.locale("it")
moment.locale("it")

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
