import React from "react";
import {Product} from "../../../classes/Product";
import styles from "./ProductItem.module.scss"
import {ProductImagePreview} from "../../../ProductImagePreview/ProductImagePreview";
import {SwippyListItem} from "../../../../../components/SwippyListItem/SwippyListItem";
import {formatPrice} from "../../../../../formatPrice/formatPrice";
import {Badge, Card} from "antd"


type PropTypes = {
    product: Product
    onClick?: (product: Product) => void
    onDelete?: (product: Product) => void | Promise<void>
}


export function ProductItem (props: PropTypes) {

    const swippyListItem =     <SwippyListItem

        editGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_open_edit_product"
        }}
        deleteGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_delete_product"
        }}
        clickGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_click_product"
        }}

        onDelete={async () => {
            if (props.onDelete) await props.onDelete(props.product)
        }}
        onClick={() => {
            if (props.onClick) props.onClick(props.product)
        }}>
        <ProductImagePreview
            width={70}
            height={70}
            className={styles.productImage}
            src={props.product.getImageSrc()}/>

        <div className={styles.titleAndPrice}>
            <span className={"font-bold"}>{props.product.name}</span>
            {props.product.subTitle ? <span>{props.product.subTitle}</span> : null}
            <span className={styles.productPrice}>{formatPrice(props.product.price)} €</span>

        </div>
    </SwippyListItem>

    return props.product.published ?  <Badge.Ribbon text={"Pubblicato"} color={"green"} placement={"end"}>
        {swippyListItem}
    </Badge.Ribbon> : swippyListItem
}