import {gql, useMutation} from "@apollo/client";
import _ from "lodash";

const CREATE_STRIPE_ONBOARDING_LINK = gql`
    mutation cc {
        createStripeOnboardingAccountLink {
            link
        } 
    }
`

export function useCreateStripeOnboardingLink() {
    const [createStripeOnboardingLinkMutation, {
        loading
    }] = useMutation(CREATE_STRIPE_ONBOARDING_LINK)



    async function createStripeOnboardingLink() {
        const res = await createStripeOnboardingLinkMutation()
        return _.get(res.data, "createStripeOnboardingAccountLink.link") as string | undefined
    }


    return {
        createStripeOnboardingLink,
        creatingStripeOnboardingLink: loading
    }
}