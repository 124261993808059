import {useFetchOpenedVirtualTableWithOrdersToBePaid} from "./useFetchOpenedVirtualTableWithOrdersToBePaid";
import {MainLayout} from "../components/MainLayout/MainLayout";
import React from "react";
import {VirtualTableToBePaidList} from "./VirtualTableToBePaidList/VirtualTableToBePaidList";
import {SwippyTitleWithCta} from "../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import styles from "./VirtualTableToBePaidScreen.module.scss"
import {ListLoading} from "../components/Loading/ListLoading";

export function VirtualTableToBePaidScreen() {
    const {virtualTables, loading} = useFetchOpenedVirtualTableWithOrdersToBePaid()

    if (!virtualTables.length && loading) return <MainLayout withSider={true} withHeader={true}>
        <ListLoading/>
    </MainLayout>

    return <MainLayout withSider={true} withHeader={true}>
        <div className={styles.wrapper}>
            <SwippyTitleWithCta title={"Ordini da pagare"}/>
            <VirtualTableToBePaidList virtualTables={virtualTables}/>
        </div>
    </MainLayout>
}