import styles from "./MissingStripeConnection.module.scss";
import {ConnectStripeButton} from "../ConnectStripeButton/ConnectStripeButton";
import React from "react";

export function MissingStripeConnection() {
    return <div className={styles.wrapper}>
        <p className={styles.expl}>Puoi consentire ai tuoi clienti di pagare direttamente tramite Swippy al momento dell'ordine.
            Ti basta collegare un account stripe e il gioco è fatto.<br/>
            Se non hai un account stripe non preoccuparti: cliccando sul bottone qui sotto sarai guidato
            nel crearne uno in pochi semplici passaggi.</p>
        <ConnectStripeButton/>
    </div>
}