import React, {useState} from "react";
import {Col, Form, Row, Select} from "antd";
import {SwippyInput} from "../../../../components/SwippyInput/SwippyInput";
import {SwippySelect} from "../../../../components/SwippySelect/SwippySelect";
import _ from "lodash";
import provinces from "./provinces";
import {SwippyButton} from "../../../../components/SwippyButton/SwippyButton";
import styles from "./RistopubAddressInput.module.scss"
import {useCurrentUser} from "../../../../auth/hooks/useCurrentUser";
import {Ristopub} from "../../../../ristopub/Ristopub";
import {useUpsertMyRistopub} from "../../../../ristopub/useUpsertMyRistopub";
import {Address} from "../../../../ristopub/Address/Address";
import {useForm} from "antd/es/form/Form";


type PropTypes = {
    ristopub: Ristopub
}
type FormFields = {
    address?: string
    city?: string
    province?: string
    postalCode?: string
}

export function RistopubAddressInput (props: PropTypes) {


    const [dirty, setDirty] = useState(false)

    const [form] = useForm<FormFields>()

    const {upsertMyRistopub, upsertingMyRistopub} = useUpsertMyRistopub()


    async function save() {

        try {
            const fields = await form.validateFields()
            const ristopub = _.cloneDeep(props.ristopub)
            ristopub.address = Address.create({
                province: fields.province,
                city: fields.city,
                postalCode: fields.postalCode,
                address: fields.address,
                country: "Italia"
            })
            await upsertMyRistopub(ristopub)
            setDirty(false)
        } catch {
            //TODO notificare utente di errore
        }

    }

    return <Form<FormFields>

        form={form}
        initialValues={{
            address: props.ristopub.address?.address,
            province: props.ristopub.address?.province,
            postalCode: props.ristopub.address?.postalCode,
            city: props.ristopub.address?.city
        }}
        onValuesChange={() => {
            setDirty(true)
        }}
        layout={"vertical"}>
        <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
                <Form.Item name={"address"} label={"Via e civico"}>
                    <SwippyInput placeholder={"Es. Via G. Rossini 31"}/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item name={"city"} label={"Città"}>
                    <SwippyInput />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item name={"province"} label={"Provincia"}>
                    <SwippySelect  showSearch={true}>
                        {
                            _.map(provinces, p => {
                                return <Select.Option key={p.sigla} value={p.nome} >
                                    {p.nome} ({p.sigla})
                                </Select.Option>
                            })
                        }
                    </SwippySelect>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item name={"postalCode"} label={"CAP"}>
                    <SwippyInput />
                </Form.Item>
            </Col>
            <Col span={24} className={styles.saveBtnWrapper}>
                <SwippyButton
                    disabled={!dirty}
                    loading={upsertingMyRistopub}
                    onClick={save}
                    type={"primary"}>
                    Salva indirizzo
                </SwippyButton>
            </Col>
        </Row>
    </Form>
}