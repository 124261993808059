import {gql, useQuery} from "@apollo/client";
import {VirtualTableDTO} from "../virtualTable/VirtualTableDTO";
import _ from "lodash";
import {VirtualTableDataMapper} from "../virtualTable/VirtualTableDataMapper";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";

const GET_OPENED_VIRTUAL_TABLES_WITH_ORDERS_TO_BE_PAID_QUERY = gql`
    query getOpenedVirtualTablesWithOrdersToBePaid {
        getOpenedVirtualTablesWithOrdersToBePaid {
            type
            status
            createdAt
            orders {
                id
                friendlyOrderId
                statusChangedAt
                dueDate
                deliveryDate
                deliveryAddress {
                    address
                    city
                    province
                    postalCode
                    country
                }
                type
                orderItems {
                    id
                    total
                    product {
                        id
                        price
                        __typename
                        name
                        subTitle
                    }
                    __typename
                    qty
                }
                customerNotes
                __typename
            }
            name
            id
            customerPhone {
                phone
                __typename
            }
            __typename
            customerName
        }
    }
`

const virtualTableDataMapper = new VirtualTableDataMapper(
    new OrderDataMapper(
        new OrderItemDataMapper(
            new ProductDataMapper()
        )
    )
)
export function useFetchOpenedVirtualTableWithOrdersToBePaid() {
    const {data, loading} = useQuery(GET_OPENED_VIRTUAL_TABLES_WITH_ORDERS_TO_BE_PAID_QUERY, {
        fetchPolicy: "cache-and-network"
    })


    const virtualTables: VirtualTableDTO[] = _.get(data, "getOpenedVirtualTablesWithOrdersToBePaid", [])



    return {
        virtualTables: _.map(virtualTables, vt => virtualTableDataMapper.toVirtualTable(vt)),
        loading
    }
}