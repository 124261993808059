import {CategoryDTO} from "./CategoryDTO";
import {Category} from "./Category";
import {ProductDataMapper} from "../../ProductsScreen/classes/ProductDataMapper";
import _ from "lodash";

export class CategoryDataMapper {

    private productDataMapper: ProductDataMapper

    constructor(productDataMapper?: ProductDataMapper) {
        this.productDataMapper = productDataMapper ? productDataMapper : new ProductDataMapper(this)
    }

    toCategory(dto: CategoryDTO): Category {
        return Category.create({
            id: dto.id,
            name: dto.name,
            description: dto.description,
            pos: dto.pos,
            products: _.map(dto.products, p => this.productDataMapper.toProduct(p))
        })
    }
}