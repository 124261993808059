import {SwippyModal} from "../../components/SwippyModal/SwippyModal";
import React, {useEffect, useState} from "react";
import {EditOrderForm} from "./EditOrderForm/EditOrderForm";
import {Order} from "../../virtualTable/Order/Order";
import {VirtualTable} from "../../virtualTable/VirtualTable";
import {OrdersListItem} from "../OrdersList/OrdersListItem";
import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import _ from "lodash";
import {Form} from "antd";
import {Product} from "../../menu/ProductsScreen/classes/Product";
import {EditOrderFormFields} from "./EditOrderForm/EditOrderFormFields";

import moment from "moment-timezone"
import {useUpsertOrderAndVirtualTable} from "./useUpsertOrderAndVirtualTable";

import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import {useTakeawayOrdersToBeHandledCtx} from "../../TakeawayOrdersToBeHandled/useTakeawayOrdersToBeHandledCtx";
import {useInStructureOrdersToBeHandledCtx} from "../../InStructureOrdersToBeHandled/useInStructureOrdersToBeHandledCtx";
import {VirtualTableType} from "../../virtualTable/VirtualTableDTO";
import {useBackofficeUpdateTakeawayOrder} from "./useBackofficeUpdateTakeawayOrder";
import {UpdateTakeawayOrderInput} from "./UpdateTakeawayOrderInput";
import {useBackofficeUpdateDeliveryOrder} from "./useBackofficeUpdateDeliveryOrder";
import {useBackofficeUpdateInStructureOrder} from "./useBackofficeUpdateInStructureOrder";
import {useBackofficeCreateDeliveryOrder} from "./useBackofficeCreateDeliveryOrder";
import {useBackofficeCreateTakeawayOrder} from "./useBackofficeCreateTakeawayOrder";
import {useBackofficeCreateInStructureOrder} from "./useBackofficeCreateInStructureOrder";
import {UpdateDeliveryOrderInput} from "./UpdateDeliveryOrderInput";
import {UpdateInStructureOrderInput} from "./UpdateInStructureOrderInput";
import {CreateDeliveryOrderInput} from "./CreateDeliveryOrderInput";
import {OrderItem} from "../../virtualTable/Order/OrderItem/OrderItem";
import {OrderItemInput} from "./OrderItemInput";
import {CreateTakeawayOrderInput} from "./CreateTakeawayOrderInput";
import {CreateInStructureOrderInput} from "./CreateInStructureOrderInput";
import {EditOrderErrorKey} from "./EditOrderErrorKey";

type PropTypes = {
    order?: OrdersListItem
    onCancel?: () => void
    defaultNewOrderType?: VirtualTableType
    isNew: boolean
    createOrderAvailableTypes: VirtualTableType[]
}




export function EditOrderModal (props: PropTypes) {

    const [form] = Form.useForm<EditOrderFormFields>()
    const [upsertingOrderAndVirtualTable,setUpsertingOrderAndVirtualTable] = useState(false)
    const {refetch: refetchTakeawayOrders} = useTakeawayOrdersToBeHandledCtx()
    const {refetch: refetchInStructureOrders} = useInStructureOrdersToBeHandledCtx()

    const [currentOrder, setCurrentOrder] = useState<Order>()
    const [currentVirtualTable, setCurrentVirtualTable] = useState<VirtualTable>()


    const {createDeliveryOrder} = useBackofficeCreateDeliveryOrder()
    const {createTakeawayOrder} = useBackofficeCreateTakeawayOrder()
    const {createInStructureOrder} = useBackofficeCreateInStructureOrder()

    const {updateTakeawayOrder} = useBackofficeUpdateTakeawayOrder()
    const {updateDeliveryOrder} = useBackofficeUpdateDeliveryOrder()
    const {updateInStructureOrder} = useBackofficeUpdateInStructureOrder()

    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false)

    const [errors, setErrors] = useState<Record<EditOrderErrorKey, boolean>>({
        tableName: false,
        deliveryAddress: false,
        deliveryDate: false,
        dueDate: false
    })

    async function refetchOrders() {
        await Promise.all([
            refetchTakeawayOrders(),
            refetchInStructureOrders()
        ])
    }

    const key = props.order ? props.order.order.id || "new-order" : "no-order"






    useEffect(() => {
        setCurrentOrder(_.cloneDeep(props.order?.order))
        setCurrentVirtualTable(_.cloneDeep(props.order?.virtualTable))
        form.setFieldsValue({
            deliveryDate: props.order?.order.deliveryDate,
            deliveryAddress: props.order?.order.deliveryAddress?.toString(),
            customerNotes: props.order?.order.customerNotes,
            customerName: props.order?.virtualTable.customerName,
            customerPhone: props.order?.virtualTable.customerPhone?.phone,
            tableName: props.order?.virtualTable.name,
            orderType: props.order?.virtualTable.type || props.defaultNewOrderType || "ONTABLE",
            orderItems: props.order?.order.items,
            dueDate: props.order?.order.dueDate

        })
    },[key])





    function hasError(errors: Record<EditOrderErrorKey, boolean>) {
        return !!_.find(errors, e => {
            return e
        })
    }


    function activeErrors(keys: EditOrderErrorKey[]) {
        const newErrors = _.cloneDeep(errors)
        _.forEach(keys, key => newErrors[key] = true)
        setErrors(newErrors)
    }

    function clearError(key: EditOrderErrorKey) {
        const newErrors = _.cloneDeep(errors)
        newErrors[key] = false
        setErrors(newErrors)
    }



    function clearAllErrors() {
        const newErrors = _.cloneDeep(errors)
        _.forEach(errors, (e, k) => {
            newErrors[k as EditOrderErrorKey] = false
        })

        if (hasError(errors)) {
            setErrors(newErrors)
        }



    }

    function getOrderTitle() {
        if (!currentVirtualTable) return ""

        return `Modifica ordine ${currentVirtualTable.getDisplayName()}`
    }

    function addProduct(p: Product) {
        const order = currentOrder
        if (!order) return
        order.addProduct(p)
        if (!order.isEmpty()) setSaveBtnEnabled(true)
        form.setFieldsValue({
            orderItems: [
                ...order.items
            ]
        })
    }
    function removeProduct(p: Product) {
        const order = currentOrder
        if (!order) return
        order.removeProduct(p)
        if (order.isEmpty()) setSaveBtnEnabled(false)
        form.setFieldsValue({
            orderItems: [
                ...order.items
            ]
        })
    }


    async function updateTakeaway(orderId: string, fields: EditOrderFormFields): Promise<boolean> {

        await updateTakeawayOrder(new UpdateTakeawayOrderInput(
            orderId,
            mapItems(fields.orderItems),
            fields.customerName,
            fields.customerNotes,
            fields.customerPhone,
            fields.dueDate ? moment(fields.dueDate) : undefined
        ))

        return true
    }

    async function updateDelivery(orderId: string, fields: EditOrderFormFields): Promise<boolean> {
        await updateDeliveryOrder(
            new UpdateDeliveryOrderInput(
                orderId,
                fields.customerName,
                fields.customerNotes,
                fields.customerPhone,
                fields.deliveryAddress,
                fields.deliveryDate ? moment(fields.deliveryDate) : undefined,
                mapItems(fields.orderItems)
            )
        )

        return true
    }


    async function updateInStructure(
        orderId: string,
        fields: EditOrderFormFields
    ): Promise<boolean> {
        await updateInStructureOrder(
            new UpdateInStructureOrderInput(
                orderId,
                fields.customerNotes,
                mapItems(fields.orderItems)
            )
        )

        return true
    }



    function mapItems(
        items: OrderItem[]
    ): OrderItemInput[] {
        if (!items) return []
        return items.map(i => {
            if (!i.product.id) throw new Error(`Missing product id`)

            return new OrderItemInput(
                i.product.id,
                i.qty
            )
        })
    }

    async function createDelivery(fields: EditOrderFormFields): Promise<boolean> {

        if (!fields.deliveryAddress || !fields.deliveryDate) {

            const errorKeys: EditOrderErrorKey[] = []
            if (!fields.deliveryAddress) errorKeys.push("deliveryAddress")
            if (!fields.deliveryDate) errorKeys.push("deliveryDate")
            activeErrors(errorKeys)
            return false
        }

        await createDeliveryOrder(
            new CreateDeliveryOrderInput(
                mapItems(fields.orderItems),
                fields.deliveryAddress,
                moment(fields.deliveryDate),
                fields.customerName,
                fields.customerNotes,
                fields.customerPhone
            )
        )


        return true
    }


    async function createTakeaway(
        fields: EditOrderFormFields
    ): Promise<boolean> {

        if (!fields.dueDate) {
            activeErrors(["dueDate"])
            return false
        }

        await createTakeawayOrder(
            new CreateTakeawayOrderInput(
                moment(fields.dueDate),
                mapItems(fields.orderItems),
                fields.customerName,
                fields.customerPhone,
                fields.customerNotes

            )
        )


        return true

    }


    async function createInStructure(
        fields: EditOrderFormFields
    ): Promise<boolean> {

        if (!fields.tableName) {
            activeErrors(["tableName"])
            return false
        }

        await createInStructureOrder(
            new CreateInStructureOrderInput(
                fields.tableName,
                mapItems(fields.orderItems),
                fields.customerNotes
            )
        )


        return true
    }


    async function create(fields: EditOrderFormFields): Promise<boolean> {
        switch (fields.orderType) {
            case "DELIVERY":
                return await createDelivery(fields)
            case "ONTABLE":
                return await createInStructure(fields)
            case "TAKEAWAY":
                return await createTakeaway(fields)
            default:
                return false
        }
    }


    async function update(fields: EditOrderFormFields) {

        const orderId = props.order?.order.id

        if (!orderId) throw new Error(`Missing order id for update`)


        switch (fields.orderType) {
            case "TAKEAWAY":
                return await updateTakeaway(orderId, fields)
            case "ONTABLE":
                return await updateInStructure(orderId, fields)
            case "DELIVERY":
                return await updateDelivery(orderId, fields)
            default:
                return false
        }
    }


    function onCancel() {
        if (props.onCancel) props.onCancel()
    }


    async function save() {
        if (!currentOrder || !currentVirtualTable) throw new Error("Order not provided")

        try {
            setUpsertingOrderAndVirtualTable(true)
            const fields = _.cloneDeep(await form.getFieldsValue())
            fields.orderItems = await form.getFieldValue("orderItems")

            const success = props.isNew
                ? await create(fields)
                : await update(fields)

            if (success) {
                await refetchOrders()
                onCancel()
            }
        } catch (e) {
            const genericErrorHandler = new GenericErrorHandler()
            genericErrorHandler.handleError(e as Error)
            console.error(e)
            throw e
        } finally {
            setUpsertingOrderAndVirtualTable(false)
        }
    }




    return <SwippyModal
        maskClosable={false}
        footer={<>
            <SwippyButton
                disabled={!saveBtnEnabled}
                loading={upsertingOrderAndVirtualTable}
                onClick={save}
                type={"primary"}
                gaCategory={"order_management"}
                gaLabel={"order_management_edit_order"}

            >
                Salva
            </SwippyButton>
        </>}

        title={getOrderTitle()}
        onCancel={props.onCancel}
        destroyOnClose
        visible={!!currentOrder}>
        <EditOrderForm
            onValuesChange={() => {
                clearAllErrors()
            }}
            errors={errors}
            isNew={props.isNew}
            createOrderAvailableTypes={props.createOrderAvailableTypes}
            createdByBackoffice={props.order?.order.createdByBackoffice}
            addProduct={addProduct}
            removeProduct={removeProduct}
            key={key}
            form={form}/>
    </SwippyModal>
}