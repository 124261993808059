import {Input} from "antd";
import {InputProps} from "antd/lib/input/Input";
import React from "react";
import styles from "./SwippyInput.module.scss"


type PropTypes= InputProps & {
    hideNumberArrows?: boolean
}
export const SwippyInput = React.forwardRef<Input,PropTypes>((props: PropTypes, ref) => {

    const inputClasses: string[] = [styles.swippyInput]


    const {hideNumberArrows, ...inputProps} = props

    if (hideNumberArrows) inputClasses.push(styles.hideNumberArrows)
    if (props.className) inputClasses.push(props.className)
    return <Input
        onWheel={hideNumberArrows ? e => (e.target as HTMLInputElement).blur() : undefined}
        {...inputProps} ref={ref} className={inputClasses.join(" ")}/>
})