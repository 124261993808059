import {Order} from "../../../virtualTable/Order/Order";
import styles from "./OrderTotal/OrderTotal.module.scss";
import {formatPrice} from "../../../formatPrice/formatPrice";
import React from "react";

type Props = {
    order: Order
}

export function OrderShippingCost(props: Props) {
    if (!props.order.isDelivery()) return null
    return <div className={"border-t border-gray-300 p-2"}>
        <p className={"flex items-center justify-between mb-0"}>
            <span>Costo spedizione</span>
            <span>{formatPrice(props.order.deliveryCost ?? 0)} €</span>
        </p>
    </div>
}