import {Button} from "antd";
import React from "react";
import {ButtonProps} from "antd/lib/button/button";
import styles from "./SwippyButton.module.scss"
import {TrackingContainer} from "../TrackingContainer/TrackingContainer";
import {GaProps} from "../../safeGtag/types";
import {ButtonType} from "antd/es/button";


type PropTypes = ButtonProps & GaProps




export function SwippyButton(props: PropTypes) {

    const btnClasses: string[] = ["swippyButton", styles.swippyButton]
    if (props.className) btnClasses.push(props.className)
    const {gaLabel, gaCategory, gaAction, ...propsWithoutGa} = props
    return <TrackingContainer gaLabel={gaLabel} gaCategory={gaCategory} gaAction={gaAction}>
        <Button {...propsWithoutGa}

                onClick={props.onClick ? (e) => {
                    //track(gaAction,gaCategory,gaLabel)
                    if (props.onClick) props.onClick(e)
                } : undefined}
                className={btnClasses.join(" ")}>
            {props.children || null}
        </Button>
    </TrackingContainer>
}