import {useParams} from "react-router-dom"

export function useOrderId() {
    const {orderId} = useParams<{
        orderId: string
    }>()

    return {
        orderId
    }
}