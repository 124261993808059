import React, {useState} from "react";
import {MainLayout} from "../components/MainLayout/MainLayout";
import {SwippyTitleWithCta} from "../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {ProductModifier} from "./ProductModifier";
import {nanoid} from "nanoid";
import {ProductModifierType} from "./ProductModifierType";
import {useCreateProductModifier} from "./useCreateProductModifier";
import {useAllModifiers} from "./useAllModifiers";
import {useUpdateProductModifier} from "./useUpdateProductModifier";
import {useDeleteProductModifier} from "./useDeleteProductModifier";
import {EditModifierModal} from "./EditModifierModal";
import {SwippyListItem} from "../components/SwippyListItem/SwippyListItem";
import {ListLoading} from "../components/Loading/ListLoading";


export function ProductModifiersScreen() {

    const [editingModifier, setEditingModifier] = useState<ProductModifier | undefined>()
    const [isNew, setIsNew] = useState(false)



    const {modifiers, refetch, loading} = useAllModifiers()

    const {createProductModifier, loading: creating} = useCreateProductModifier()
    const {updateProductModifier, loading: updating} = useUpdateProductModifier()
    const {deleteProductModifier, loading: deleting} = useDeleteProductModifier()
    async function openAddModifierModal() {
        setIsNew(true)
        setEditingModifier(new ProductModifier(
            nanoid(),
            ProductModifierType.MULTIPLE_CHOICE
        ))
    }


    function closeModal() {
        setIsNew(false)
        setEditingModifier(undefined)
    }



    async function onSave(modifier: ProductModifier) {
        if (isNew) {
            await createProductModifier(modifier)
        } else {
            await updateProductModifier(modifier)
        }
        await refetch()
        closeModal()
    }

    function editModifier(modifier: ProductModifier) {
        setIsNew(false)
        setEditingModifier(modifier)
    }




    async function deleteModifier(m: ProductModifier) {
        await deleteProductModifier(m)
        await refetch()
    }

    return <MainLayout
        withSider={true}
        withHeader={true}
    >
        <div className={"p-4"}>

            <EditModifierModal
                enableTypeSwitch={isNew}
                deleting={deleting}
                loading={creating || updating}
                onSave={onSave}
                onClose={closeModal}
                mod={editingModifier} />
            <SwippyTitleWithCta title={"Modificatori di prodotto"}
                                ctaLabel={"Aggiungi modificatore"}
                                onClick={openAddModifierModal}
            />

            <div className={"mt-4"}>
                {
                    !modifiers.length && loading ? <ListLoading /> :  modifiers.map(m => {
                        return <div className={"mb-2"}>
                            <SwippyListItem

                                onDelete={async () => {
                                    await deleteModifier(m)
                                }}
                                onClick={() => editModifier(m)}
                                onEdit={() => editModifier(m)}
                            >
                                <div className={"flex flex-col"}>
                                    <h3 className={"font-bold mb-0"}>
                                        {m.name}
                                    </h3>
                                    {m.description ? <p className={"mb-0 mt-1"}>
                                        {m.description}
                                    </p> : null}
                                </div>
                            </SwippyListItem>
                        </div>
                    })
                }
            </div>

        </div>
    </MainLayout>

}