import {OrderItemInput} from "./OrderItemInput";
import {Moment} from "moment-timezone";

export class CreateDeliveryOrderInput {
    constructor(
        public readonly items: OrderItemInput[],
        public readonly deliveryAddress: string,
        public readonly deliveryDate: Moment,
        public readonly customerName?: string,
        public readonly customerNotes?: string,
        public readonly customerPhone?: string
    ) {
    }
}