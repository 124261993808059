export class Debouncer {


    constructor(
        private timerInMs: number
    ) {
    }

    private timeout?: NodeJS.Timeout

    public debounce(
        func: () => void
    ) {
        if (this.timeout) clearTimeout(this.timeout)

        this.timeout = setTimeout(async () => {
            await func()
            this.timeout = undefined
        }, this.timerInMs)
    }
}