import React from "react";

import styles from "./LoadingIcon.module.scss"


type PropTypes = {
    className?: string
}


export function LoadingIcon(props: PropTypes) {
    return <div className={
        ["loader ease-linear rounded-full border-4 border-t-4 border-gray-200",
            styles.loader,
            props.className || ""
        ].join(" ")
    } />
}
