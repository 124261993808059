import {gql, useMutation} from "@apollo/client";
import {ProductModifier} from "./ProductModifier";
import {ProductModifierDTO} from "./ProductModifierDTO";
import {ProductModifierType} from "./ProductModifierType";

const UPDATE_PRODUCT_MODIFIER_MUTATION = gql`
    mutation updateModifier($input: UpdateModifierInput!) {
        updateModifier(input: $input) {
            id
        }
    }
`


type UpdateProductModifierInput = {
    choices: {
        id?: string
        name?: string
        price?: number
        maxQty?: number
    }[]
    description?: string
    id: string
    maxSelectableChoices?: number
    name?: string
    required?: boolean
}

export function useUpdateProductModifier() {
    const [updateModifierMutation, {
        loading
    }] = useMutation<{
        updateModifier: ProductModifierDTO
    }, {
        input: UpdateProductModifierInput
    }>(UPDATE_PRODUCT_MODIFIER_MUTATION)


    async function updateProductModifier(modifier: ProductModifier) {
        await updateModifierMutation({
            variables: {
                input: {
                    name: modifier.name,
                    required: modifier.required,
                    description: modifier.description,
                    maxSelectableChoices: modifier.maxSelectableChoices,
                    id: modifier.id,
                    choices: modifier.choices.map(c => {
                        return {
                            id: c._isNew ? undefined : c.id,
                            name: c.name,
                            price: c.price,
                            maxQty: modifier.type === ProductModifierType.MULTIPLE_CHOICE_WITH_QTY ? c.maxQty : undefined
                        }
                    })
                }
            }
        })
    }
    return {
        loading,
        updateProductModifier
    }
}