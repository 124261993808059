import React from "react";
import styles from "./SwippyMenuItem.module.scss"

type PropTypes = {
    label: string,
    onClick?: () => void
    active?: boolean
    render?: (label: React.ReactNode) => React.ReactNode
}

export function SwippyMenuItem(props: PropTypes) {

    const classes = [styles.menuItem]
    if (props.active) classes.push(styles.active)



    return <div className={classes.join(" ")} onClick={props.onClick}>
        {props.render ? props.render(<span className={styles.label}>{props.label}</span>) : <span className={styles.label}>{props.label}</span>}
    </div>
}