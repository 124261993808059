import {gql, useMutation} from "@apollo/client";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";
import {UpdateTakeawayOrderInput} from "./UpdateTakeawayOrderInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";

const BACKOFFICE_UPDATE_TAKEAWAY_ORDER = gql`
    mutation backofficeUpdateTakeawayOrder($input: BackofficeUpdateTakeawayOrderInput!) {
        backofficeUpdateTakeawayOrder(input: $input) {
            id
            cartId

            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }

            virtualTable {
                id
            }
        }
    }
`

export function useBackofficeUpdateTakeawayOrder() {

    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))

    const [updateTakeawayOrderMutation, {
        loading
    }] = useMutation<{
        backofficeUpdateTakeawayOrder: OrderDTO
    }>(BACKOFFICE_UPDATE_TAKEAWAY_ORDER)


    async function updateTakeawayOrder(
        input: UpdateTakeawayOrderInput
    ) {
        const res = await updateTakeawayOrderMutation({
            variables: {
                input: {
                    customerName: input.customerName,
                    customerNotes: input.customerNotes,
                    customerPhone: input.customerPhone,
                    dueDate: input.dueDate?.toDate(),
                    items: input.items?.map(i => ({qty: i.qty, productId: i.productId})),
                    orderId: input.orderId
                }
            }
        })


        return res.data?.backofficeUpdateTakeawayOrder ? orderDataMapper.toOrder(res.data.backofficeUpdateTakeawayOrder) : undefined
    }


    return {
        updateTakeawayOrder,
        loading
    }
}