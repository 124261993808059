import {Card} from "antd";
import React from "react";
import "./SwippyCard.scss"

type PropTypes = {
    title?: React.ReactNode
    children?: React.ReactNode
    className?: string
}
export function SwippyCard(props: PropTypes) {


    const cardCssClasses = ["swippyCard"]
    if (props.className) cardCssClasses.push(props.className)

    return <Card className={cardCssClasses.join(" ")} title={props.title}>
        {props.children || null}
    </Card>
}