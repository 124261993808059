import {useContext} from "react";
import {InStructureOrdersToBeHandledCtx} from "./InStructureOrdersToBeHandledCtx";


export function useInStructureOrdersToBeHandledCtx() {
    const {orders,loading,refetch,lastQueryExecutedAt} = useContext(InStructureOrdersToBeHandledCtx)
    return {
        orders,
        loading,
        refetch,
        lastQueryExecutedAt
    }

}