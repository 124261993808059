import React from "react";

type PropTypes = {
    className?: string
    onClick?: () => void
}
export function CrossIcon(props: PropTypes) {
    return <svg
        onClick={props.onClick}
        xmlns="http://www.w3.org/2000/svg" className={props.className} fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
    </svg>
}