import {gql, useMutation} from "@apollo/client";
import {ProductModifier} from "./ProductModifier";

const DELETE_PRODUCT_MODIFIER_MUTATION = gql`
    mutation deleteModifier($input: DeleteModifierInput!) {
        deleteModifier(input: $input)
    }
`

export function useDeleteProductModifier() {
    const [deleteModifierMutation, {
        loading
    }] = useMutation<{
        deleteModifier: boolean
    }, {
        input: {
            id: string
        }
    }>(DELETE_PRODUCT_MODIFIER_MUTATION)

    async function deleteProductModifier(modifier: ProductModifier) {
        await deleteModifierMutation({
            variables: {
                input: {
                    id: modifier.id
                }
            }
        })
    }

    return {
        loading,
        deleteProductModifier
    }
}