import {BreadcrumbsComponent} from "../BreadcrumbsComponent/BreadcrumbsComponent";
import React from "react";
import {useCurrentCategory} from "../../../../menu/CategoriesScreen/useCurrentCategory";
import {useHistory} from "react-router-dom"

export function BreadcrumbsProductsPage() {

    const {category} = useCurrentCategory()
    const history = useHistory()
    if (!category || !category.name) return null
    return <BreadcrumbsComponent paths={[
        {
            label: "Menu",
            onClick: () => {
                history.push("/menu")
            }
        },
        {
            label: category.name,
        }
    ]}/>
}