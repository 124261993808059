import {gql, useQuery} from "@apollo/client";
import {OpenDayItem} from "./OpenDayItem";

const GET_OPEN_DAYS_QUERY = gql`
    query getOpenDays {
        getOpenDays {
            openDays {
                dayOfWeek
                closeAllDay
                timeSlots {
                    localFrom
                    localTo
                    timezone
                }
            }
        }
    }
`

export function useOpenDays() {
    const {data, loading} = useQuery<{
        getOpenDays: {
            openDays: OpenDayItem[]
        }
    }>(GET_OPEN_DAYS_QUERY, {
        fetchPolicy: "network-only"
    })

    return {
        openDays: data?.getOpenDays.openDays || [],
        loading
    }
}