import {MainLayout} from "../components/MainLayout/MainLayout";
import React from "react";
import {useVirtualTableHistory} from "./useVirtualTableHistory";
import {VirtualTableWithPaidOrdersList} from "./VirtualTableWithPaidOrdersList/VirtualTableWithPaidOrdersList";
import styles from "./VirtualTableWithPaidOrdersScreen.module.scss"
import {SwippyTitleWithCta} from "../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {ListLoading} from "../components/Loading/ListLoading";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";

export function OrderHistoryScreen () {

    const {virtualTables, loading,fetchMoreVirtualTables,hasMore,fetchingMore} = useVirtualTableHistory()


    if (!virtualTables.length && loading) {
        return <MainLayout
            withSider={true}
            withHeader={true}
        >
            <ListLoading/>
        </MainLayout>
    }

    return <MainLayout
        withSider={true}
        withHeader={true}
    >
        <div className={styles.wrapper}>
            <SwippyTitleWithCta title={"Storico ordini"}/>
            <VirtualTableWithPaidOrdersList virtualTables={virtualTables}/>
            {hasMore ? <div className={styles.fetchMoreWrapper}>
                <SwippyButton
                    onClick={fetchMoreVirtualTables}
                    loading={fetchingMore}
                    type={"link"}>
                    Carica di più
                </SwippyButton>
            </div> : null}
        </div>
    </MainLayout>
}