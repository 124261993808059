import {gql, useMutation} from "@apollo/client";


const ACCEPT_ODER_MUTATION = gql`
    mutation acceptOrder($input: AcceptOrderInput!) {
        acceptOrder(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`
const PAY_ORDER_MUTATION = gql`
    mutation payOrder($input: PayOrderInput!) {
        payOrder(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`
const REJECT_ODER_MUTATION = gql`
    mutation rejectOrder($input: RejectOrderInput!) {
        rejectOrder(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`
const PROPOSE_DATE_CHANGE_MUTATION = gql`
    mutation proposeOrderDateChange($input: ProposeOrderDateChangeInput!) {
        proposeOrderDateChange(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`


const CANCEL_ORDER_MUTATION = gql`
    mutation cancelOrder($input: CancelOrderInput!) {
        cancelOrder(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`
const REFUND_ORDER_MUTATION = gql`
    mutation refundOrder($input: RefundOrderInput!) {
        refundOrder(input: $input) {
            id
            __typename
            orderStatus {
                id
            }
        }
    }
`
const MARK_AS_DELIVERED = gql`
    mutation markOrderAsDelivered($input: MarkOrderAsDeliveredInput!) {
        markOrderAsDelivered(input: $input) {
            id
            __typename
            name
            orders {
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`

export function useOrdersActions() {
    const [proposeDateChangeMutation] = useMutation(PROPOSE_DATE_CHANGE_MUTATION)
    const [acceptOrderMutation] = useMutation(ACCEPT_ODER_MUTATION)
    const [rejectOrderMutation] = useMutation(REJECT_ODER_MUTATION)
    const [payOrderMutation] = useMutation(PAY_ORDER_MUTATION)
    const [cancelOrderMutation] = useMutation(CANCEL_ORDER_MUTATION)
    const [refundOrderMutation] = useMutation(REFUND_ORDER_MUTATION)
    const [markOrderAsDeliveredMutation] = useMutation(MARK_AS_DELIVERED)


    return {
        acceptOrderMutation,
        rejectOrderMutation,
        proposeDateChangeMutation,
        payOrderMutation,
        cancelOrderMutation,
        refundOrderMutation,
        markOrderAsDeliveredMutation
    }
}