import {Time} from "./Time";


type CreateRistopubTakeawaySlotInput = {
    start: string
    end: string
}

export class RistopubDeliverySlot {
    private constructor(public start: Time, public end: Time) {
    }


    public static create({start,end}: CreateRistopubTakeawaySlotInput) {
        return new RistopubDeliverySlot(
            Time.create({time: start}),
            Time.create({time: end})
        )
    }


    public setStart(time: Time) {
        this.start = time
    }


    public setEnd(time: Time) {
        this.end = time
    }

}