import {Redirect} from "react-router-dom";
import {FullPageLoading} from "../../../components/Loading/FullPageLoading";
import React from "react";
import {useSwippyLoggedUserCtx} from "../../../swippyUserCtx/useSwippyLoggedUserCtx";

export function RedirectPostAuthScreen() {
    const {loadingUser,loggedUser:user} = useSwippyLoggedUserCtx()
    if (loadingUser) return <FullPageLoading/>
    if (!user) throw new Error("User must be defined")



    const ristopub = user.getRistopub()
    if (!ristopub || !ristopub.name || !ristopub.name.length) return <Redirect to={"/onboarding"}/>
    else return <Redirect to={"/menu"}/>
}