import {gql, useQuery} from "@apollo/client";
import {OrderDTO} from "../virtualTable/Order/OrderDTO";
import {useMemo} from "react";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";


const GET_ORDER_BY_ID = gql`
    query getOrderById($input: GetOrderByIdInput!) {
        getOrderById(input: $input) {
            friendlyOrderId
            deliveryCost
            createdByBackoffice
            total
            type
            __typename
            deliveryDate
            statusChangedAt
            id
            createdAt
            archived
            inPlace
            customerNotes
            dueDate
            qrCodeName
            orderStatus {
                id
                proposedDateChange
            }
            deliveryAddress {
                address
                postalCode
                country
                city
                province
            }
            orderItems {
                total
                __typename
                id
                qty
                product {
                    __typename
                    id
                    name
                    description
                    subTitle
                }
                variations {
                    modifierId
                    modifierName
                    choices {
                        choiceId
                        choiceName
                        choicePrice
                        choiceQty
                    }
                }
            }

            virtualTable {
                id
                name
                customerName
                type
                customerPhone {
                    phone
                    __typename
                }
                __typename
            }
        }
    }
`

export function useOrderById(orderId?: string) {
    const {data, loading} = useQuery<{
        getOrderById: OrderDTO | undefined
    }, {
        input: {
            orderId: string
        }
    }>(GET_ORDER_BY_ID, {
        skip: !orderId,
        variables: {
            input: {
                orderId: orderId as string
            }
        }
    })


    const dataMapper = useMemo(() => {
        return new OrderDataMapper(
            new OrderItemDataMapper(
                new ProductDataMapper()
            )
        )
    }, [])

    const dto = data?.getOrderById

    return {
        order: dto ? dataMapper.toOrder(dto) : undefined,
        loading
    }
}