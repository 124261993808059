import React from "react"

import moment, {Moment} from "moment-timezone";
import {Order} from "../virtualTable/Order/Order";
import {OrdersListItem} from "../orders/OrdersList/OrdersListItem";


export type InStructureOrdersToBeHandledCtxType = {
    orders: OrdersListItem[]
    loading: boolean,
    refetch: () => Promise<void>
    lastQueryExecutedAt: Moment
}

const InStructureOrdersToBeHandledCtx = React.createContext<InStructureOrdersToBeHandledCtxType>( {
    orders: [],
    loading: false,
    refetch: async () => {},
    lastQueryExecutedAt: moment()
})


export {
    InStructureOrdersToBeHandledCtx
}