import {QrCodeType} from "./QrCodeType";

export class QrCode {
    constructor(
        public readonly id: string,
        public name: string,
        public enableDeliveryToLocation: boolean,
        public enablePickup: boolean,
        public readonly type: QrCodeType,
        public readonly enableDelivery?: boolean,
        public readonly enableTakeaway?: boolean
    ) {
    }


    public getQrLink(
        ristopubId: string
    ) {
        return `${process.env.REACT_APP_WEB_APP_BASE_URL}/ristopub/${ristopubId}/qrcode/${this.id}`
    }
}