import {OrderActionsWrapper} from "../OrderActionsWrapper/OrderActionsWrapper";
import React, {useState} from "react";
import {Select} from "antd";
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {useOrdersActions} from "../useOrdersActions";
import moment from "moment-timezone";
import _ from "lodash";
import {ProposeDateChangeModal} from "../ProposeDateChangeModal/ProposeDateChangeModal";
import {useSwippyLoggedUserCtx} from "../../../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {Order} from "../../../../../virtualTable/Order/Order";
import {AvailabilityType} from "../../../../../ristopub/useRistopubAvailability";

const {Option} = Select


type PropTypes = {
    virtualTableId?: string
    orderId?: string
    showProposeDateChangeAction?: boolean
    order?: Order
}

export function PendingOrderActions(props: PropTypes) {




    const {acceptOrderMutation,rejectOrderMutation,proposeDateChangeMutation} = useOrdersActions()
    const [accepting, setAccepting] = useState(false)
    const [rejecting, setRejecting] = useState(false)

    const [showProposeChangeDateModal, setShowProposeChangeDateModal] = useState(false)
    const {loggedUser: user} = useSwippyLoggedUserCtx()
    function openProposeChangeDateModal() {
        setShowProposeChangeDateModal(true)
    }


    function closeProposeChangeDateModal() {
        setShowProposeChangeDateModal(false)
    }

    async function accept() {
        setAccepting(true)
        try {
            await acceptOrderMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setAccepting(false)
        }
    }
    async function reject() {
        setRejecting(true)
        try {
            await rejectOrderMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setRejecting(false)
        }
    }
    if (!props.virtualTableId || !props.orderId || !user) return null



    const ristopub = user.getRistopub()


    console.log("props.order?", props.order)

    return <OrderActionsWrapper>

        <ProposeDateChangeModal
            initialDate={props.order?.isDelivery() ? props.order?.deliveryDate : props.order?.dueDate}
            type={props.order?.isDelivery() ? AvailabilityType.DELIVERY : AvailabilityType.TAKEAWAY}
            ristopub={ristopub}
            onCancel={closeProposeChangeDateModal}
            visible={showProposeChangeDateModal}
            orderId={props.orderId}
            virtualTableId={props.virtualTableId}

        />
        <SwippyButton

            gaCategory={"order_management"}
            gaLabel={"order_management_accept_action"}
            onClick={accept} loading={accepting} type={"link"}>
            Accetta
        </SwippyButton>
        {props.showProposeDateChangeAction ? <SwippyButton
            gaCategory={"order_management"}
            gaLabel={"order_management_propose_date_change_action"}
            onClick={openProposeChangeDateModal} type={"link"}>
            Proponi cambio orario
        </SwippyButton> : null}
        <SwippyButton

            gaCategory={"order_management"}
            gaLabel={"order_management_reject_action"}
            onClick={reject} loading={rejecting} type={"link"}>
            Rifiuta
        </SwippyButton>
    </OrderActionsWrapper>
}