import {gql, useQuery} from "@apollo/client";
import _ from "lodash";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";
import {OrdersListItem} from "./OrdersList/OrdersListItem";
import {OrderDTO} from "../virtualTable/Order/OrderDTO";
import {firebase} from "../firebase";
import {useRistopubUpdateListener} from "./useRistopubUpdateListener";

const GET_ORDERS_TO_BE_PAID_QUERY = gql`
    query getOrdersToBePaid {
        getOrdersToBePaid {
            total
            customerNotes
            statusChangedAt
            friendlyOrderId
            virtualTable {
                id
                name
                customerName
                customerPhone {
                    phone
                    __typename
                }
                __typename
            }
            __typename
            createdAt
            inPlace
            id
            archived
            dueDate
            deliveryDate
            deliveryAddress {
                address
                city
                province
                postalCode
                country
            }
            orderStatus {
                id
                proposedDateChange
            }
            orderItems {
                total
                __typename
                id
                qty
                product {
                    __typename
                    id
                    name
                    description
                    subTitle
                }
                variations {
                    modifierId
                    modifierName
                    choices {
                        choiceId
                        choiceName
                        choicePrice
                        choiceQty
                    }
                }
            }
        }
    }
`



const orderItemDataMapper = new OrderItemDataMapper(
    new ProductDataMapper()
)




const orderDataMapper = new OrderDataMapper(orderItemDataMapper)

export function useFetchOrdersToBePaid() {
    const {data, loading,refetch} = useQuery(GET_ORDERS_TO_BE_PAID_QUERY, {
        fetchPolicy: "cache-and-network",
        pollInterval: 30 * 1000, //30s
    }) || {}

    async function handler(a: firebase.database.DataSnapshot, b?: string | null): Promise<void> {
        if (refetch) await refetch()
    }


    useRistopubUpdateListener(handler)



    const orderDtos = _.get(data, "getOrdersToBePaid", []) as OrderDTO[]

    const orders = _.map(orderDtos, d => orderDataMapper.toOrder(d))

    return {
        orders: _.reduce(orders, (res: OrdersListItem[], o) => {
            if (o.virtualTable)
                res.push({
                    order: o,
                    virtualTable: o.virtualTable
                })
            return res
        }, []),
        loading
    }

}