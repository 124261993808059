import {Select} from "antd"
import React from "react";
import moment from "moment-timezone"

const {Option} = Select



let options: React.ReactNode[]
export function getRangeOptions() {

    if (options) return options
    options = []


    const startOfDay = moment().startOf("day")
    const endOfDay = moment().endOf("day")

    for (let currMoment = moment(startOfDay); currMoment.isBefore(endOfDay); currMoment = moment(currMoment).add(10, "minutes")) {
        options.push(<Option key={currMoment.format("HH:mm")} value={currMoment.format("HH:mm")}>{currMoment.format("HH:mm")}</Option>)
    }



    return options
}