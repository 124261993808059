import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom"
import {CategoryDTO} from "./classes/CategoryDTO";
import _ from "lodash";
import {CategoryDataMapper} from "./classes/CategoryDataMapper";

const GET_CATEGORY_QUERY = gql`
    query getCategory($input: GetCategoryInput!) {
        getCategory(input:$input) {
            id
            name
        }
    } 
`

export function useCurrentCategory() {

    const {categoryId} = useParams<{categoryId: string}>()




    const {data,loading} = useQuery(GET_CATEGORY_QUERY, {
        variables: {
            input: {
                categoryId
            }
        }
    })


    const cDto: CategoryDTO | undefined = _.get(data, "getCategory")
    const catDataMapper = new CategoryDataMapper()


    return {
        category: cDto ? catDataMapper.toCategory(cDto) : undefined,
        loading
    }




}