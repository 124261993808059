import React from "react";

import {useTakeawayOrdersToBeHandledCtx} from "../../../../TakeawayOrdersToBeHandled/useTakeawayOrdersToBeHandledCtx";
import {OrdersToBeHandledBadge} from "../OrdersToBeHandledBadge/OrdersToBeHandledBadge";


type PropTypes = {
    children?: React.ReactNode
    className?: string

}
export function TakeawayOrdersToBeHandledBadge(props: PropTypes) {
    const {orders} = useTakeawayOrdersToBeHandledCtx()

    return <OrdersToBeHandledBadge
        className={props.className}
        orders={orders}
        offset={[0,0]}
    >
        {props.children || null}
    </OrdersToBeHandledBadge>

}