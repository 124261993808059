import {Upload} from "antd";
import {UploadProps} from "antd/lib/upload/interface";
import React from "react";
import styles from "./SwippyDragger.module.scss"

const { Dragger } = Upload;

type PropTypes = UploadProps & {
    children?: React.ReactNode
}



export function SwippyDragger(props: PropTypes) {

    const classes = [styles.swippyDragger]
    if (props.className) classes.push(props.className)
    return <Dragger {...props} className={classes.join(" ")}>
        {props.children || null}
    </Dragger>
}