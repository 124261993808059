import {VariationGroupedByModifier} from "../virtualTable/Order/OrderItem/OrderItemDTO";
import {formatPrice} from "../formatPrice/formatPrice";
import React from "react";

type Props = {
    variations: VariationGroupedByModifier[]
}

export function OrderItemVariationsRenderer(props: Props) {
    const hasVariations = props.variations.length > 0
    if (!hasVariations) return null


    return <div className={"mt-3"}>
        {
            props.variations.map((v, index) => {
                return <div
                    className={[
                        index < props.variations.length - 1 ? "mb-2" : ""
                    ].join(" ")}
                    key={v.modifierId}>
                    <h3 className={[
                        "font-bold text-sm mb-0",

                    ].join(" ")}>{v.modifierName}</h3>
                    <div>
                        {
                            v.choices.map((c, index) => {
                                const choiceQty = c.choiceQty ?? 1

                                return <div key={c.choiceId} className={[
                                    "text-gray-500 text-sm flex items-center justify-between",
                                    index < v.choices.length - 1 ? "mb-2" : ""
                                ].join(" ")}>
                                    <div>
                                        { choiceQty > 1 ? <span className={"mr-1"}>x{choiceQty}</span> : null }
                                        <span>{c.choiceName}</span>
                                    </div>
                                    <span>{formatPrice(c.choicePrice * choiceQty)} €</span>
                                </div>
                            })
                        }
                    </div>
                </div>
            })
        }
    </div>
}