import {useQuery} from "@apollo/client";
import {GET_CATEGORIES_QUERY} from "./CategoriesList/GetCategoriesQuery";
import {CategoryDTO} from "./classes/CategoryDTO";
import _ from "lodash";
import {CategoryDataMapper} from "./classes/CategoryDataMapper";

export function useGetCategories() {
    const {data, loading} = useQuery(GET_CATEGORIES_QUERY)

    const categories: CategoryDTO[] = _.get(data, "getCategories", [])

    const dataMapper = new CategoryDataMapper()

    return {
        categories: _.sortBy(_.map(categories, d => dataMapper.toCategory(d)), c => c.pos),
        loading
    }
}