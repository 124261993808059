import React, {useEffect, useRef, useState} from "react";
import {Col, Form, message, Row} from "antd"
import {SwippyInput} from "../../../components/SwippyInput/SwippyInput";
import {Ristopub} from "../../../ristopub/Ristopub";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import styles from "./GeneralSettings.module.scss"
import {useUpsertMyRistopub} from "../../../ristopub/useUpsertMyRistopub";
import slugify from "slug"
import validator from "email-validator"
import {Email} from "../../../auth/user/Email";
import {CroppableImageInput} from "../../../ristopub/CroppableImageInput";
import {ImageUploader} from "../../../menu/ProductsScreen/EditProductModal/ImageUploader";
import {Debouncer} from "../../../Debouncer";
import {useIsSlugBusy} from "../../../ristopub/useIsSlugBusy";
import {LoadingIcon} from "../../../components/LoadingIcon";
import {CheckIcon} from "../../../components/CheckIcon";
import {CrossIcon} from "../../../components/CrossIcon";
import {CopyIcon} from "../../../components/CopyIcon";
import copy from "copy-to-clipboard";

type PropTypes = {
    ristopub: Ristopub
}

export function GeneralSettings(props: PropTypes) {
    const ristopub = props.ristopub
    const [name, setName] = useState(ristopub.name)
    const [slug, setSlug] = useState(ristopub.slug)
    const [emailForNotification, setEmailForNotification] = useState(ristopub.getEmailForNotification())
    const [saving, setSaving] = useState(false)
    const {upsertMyRistopub} = useUpsertMyRistopub()
    const [dirty, setDirty] = useState(false)
    const [slugChanged, setSlugChanged] = useState(false)
    const [featuredImageToUpload, setFeaturedImageToUpload] = useState<File | undefined>()
    const [checkingSlugAvailability, setCheckingSlugAvailability] = useState(false)

    const [slugAvailable, setSlugAvailable] = useState(false)

    const {current: slugAvailabilityDebounce} = useRef(new Debouncer(800))

    const {isBusySlug} = useIsSlugBusy()

    async function checkSlugAvailability() {
        if (!slug || !slug.length) return
        if (!checkingSlugAvailability) setCheckingSlugAvailability(true)
        slugAvailabilityDebounce.debounce(async () => {
            const isBusy = await isBusySlug(slug)
            setSlugAvailable(!isBusy)
            setCheckingSlugAvailability(false)
        })
    }

    useEffect(() => {
        checkSlugAvailability().catch(e => {
            throw e
        })
    }, [slug])

    function isSaveButtonDisabled() {
        return !dirty
            || !name || !name.length
            || !emailForNotification || !validator.validate(emailForNotification)

    }

    async function uploadImageIfNeeded() {
        const imgUploader = new ImageUploader()
        if(featuredImageToUpload) {
            return await imgUploader.uploadImage(featuredImageToUpload)
        }
    }

    async function save() {
        if (!name) return
        setSaving(true)
        try {


            const uploadRes = await uploadImageIfNeeded()

            ristopub.name = name
            ristopub.slug = slug
            ristopub.setEmailForNotification(emailForNotification ? new Email(emailForNotification) : undefined)
            await upsertMyRistopub(ristopub, {
                featuredImageToUpload: uploadRes
            })
            setDirty(false)
        } finally {
            setSaving(false)
        }
    }


    const ristopubLink = `${process.env.REACT_APP_WEB_APP_BASE_URL}/${slug}`


    async function copyLink() {
        copy(ristopubLink)
        await message.info("Link copiato negli appunti")
    }


    return <div>
        <Form
            requiredMark={false}
            initialValues={{
                "name": ristopub.name,
                "emailForNotification": ristopub.getEmailForNotification(),
                slug: slug
            }}
            onValuesChange={() => {
                setDirty(true)
            }}
            layout={"vertical"}>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name={"name"}
                        rules={[{ required: true, message: 'Il tuo locale deve avere un nome' }]}
                        label={"Nome locale"}>
                        <SwippyInput
                            onChange={e => setName(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={"slug"}
                        rules={[{ required: true, message: 'Il link personalizzato non può essere vuoto' }]}
                        label={<p className={"flex flex-col"}>
                            <span>Link personalizzato per asporto e consegne</span>
                            <span className={"italic flex items-center"}>Il tuo link è {ristopubLink} <CopyIcon
                                onClick={copyLink}
                                className={"h-5 w-5 text-gray-600 cursor-pointer ml-1"} /></span>
                            { slugChanged
                                ?  (
                                    checkingSlugAvailability
                                        ? <span className={"flex items-center mt-1"}>
                                            <span className={"leading-none"}>Controllo disponibilità</span>
                                            <LoadingIcon className={"h-4 ml-2 w-4"} />
                                        </span>
                                        : slugAvailable ? <span className={"flex items-center mt-1"}>
                                            <span className={"leading-none"}>Disponibile</span>
                                            <CheckIcon className={"h-4 w-4 ml-2 text-green-600"} />
                                        </span> : <span className={"flex items-center mt-1"}>
                                            <span className={"leading-none"}>Non disponibile</span>
                                            <CrossIcon className={"h-4 ml-2 w-4 text-red-600"} />
                                        </span>
                                    )
                                : null }
                        </p>}>
                        <SwippyInput
                            onChange={e => {
                                setSlug(slugify(e.target.value))
                                if (!slugChanged) setSlugChanged(true)
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={"emailForNotification"}
                        rules={[
                            { required: true, message: 'Devi specificare una email di notifica' },
                            { type: "email", message: 'Devi specificare una email valida' },

                            ]}

                        label={<span>Email di notifica <span className={styles.help}>(il sistema invierà le comunicazioni a questa email)</span></span>}>
                        <SwippyInput
                            onChange={e => setEmailForNotification(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={"featuredImage"}
                        label={<span>Copertina del locale</span>}>
                        <CroppableImageInput
                            onChange={(img) => setFeaturedImageToUpload(img)}
                            initialSrc={props.ristopub.featuredImageSrc}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className={styles.saveBtnWrapper}>
                    <SwippyButton disabled={isSaveButtonDisabled()}
                                  loading={saving}
                                  onClick={save}
                                  type={"primary"}>
                        Salva
                    </SwippyButton>
                </Col>
            </Row>
        </Form>
    </div>

}