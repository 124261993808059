import {Loading} from "./Loading";
import {MainLayout} from "../MainLayout/MainLayout";
import React from "react";

export function FullPageLoading() {
    return <MainLayout>
        <div style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Loading/>
        </div>
    </MainLayout>

}