import {OrderItemInput} from "./OrderItemInput";
import {Moment} from "moment-timezone";

export class UpdateTakeawayOrderInput {
    constructor(
        public readonly orderId: string,
        public readonly items?: OrderItemInput[],
        public readonly customerName?: string,
        public readonly customerNotes?: string,
        public readonly customerPhone?: string,
        public readonly dueDate?: Moment

    ) {
    }
}