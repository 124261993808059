import {StripeAccountDTO} from "../../../../auth/user/UserDTO";
import React from "react";
import styles from "./StripeAccountConnected.module.scss"

type PropTypes = {
    stripeAccount: StripeAccountDTO
}

export function StripeAccountConnected(props: PropTypes) {

    const pendingVerification = !props.stripeAccount.detailsSubmitted

    return <div>
        <div className={styles.topWrapper}>
            <p className={styles.topInfo}>
                <span className={styles.label}>Account stripe collegato:</span>
                <span className={styles.accountEmail}>{props.stripeAccount.email}</span>
            </p>
        </div>
        {
            pendingVerification ? <>
                <h4 className={styles.missingInfoTitle}>Informazioni richieste</h4>
                <p className={styles.missingInfoWrapper}>
                    Questo account Stripe necessita di ulteriori informazioni per poter ricevere pagamenti.
                    Puoi fornire le informazioni richieste nella <a
                    className={styles.stripeDashboardLink}
                    href={"https://dashboard.stripe.com/dashboard"}>Dashboard</a> di Stripe.
                </p>

            </>: null
        }
    </div>
}