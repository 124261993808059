import {Product} from "../../ProductsScreen/classes/Product";

type CreateCategoryInput = {
    name?: string
    id?: string
    description?: string,
    pos: number,
    products?: Product[]
}

export class Category {
    private constructor(
        public id: string | undefined,
        public pos: number,
        public name?: string,
        public description?: string,
        private products: Product[] = []
    ) {
    }


    public getProducts() {
        return this.products
    }


    public static create({
        name,
        id,
        description,
        pos,
        products
                         }: CreateCategoryInput) {
        return new Category(id,pos,name,description,products)
    }
}