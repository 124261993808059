import {OrderStatusDTO} from "./OrderStatusDTO";
import {OrderItem} from "./OrderItem/OrderItem";
import {Moment} from "moment-timezone";
import {VirtualTable} from "../VirtualTable";
import {OrderType} from "./OrderDTO";
import {Product} from "../../menu/ProductsScreen/classes/Product";
import _ from "lodash";
import {Address} from "../../ristopub/Address/Address";

type CreateOrderInput =  {
    id?: string
    items?: OrderItem[]
    status: OrderStatusDTO
    archived?: boolean
    inPlace?: boolean
    total?: number
    dueDate?: Moment
    deliveryDate?: Moment
    createdAt?: Moment,
    statusChangedAt?: Moment,
    virtualTable?: VirtualTable,
    customerNotes?: string,
    createdByBackoffice?: boolean
    type?: OrderType
    deliveryAddress?: Address
    friendlyOrderId?: string
    selfPickup?: boolean
    deliveryCost?: number
    qrCodeName?: string
}
export class Order {
    getShippingCost(): number {
        if (!this.isDelivery()) return 0
        return this.deliveryCost ?? 0
    }
    private constructor(
        public id: string | undefined,
        public status: OrderStatusDTO,
        public items: OrderItem[] = [],
        public archived: boolean = false,
        public total: number,
        public dueDate?: Moment,
        public deliveryDate?: Moment,
        public inPlace?: boolean,
        public createdAt?: Moment,
        public virtualTable?: VirtualTable,
        public customerNotes?: string,
        public statusChangedAt?: Moment,
        public type: OrderType = "TAKEAWAY",
        public deliveryAddress?: Address,
        public readonly createdByBackoffice = false,
        public readonly friendlyOrderId?: string,
        public readonly selfPickup?: boolean,
        public readonly deliveryCost: number = 0,
        public readonly qrCodeName?: string

    ) {
    }




    public static create({
         id,
         status,
         items,
         total,
         archived,
         deliveryAddress,
         deliveryDate,
         dueDate,
         inPlace,
         createdAt,
         virtualTable,
         customerNotes,
         statusChangedAt,
        qrCodeName,
         type,
         createdByBackoffice,
                             friendlyOrderId,
                             selfPickup,
                             deliveryCost
    }: CreateOrderInput) {
        return new Order(
            id,
            status,
            items,
            archived,
            total || 0,
            dueDate,
            deliveryDate,
            inPlace,
            createdAt,
            virtualTable,
            customerNotes,
            statusChangedAt,
            type,
            deliveryAddress,
            createdByBackoffice,
            friendlyOrderId,
            selfPickup,
            deliveryCost,
            qrCodeName
        )
    }


    private findItemByProduct(product: Product) {
        return _.find(this.items, i => {
            return i.product.id === product.id
        })
    }


    public removeProduct(product: Product) {
        const item = this.findItemByProduct(product)
        if (!item) return
        item.decreaseQty(1)
        if (item.qty <= 0) _.remove(this.items, i => i === item)
    }

    public addProduct(product: Product) {
        const item = this.findItemByProduct(product)
        if (item) item.increaseQty(1)
        else {
            const newItem = OrderItem.create({
                qty: 1,
                product,
                total: product.price
            })
            this.items.push(newItem)
        }

    }


    public isTakeAway() {
        return this.type === "TAKEAWAY"
    }
    public isDelivery() {
        return this.type === "DELIVERY"
    }


    public isFromTable() {
        return this.type === "INRISTOPUB"
    }

    public isPending() {
        return this.status.id === "PENDING"
    }

    public isAccepted() {
        return this.status.id === "ACCEPTED"
    }


    public isInProposedDateChangeState() {
        return this.status.id === "OWNER_DATE_CHANGE_PROPOSED" || this.status.id === "PAID_IN_APP_OWNER_DATE_CHANGE_PROPOSED"
    }


    public isRejected() {
        return this.status.id === "REJECTED"
    }

    public isInPendingPaymentConfirmationState() {
        return this.status.id === "PENDING_PAYMENT_CONFIRMATION"
    }

    public isPaid() {
        return this.status.id === "PAID" || this.status.id === "PAID_IN_APP"
    }


    public isCancelled() {
        return this.status.id === "CANCELLED" || this.status.id === "PAID_IN_APP_CANCELLED"
    }

    public isRefunded() {
        return this.status.id === "REFUNDED"
    }

    public isPaidInApp() {
        return this.status.id === "PAID_IN_APP"
    }



    public isDelivered() {
        return this.status.id === "DELIVERED"
    }


    public isPaidInAppDelivered() {
        return this.status.id === "PAID_IN_APP_DELIVERED"
    }



    public getTypeColor() {
        if (this.isTakeAway()) {
            return "#5d80d0"
        } else if (this.isFromTable() && !this.selfPickup) {
            return "#27C46B"
        } else if (this.isFromTable() && this.selfPickup) {
            return "#9d4abe"
        } else if (this.isDelivery()){
            return "#FFCD00"
        } else {
            return ""
        }
    }

    public getTypeLabel() {
        if (this.isTakeAway()) {
            return "Da asporto"
        } else if (this.isFromTable() && !this.selfPickup) {
            return "Dal tavolo"
        } else if (this.isFromTable() && this.selfPickup) {
            return "Da banco"
        } else if (this.isDelivery()){
            return "Consegna"
        } else  {
            return
        }
    }

    public statusColor() {


        if (
            this.isCancelled()
            || this.isRejected()
        ) {
            return "#D51C1C"
        } else if (
            this.isInProposedDateChangeState()
            || this.isPending()
            || this.isInPendingPaymentConfirmationState()
        ) {
            return "#ff6a00"
        } else if (
            this.isPaid()
            || this.isAccepted()
            || this.isRefunded()
        ) {
            return "#2195d8"
        }
    }

    public formatStatus() {

        if (this.isRejected()) {
            return "Rifiutato"
        } else if (this.isPending()) {
            return "In attesa di conferma"
        } else if (this.isInPendingPaymentConfirmationState()) {
            return "Elaborazione pagamento in corso"
        } else if (this.isInProposedDateChangeState()) {
            return "Richiesto cambio di data"
        } else if (this.isCancelled()) {
            return "Cancellato"
        } else if (this.isPaidInApp()) {
            return "Pagato in app"
        } else if (this.isPaid()) {
            return "Pagato"
        } else if (this.isAccepted()) {
            return "Accettato"
        } else if (this.isRefunded()) {
            return "Rimborsato"
        } else {
            return ""
        }

    }


    public isEmpty() {
        return this.items.length <= 0
    }

    public isInStructure(): boolean {
        return this.type === "INRISTOPUB"
    }
}