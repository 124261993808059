import {useEffect, useState} from "react";
import {DropResult} from "react-beautiful-dnd";
import _ from "lodash";

type ItemWithPos = {
    pos: number
}

export function useDragAndDropHelpers<T extends ItemWithPos>(items: T[]) {
    const [realItems, setRealItems] = useState(items)

    useEffect(() => {
        setRealItems(items)
    }, [items])

    function getPrev(index: number): undefined | T {
        if (index === 0) return undefined
        return realItems[index - 1]
    }


    function getNext(index: number): undefined | T {
        if (index >= realItems.length - 1) return undefined
        return realItems[index + 1]
    }


    function computeNewPos(prevItem?: T, nextItem?: T): number | undefined {
        if (!prevItem && nextItem) return nextItem.pos / 2
        else if (!nextItem && prevItem) return prevItem.pos * 2
        else if (nextItem && prevItem) return (prevItem.pos + nextItem.pos) / 2
        else return undefined
    }

    function getPrevAndNext(source: number, destination: number): {
        prevItem?: T,
        nextItem?: T
    } {
        if (destination > source) {
            const prevItem = realItems[destination]
            const nextItem = getNext(destination)
            return {
                prevItem,
                nextItem
            }
        } else {
            const prevItem = getPrev(destination)
            const nextItem = realItems[destination]
            return {
                prevItem,
                nextItem
            }
        }
    }



    function updatePosOnDrag(result: DropResult): T | undefined {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) return

        const item2move = realItems[result.source.index]

        const {nextItem,prevItem} = getPrevAndNext(result.source.index, result.destination.index)

        const newPos = computeNewPos(prevItem,nextItem)
        if (!newPos) throw new Error("Oooops something went wrong")

        item2move.pos = newPos


        setRealItems([
            ..._.sortBy(realItems, i => i.pos)
        ])


        return item2move

    }

    return {
        items: realItems,
        updatePosOnDrag
    }
}