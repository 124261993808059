import {Editor} from 'react-draft-wysiwyg';
import React, {useRef, useState} from "react";
import {convertToRaw, EditorState} from "draft-js";
import {useAllCustomerEmailTemplateRenderers} from "./useAllCustomerEmailTemplateRenderers";
import {Loading} from "../../components/Loading/Loading";
import {SwippySelect} from "../../components/SwippySelect/SwippySelect";
import {Select} from "antd";
import {CustomerEmailType} from "./CustomerEmailType";
import _ from "lodash";
import {CustomerEmailTemplateVariableDTO} from "./CustomerEmailTemplateRendererDTO";
import {CustomerEmailTemplateRenderer} from "./CustomerEmailTemplateRenderer";
import {RistopubEmailConfig} from "./RistopubEmailConfig";
import {useEmailConfig} from "./useEmailConfig";
import {useUpsertEmailConfig} from "./useUpsertEmailConfig";
import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import {EmailConfig} from "./EmailConfig";
import {EmailConfigDTO} from "./RistopubEmailConfigDTO";
import {Debouncer} from "../../Debouncer";
import {useRenderCustomerEmailTestPreview} from "./useRenderCustomerEmailTestPreview";
import {useCurrentUser} from "../../auth/hooks/useCurrentUser";
import {CroppableImageInput} from "../../ristopub/CroppableImageInput";
import {ImageUploader} from "../../menu/ProductsScreen/EditProductModal/ImageUploader";


type VariableExplProps = {
    variable: CustomerEmailTemplateVariableDTO
}

function VariableExpl(props: VariableExplProps) {



    return <div>
        <h4 className={"mb-0"}>{"{{"}{props.variable.name}{"}}"}</h4>
        {props.variable.description ? <p>
            {props.variable.description}
        </p> : null}
    </div>
}



type DebouncedWysiwygEditorProps = {
    initialState?: EditorState
    onEditorStateChange: (newState: EditorState) => void
}

function DebouncedWysiwygEditor(props: DebouncedWysiwygEditorProps) {

    const [state, setState] = useState(props.initialState)



    const {current: debouncer} = useRef(new Debouncer(500))

    function updateState(newState: EditorState) {
        setState(newState)
        debouncer.debounce(() => {
            props.onEditorStateChange(newState)
        })
    }

    return <Editor
        editorState={state}
        toolbarClassName="toolbarClassName"
        wrapperClassName="border border-gray-300 rounded"
        editorClassName="p-2"
        onEditorStateChange={updateState}
    />
}

const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

type MailSettingsMainComponentProps = {
    renderers: CustomerEmailTemplateRenderer[],
    config?: RistopubEmailConfig
}

function MailSettingsMainComponent(props: MailSettingsMainComponentProps) {
    const [selectedTemplate, setSelectedTemplate] = useState<CustomerEmailType>(props.renderers[0].customerEmailType)

    const [dirty, setDirty] = useState(false)

    const [logo2upload, setLogo2Upload] = useState<File>()

    const [savedLogoSrc, setSavedLogoSrc] = useState<string>()

    const {user} = useCurrentUser()

    const {upsertEmailConfig, loading} = useUpsertEmailConfig()

    const initialEmailConfig = _.reduce(props.config?.mailConfig, (res, ec) => {
        const contentState = ec.getWysiwygContentState()
        if (contentState) res[ec.type] = EditorState.createWithContent(contentState)
        return res
    }, {} as Record<CustomerEmailType, EditorState>)

    const [editorStates, setEditorStates] = useState<Record<CustomerEmailType, EditorState>>(initialEmailConfig)

    const renderers = props.renderers

    const renderersByType = _.keyBy(renderers, r => r.customerEmailType)

    const [renderingPreview, setRenderingPreview] = useState(false)

    const {renderTestPreview} = useRenderCustomerEmailTestPreview()

    function updateCurrentState(newState: EditorState) {
        const states = _.cloneDeep(editorStates)
        states[selectedTemplate] = newState
        setEditorStates(states)
        if (!dirty) setDirty(true)
    }



    function buildEmailConfig(): EmailConfigDTO[] {
        const newEmailConfigs: EmailConfigDTO[] = []

        _.forEach(editorStates, (state, emailType) => {
            newEmailConfigs.push(
                {
                    wysiwygJsonState: JSON.stringify(convertToRaw(state.getCurrentContent())),
                    type: emailType as CustomerEmailType
                }
            )
        })

        return newEmailConfigs
    }

    async function uploadLogoIfNeeded() {
        const imgUploader = new ImageUploader()
        if(logo2upload) {
            return await imgUploader.uploadImage(logo2upload)
        }
    }

    async function save() {


        const uploadRes = !savedLogoSrc ? await uploadLogoIfNeeded() : undefined

        const newEmailConfigs = buildEmailConfig()

        await upsertEmailConfig(
            newEmailConfigs,
            uploadRes?.src || savedLogoSrc || props.config?.logoSrc
        )
        setDirty(false)
    }

    const selectedRenderer = renderersByType[selectedTemplate]

    const currentEditorState = editorStates[selectedTemplate]



    async function openPreview() {

        const ristopub = user?.getRistopub()
        if (!ristopub) throw new Error(`openPreview missing ristopub`)


        setRenderingPreview(true)

        const mailConfigs = buildEmailConfig()

        const logoSrc: string | undefined = logo2upload ? (await uploadLogoIfNeeded())?.src : props.config?.logoSrc


        setSavedLogoSrc(logoSrc)

        const preview = await renderTestPreview(
            ristopub.name || "",
            selectedTemplate,
            {
                ristopubId: ristopub.id,
                logoSrc: logoSrc,
                mailConfigs: mailConfigs
            }
        )

        setRenderingPreview(false)

        const previewWindow = window.open()
        if (!previewWindow) throw new Error(`Error opening preview window`)
        previewWindow.document.write(preview)

    }

    return <>

        <div className={"mb-2"}>


            <label className={"mb-2 block"}>
                Logo della mail

            </label>

            <CroppableImageInput
                onChange={(newImg) => {
                    setLogo2Upload(newImg)
                    setSavedLogoSrc(undefined)
                    setDirty(true)
                }}
                value={logo2upload}
                aspectRatio={2.75}
                initialSrc={props.config?.logoSrc}
            />

            <label className={"mb-2 mt-2 block"}>
                Seleziona il template che vuoi modificare
            </label>
            <SwippySelect
                onChange={v => {
                    setSelectedTemplate(v)
                }}
                value={selectedTemplate}>
                {
                    renderers.map(r => {
                        return <Select.Option key={r.customerEmailType} value={r.customerEmailType}>{
                            r.getName()
                        }</Select.Option>
                    })
                }
            </SwippySelect>
        </div>



        {
            selectedTemplate && selectedRenderer
                ?   <>
                    {
                        selectedRenderer.hasVariables()
                            ? <div>
                                <h3 className={"font-bold"}>Variabili ammesse</h3>
                                {
                                    selectedRenderer.variables.map(v => {
                                        return <VariableExpl key={v.name} variable={v} />
                                    })
                                }
                            </div>
                            : null
                    }
                    <DebouncedWysiwygEditor
                        key={selectedTemplate}
                        initialState={currentEditorState}
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="border border-gray-300 rounded"
                        // editorClassName="p-2"
                        onEditorStateChange={updateCurrentState}
                    />
                    {/*<div>*/}
                    {/*    {currentEditorState ? JSON.stringify(convertToRaw(currentEditorState.getCurrentContent())) : null}*/}
                    {/*</div>*/}
                </>
                : null
        }

        <div

            className={"flex items-center justify-end w-full mt-4"}>
            <SwippyButton
                loading={renderingPreview}
                onClick={openPreview}
                className={"mr-2"}
                type={"primary"}>
                Anteprima
            </SwippyButton>
            <SwippyButton
                loading={loading}
                onClick={save}
                disabled={!dirty}
                type={"primary"}>
                Salva
            </SwippyButton>

        </div>

    </>
}


export function MailSettingsTab() {

    const {loading: loadingEmailTemplateRenderers, renderers} = useAllCustomerEmailTemplateRenderers()
    const {loading: loadingEmailConfig, emailConfig} = useEmailConfig()


    const loading = loadingEmailTemplateRenderers || loadingEmailConfig

    if (loading) return <div className={"flex items-center justify-center"}>
        <Loading />
    </div>

    return <MailSettingsMainComponent
        config={emailConfig}
        renderers={renderers} />
}