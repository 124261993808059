import {User} from "../../auth/user/User";
import React, {useEffect, useState} from "react";
import {useUpsertNotificationSettings} from "./useUpsertNotificationSettings";
import {useNotificationConfig} from "./useNotificationConfig";
import {SwippyInput} from "../../components/SwippyInput/SwippyInput";
import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import {Loading} from "../../components/Loading/Loading";

type Props = {
    user: User
}
export function NotificationSettingsTab(props: Props) {

    const ristopubId = props.user.getRistopub().id

    const [dirty, setDirty] = useState(false)
    const [slackUrl, setSlackUrl] = useState<string>()

    const {
        upsertNotificationSettings,
        loading: upserting
    } = useUpsertNotificationSettings()

    const {config, loading} = useNotificationConfig(ristopubId)

    useEffect(() => {
        setSlackUrl(config?.slackPushUrl)
    }, [config])


    if (loading) return <div className={"flex items-center justify-center"}>
        <Loading />
    </div>

    async function save() {

        if (!slackUrl) return

        await upsertNotificationSettings(
            ristopubId,
            slackUrl
        )
        setDirty(false)
    }

    return <div>
        <div className={"flex flex-col"}>
            <label className={"mb-2"}>URL Notifica Slack</label>
            <SwippyInput
                value={slackUrl}
                onChange={e => {
                    setDirty(true)
                    setSlackUrl(e.target.value)
                }}
            />
        </div>
        <div className={"justify-end flex mt-4"}>
            <SwippyButton

                onClick={save}
                loading={upserting}
                disabled={!dirty}
                type={"primary"}>
                Salva
            </SwippyButton>
        </div>


    </div>
}