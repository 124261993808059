import {MainLayout} from "../../components/MainLayout/MainLayout";
import React, {useState} from "react";
import {SwippyTitleWithCta} from "../../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {EditCategoryModal} from "./EditCategoryModal";
import {Category} from "./classes/Category";
import {CategoriesList} from "./CategoriesList/CategoriesList";
import {useGetCategories} from "./useGetCategories";
import {ListLoading} from "../../components/Loading/ListLoading";

export function CategoriesScreen() {



    const [editingCategory, setEditingCategory] = useState<Category>()
    const {categories,loading} = useGetCategories()

    function openNewCatModal() {
        const pos = categories.length ? categories[0].pos / 2 : 1
        setEditingCategory(Category.create({
            pos
        }))
    }
    function closeNewCatModal() {
        setEditingCategory(undefined)
    }



    return <MainLayout withSider={true} withHeader={true}>
        <div style={{padding: 16, display: "flex", flexDirection: "column", flex: 1}}>
            <SwippyTitleWithCta
                subTitle={categories.length ? "Tieni premuto su una categoria e trascinala per modificare la sua posizione nella lista" : undefined}
                gaCategory={"menu_management"}
                gaLabel={"menu_management_open_create_category"}
                title={"Categorie"}
                onClick={openNewCatModal}
                ctaLabel={"Aggiungi categoria"}
            />
            <EditCategoryModal
                onCancel={closeNewCatModal}
                visible={!!editingCategory}
                category={editingCategory}/>
            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "column"
            }}>
                {!categories.length && loading ? <ListLoading/> : <CategoriesList
                    categories={categories}
                    onEdit={(category) => {
                        setEditingCategory(category)
                    }}/>
                }
            </div>
        </div>
    </MainLayout>
}
