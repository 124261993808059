import {Dropdown, Menu} from 'antd';
import React, {useState} from "react"
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {LoadingOutlined, MoreOutlined} from "@ant-design/icons"
import styles from "./OrderActionsDropdown.module.scss";
import {GenericErrorHandler} from "../../../../../classes/GenericErrorHandler";

type PropTypes = {
    showArchiveButton?: boolean
    onArchive?: () => void | Promise<void>
    onEdit?: () => void
}
export function OrderActionsDropdown(props: PropTypes) {





    const menu = (
        <Menu>
            <Menu.Item>
                <SwippyButton onClick={props.onEdit} type={"link"}>
                   Modifica
                </SwippyButton>
            </Menu.Item>
        </Menu>
    );



    return props.onEdit ? <Dropdown
        trigger={["click"]}
        overlay={menu}>


        {<MoreOutlined className={styles.archiveIcon} />}


    </Dropdown> : null

}