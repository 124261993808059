import moment, {Moment} from "moment-timezone";
import React, {useEffect, useState} from "react";
import styles from "./TakeawayOrdersMinutesCounter.module.scss"

type PropTypes = {
    dueDate?: Moment
}


function computeDifference(dueDate?: Moment) {
    if (!dueDate) return undefined
    return dueDate.diff(moment(), "minutes")
}

export function TakeawayOrdersMinutesCounter(props: PropTypes) {


    const [difference,setDifferenc] = useState(computeDifference(props.dueDate))

    useEffect(() => {
        const interval = setInterval(() => {
            setDifferenc(computeDifference(props.dueDate))
        }, 60 * 1000)


        return () => {
            clearInterval(interval)
        }
    }, [])

    if (!difference) return null
    if (difference < 0) {
        return <span className={styles.wrapper}>{`(Ritardo di ${-difference} minuti)`}</span>
    } else {
        return <span className={styles.wrapper}>{`(Mancano ${difference} minuti)`}</span>
    }
}