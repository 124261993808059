import {Ristopub} from "../ristopub/Ristopub";
import {useQrCodes} from "./useQrCodes";
import {QrCodeType} from "./QrCodeType";
import React from "react";
import {QrCodesList} from "./QrCodesList";

type Props = {
    ristopub: Ristopub
}
export function NewDeliveryAndTakeawayQrCodes(props: Props) {
    const {qrCodes, loading, refetch} = useQrCodes(QrCodeType.DELIVERY_AND_TAKEAWAY)


    return <QrCodesList
        type={QrCodeType.DELIVERY_AND_TAKEAWAY}
        loading={loading}
        qrCodes={qrCodes || []}
        ristopub={props.ristopub}
        refetch={refetch}
    />
}