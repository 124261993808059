import {Order} from "./Order";
import {OrderDTO} from "./OrderDTO";
import {OrderItemDataMapper} from "./OrderItem/OrderItemDataMapper";
import _ from "lodash";
import moment from "moment-timezone"
import {VirtualTableDataMapper} from "../VirtualTableDataMapper";
import {AddressDataMapper} from "../../ristopub/Address/AddressDataMapper";

export class OrderDataMapper {
    private virtualTableDataMapper: VirtualTableDataMapper
    constructor(
        private orderItemDataMapper: OrderItemDataMapper,
        private addressDataMapper = new AddressDataMapper(),
        virtualTableDataMapper?: VirtualTableDataMapper

    ) {
        this.virtualTableDataMapper = virtualTableDataMapper ? virtualTableDataMapper : new VirtualTableDataMapper(this)
    }

    toOrder(d: OrderDTO): Order {



        return Order.create({
            selfPickup: d.selfPickup,
            inPlace: d.inPlace,
            id: d.id,
            type: d.type,
            qrCodeName: d.qrCodeName,
            friendlyOrderId: d.friendlyOrderId,
            status: d.orderStatus,
            deliveryCost: d.deliveryCost,
            archived: d.archived,
            total: d.total,
            customerNotes: d.customerNotes,
            createdByBackoffice: d.createdByBackoffice,
            deliveryAddress: d.deliveryAddress ? this.addressDataMapper.toEntity(d.deliveryAddress) : undefined,
            items: _.map(d.orderItems, oi => {
                return this.orderItemDataMapper.toOrderItem(oi)
            }),
            dueDate: d.dueDate ? moment(d.dueDate) : undefined,
            deliveryDate: d.deliveryDate ? moment(d.deliveryDate) : undefined,
            createdAt: d.createdAt ? moment(d.createdAt) : undefined,
            statusChangedAt: d.statusChangedAt ? moment(d.statusChangedAt) : undefined,
            virtualTable: d.virtualTable ? this.virtualTableDataMapper.toVirtualTable(d.virtualTable) : undefined
        })
    }
}