import {useContext} from "react";
import {SwippyLoggedUserCtx} from "./SwippyLoggedUserCtx";
import _ from "lodash";

export function useSwippyLoggedUserCtx() {
    const {loggedUser,logout,loadingUser} = useContext(SwippyLoggedUserCtx)


    return {
        loggedUser: _.cloneDeep(loggedUser),
        logout,
        loadingUser
    }
}