import {useOrdersAggregatedByDate} from "./useOrdersAggregatedByDate";
import {MainLayout} from "../components/MainLayout/MainLayout";
import React from "react";
import {ListLoading} from "../components/Loading/ListLoading";
import {SwippyTitleWithCta} from "../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {OrderByDateItem} from "./OrderByDateItem";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";

export function CompensationReportScreen() {
    const {ordersByDate, hasNextPage, fetchNextPage, loading, fetchingMore} = useOrdersAggregatedByDate()


    if (!ordersByDate.length && loading) {
        return <MainLayout
            withSider={true}
            withHeader={true}>
            <ListLoading />
        </MainLayout>
    }

    return <MainLayout
        withSider={true}
        withHeader={true}>

        <div className={"p-2"}>
            <SwippyTitleWithCta title={"Corrispettivi"} />
            {
                ordersByDate.map(o => {
                    return <div
                        key={o.date.toISOString()}
                        className={"mb-2"}>

                        <OrderByDateItem ordersByDate={o}/>
                    </div>
                })
            }
            {
                hasNextPage ? <div className={"flex items-center justify-center mt-2"}>

                    <SwippyButton
                        onClick={fetchNextPage}
                        loading={fetchingMore}
                        type={"link"}>
                        Carica di più
                    </SwippyButton>
                </div> : null
            }

        </div>

    </MainLayout>
}