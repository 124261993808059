import React, {useState} from "react";
import {TakeAwaySlotsInput} from "./TakeAwaySlotInput/TakeAwaySlotsInput";
import {SwippyInputNumber} from "../../../components/SwippyInputNumber/SwippyInputNumber";
import {DeliveryArea} from "../../../delivery/DeliveryArea/DeliveryArea";
import {User} from "../../../auth/user/User";
import {useUpsertMyRistopub} from "../../../ristopub/useUpsertMyRistopub";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import _ from "lodash";
import {SwippyCheckbox} from "../../../components/SwippyCheckbox/SwippyCheckbox";
import {CheckboxChangeEvent} from "antd/es/checkbox";
type PropTypes = {

    user: User
}
export function TakeAwaySettings(props: PropTypes) {

    const [dirty, setDirty] = useState(false)
    const [minimumOrderValue, setMinimumOrderValue] = useState(props.user.getRistopub().minimumTakeawayOrderValue)

    const [enableTakeaway, setEnableTakeaway] = useState(props.user.getRistopub().enableTakeaway)


    const {upsertMyRistopub, upsertingMyRistopub} = useUpsertMyRistopub()



    function onChangeEnableTakeaway(e: CheckboxChangeEvent) {
        setDirty(true)
        setEnableTakeaway(e.target.checked)
    }

    async function save() {



        try {
            const ristopub = _.cloneDeep(props.user.getRistopub())
            ristopub.minimumTakeawayOrderValue = minimumOrderValue
            ristopub.enableTakeaway = enableTakeaway
            await upsertMyRistopub(ristopub)
            setDirty(false)
        } catch (e) {
            console.error(e)
        }
    }

    return <div>
        <div className={"grid grid-cols-1 w-full p-2"}>


            <div className={"flex flex-col col-span-1 "}>
                <div className={"mb-2"}>
                    <SwippyCheckbox
                        onChange={onChangeEnableTakeaway}
                        checked={enableTakeaway}>
                        Abilita asporto
                    </SwippyCheckbox>
                </div>
                <label>
                    Ordine minimo
                </label>
                <SwippyInputNumber
                    className={"mt-2"}
                    step={0.01}
                    onChange={(price) => {
                        if (typeof price !== "string") {
                            setDirty(true)
                            setMinimumOrderValue(price)
                        }
                    }}
                    value={minimumOrderValue}
                    min={0}
                    formatter={value => `€ ${value}`}
                    parser={value => value ? value.replace(/€\s*/g, '').replace(",", ".") : 0}
                />

            </div>

        </div>
        <TakeAwaySlotsInput/>
        <div className={"flex p-2 justify-end"}>
            <SwippyButton type={"primary"}
                          disabled={!dirty}
                onClick={save}
                loading={upsertingMyRistopub}
            >
                Salva
            </SwippyButton>
        </div>
    </div>
}