import React from "react";
import {useInStructureOrdersToBeHandledCtx} from "../../../../InStructureOrdersToBeHandled/useInStructureOrdersToBeHandledCtx";
import {OrdersToBeHandledBadge} from "../OrdersToBeHandledBadge/OrdersToBeHandledBadge";


type PropTypes = {
    children?: React.ReactNode
    className?: string

}
export function InStructureOrdersToBeHandledBadge(props: PropTypes) {
    const {orders} = useInStructureOrdersToBeHandledCtx()

    return <OrdersToBeHandledBadge
        className={props.className}
        orders={orders}
    >
        {props.children || null}
    </OrdersToBeHandledBadge>

}