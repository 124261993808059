import {MainLayout} from "../../components/MainLayout/MainLayout";
import React, {useState} from "react";
import {SwippyTitleWithCta} from "../../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {useCurrentCategory} from "../CategoriesScreen/useCurrentCategory";
import {EditProductModal} from "./EditProductModal/EditProductModal";
import {Product} from "./classes/Product";
import {ProductsList} from "./ProductsList/ProductsList";
import {useGetProductsByCategory} from "./useGetProductsByCategory";
import {ListLoading} from "../../components/Loading/ListLoading";

export function ProductsScreen() {

    const [editingProduct, setEditingProduct] = useState<Product>()

    const {category, loading: loadingCategory} = useCurrentCategory()


    const titleComponents = ["Prodotti"]
    if (category && category.name) titleComponents.push(`(${category.name})`)

    const {products, loading: loadingProducts} = useGetProductsByCategory(category)



    function openNewProductModal() {
        const pos = products.length ? products[0].pos / 2 : 1
        const published = true
        setEditingProduct(Product.create({
            pos,
        published
        }))
    }


    function closeNewProductModal() {
        setEditingProduct(undefined)
    }


    if (loadingCategory) return <MainLayout
        withSider={true} withHeader={true}
    >
        <ListLoading/>
    </MainLayout>

    return <MainLayout withSider={true} withHeader={true}>
        <div style={{padding: 16, display: "flex", flexDirection: "column", flex: 1}}>
            <SwippyTitleWithCta
                subTitle={products.length ? "Tieni premuto su un prodotto e trascinalo per modificare la sua posizione nella lista" : undefined}
                gaCategory={"menu_management"}
                gaLabel={"menu_management_open_create_product"}
                onClick={openNewProductModal}
                ctaLabel={"Aggiungi prodotto"}
                title={titleComponents.join(" ")}/>

            {category ? <EditProductModal
                onCancel={closeNewProductModal}
                product={editingProduct}
                visible={!!editingProduct}
                category={category}
            /> : null }

            {
                category ? loadingProducts
                    ? <ListLoading/>
                    : <ProductsList
                        products={products}
                        category={category}/>
                    : null
            }
        </div>

    </MainLayout>
}