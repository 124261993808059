import {gql, useMutation} from "@apollo/client";
import {Category} from "./classes/Category";
import {GET_CATEGORIES_QUERY} from "./CategoriesList/GetCategoriesQuery";
import {useState} from "react";

const UPSERT_CATEGORY_MUTATION = gql`
    mutation upsertCategory($input: UpsertCategoryInput!) {
        upsertCategory(input:$input) {
            id
            name
            description
            __typename
            pos
        }
    }
`
export function useUpsertCategory() {
    const [upsertCategoryMutation] = useMutation(UPSERT_CATEGORY_MUTATION)
    const [upserting, setUpserting] = useState(false)
    async function upsertCategory(category: Category) {

        setUpserting(true)

        try {
            await upsertCategoryMutation({
                variables: {
                    input: {
                        categoryId: category.id,
                        name: category.name,
                        description: category.description,
                        pos: category.pos
                    }
                },
                update: !category.id ? (cache, { data: { upsertCategory } }) => {
                    const data = cache.readQuery<{
                        getCategories: Category[]
                    }>({
                        query: GET_CATEGORIES_QUERY,
                    });
                    const newData = {
                        getCategories: [
                            ...data?.getCategories || [],
                            upsertCategory
                        ]
                    }
                    cache.writeQuery({
                        query: GET_CATEGORIES_QUERY,
                        data: newData,
                    });
                } : undefined
            })
        } finally {
            setUpserting(false)
        }
    }


    return {
        upsertCategory,
        upsertingCategory: upserting
    }
}