import React from "react";
import {useInStructureOrdersToBeHandledCtx} from "../../InStructureOrdersToBeHandled/useInStructureOrdersToBeHandledCtx";
import {OrdersListPage} from "../OrdersListPage/OrdersListPage";


export function InStructureOrdersScreen() {

    const {orders, loading,refetch, lastQueryExecutedAt} = useInStructureOrdersToBeHandledCtx()

    const realLoading = loading && !orders.length





    return <OrdersListPage
        createOrderAvailableTypes={["ONTABLE"]}
        title={"Ordini in struttura"}
        orders={orders}
        lastQueryExecutedAt={lastQueryExecutedAt}
        refetch={refetch}
        loading={realLoading}
    />

}