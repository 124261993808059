import {gql, useMutation} from "@apollo/client";

const ARCHIVE_ORDER_MUTATION = gql`
    mutation archiveOrder($input: ArchiveOrderInput!) {
        archiveOrder(input: $input) {
            id
            __typename
            name
            orders {
                archived
                id
                __typename
                orderStatus {
                    id
                    proposedDateChange
                }
            }
        }
    }
`

export function useArchiveOrderMutation() {
    const [archiveOrderMutation] = useMutation(ARCHIVE_ORDER_MUTATION)


    return {
        archiveOrderMutation
    }
}