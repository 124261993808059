import {gql, useMutation} from "@apollo/client";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";
import {CreateDeliveryOrderInput} from "./CreateDeliveryOrderInput";
import {OrderItemInput} from "./OrderItemInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";

const BACKOFFICE_CREATE_DELIVERY_ORDER = gql`
    mutation backofficeCreateDeliveryOrder($input: BackofficeCreateDeliveryOrderInput!) {
        backofficeCreateDeliveryOrder(input: $input) {
            id
            cartId

            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }

            virtualTable {
                id
            }
        }
    }
`

export function useBackofficeCreateDeliveryOrder() {

    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))

    const [createDeliveryOrderMutation, {
        loading
    }] = useMutation<{
        backofficeCreateDeliveryOrder: OrderDTO
    }>(BACKOFFICE_CREATE_DELIVERY_ORDER)


    async function createDeliveryOrder(
        input: CreateDeliveryOrderInput
    ) {
        const res = await createDeliveryOrderMutation({
            variables: {
                input: {
                    items: input.items,
                    deliveryAddress: input.deliveryAddress,
                    deliveryDate: input.deliveryDate.toDate(),
                    customerName: input.customerName,
                    customerNotes: input.customerNotes,
                    customerPhone: input.customerPhone
                }
            }
        })


        return res.data?.backofficeCreateDeliveryOrder ? orderDataMapper.toOrder(res.data.backofficeCreateDeliveryOrder) : undefined
    }


    return {
        createDeliveryOrder,
        loading
    }
}