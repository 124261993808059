import {VirtualTable} from "../../virtualTable/VirtualTable";
import React from "react";
import _ from "lodash";
import {VirtualTableListItem} from "./VirtualTableListItem/VirtualTableListItem";

type PropTypes = {
    virtualTables: VirtualTable[]
}


export function VirtualTableToBePaidList(props: PropTypes) {




    return <div>
        {
            _.map(props.virtualTables, vt => <VirtualTableListItem
                showActions={true}
                key={vt.id} virtualTable={vt}/>)
        }
    </div>
}