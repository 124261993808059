import React from "react";
import {Dropdown, Layout, Menu} from "antd";
import styles from "./SwippyHeader.module.scss"
import {Breadcrumbs} from "./Breadcrumbs";
import {useSwippyLoggedUserCtx} from "../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {SwippyButton} from "../../SwippyButton/SwippyButton";
import Cookies from "js-cookie"
import {ADMIN_LOGIN_AS_USER_COOKINE_NAME} from "../../../apolloClient/credentials/AdminLoginAsUserCredentials";
import {MenuOutlined} from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

type PropTypes = {
    openMenuOnMobile?: () => void
}

export function SwippyHeader(props: PropTypes) {


    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const {logout} = useSwippyLoggedUserCtx()


    function logoutAsAdminFromUser() {
        Cookies.remove(ADMIN_LOGIN_AS_USER_COOKINE_NAME, {
            domain: process.env.REACT_APP_DOMAIN
        })
        window.location.href = "/"
    }

    async function logoutAsNormalUser() {
        await logout()
        window.location.href = "/"
    }

    function logoutAndRedirectToAuth() {
        const token = Cookies.get(ADMIN_LOGIN_AS_USER_COOKINE_NAME)
        if (token) return logoutAsAdminFromUser()
        else return logoutAsNormalUser()
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <SwippyButton
                    onClick={logoutAndRedirectToAuth}
                    type={"link"}>
                    Esci
                </SwippyButton>
            </Menu.Item>

        </Menu>
    );




    return <Header className={styles.swippyHeader}>
        <div className={styles.headerContentWrapper}>
            <div className={styles.leftInfoWrapper}>
                {user && user.getRistopub() ? <span className={styles.currentRistopub}>{user.getRistopub().name}</span> : null}
                <div style={{
                    marginTop: 8
                }}>
                    <Breadcrumbs/>
                </div>


            </div>

            <div className={styles.rightInfoWrapper}>
                <Dropdown trigger={["click"]} overlay={menu}>
                    {user && user.getEmail() ? <span className={styles.email}>{user.getEmail()}</span> : <span className={styles.email}>Nessun email</span>}
                </Dropdown>
            </div>
        </div>
        <div className={styles.menuWrapper}>
            <MenuOutlined onClick={props.openMenuOnMobile} />
        </div>


    </Header>
}