export class OrderItemReportItem {
    constructor(
        public readonly title: string,
        public readonly subTitle: string,
        public readonly key: string,
        private qty: number = 0,
        private totalAmount: number
    ) {
    }

    public increaseQty(qty: number = 0): void {
        this.qty = this.qty + qty
    }

    public increaseTotal(total: number = 0): void {
        this.totalAmount = this.totalAmount + total
    }

    public getQty(): number {
        return this.qty
    }

    public getTotalAmount(): number {
        return this.totalAmount
    }
}