import {Modal} from "antd";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {ModalProps} from "antd/es/modal";
import styles from "./SwippyModal.module.scss"
import {nanoid} from "nanoid"
import {BodyScrollLocker} from "../../BodyScrollLocker/BodyScrollLocker";
import _ from "lodash";

type ModalOpenedDetectorProps = PropsWithChildren<{
    wrapClassName: string
    visible?: boolean
}>
function ModalOpenedDetector(props:ModalOpenedDetectorProps) {

    // useEffect(() => {
    //     if (props.visible) {
    //         BodyScrollLocker.addBodyScrollLockElementByClassName(props.wrapClassName)
    //     } else {
    //         BodyScrollLocker.removeBodyScrollLockElementByClassName()
    //     }
    // }, [props.visible])

    return <>

        {props.children || null}
        </>
}
type PropTypes = {
    children?: React.ReactNode
} & ModalProps
export function SwippyModal(props: PropTypes) {
    const [wrapClassName] = useState(nanoid())



    const classes = [styles.swippyModal]
    if (props.className) classes.push(props.className)
    return <Modal
        keyboard={props.keyboard ?? false}
        wrapClassName={wrapClassName}
        getContainer={() => {
            return document.body
        }}
        maskClosable={false}
        {...props} className={classes.join(" ")}>

        <ModalOpenedDetector wrapClassName={wrapClassName} visible={props.visible}>
            {props.children || null}
        </ModalOpenedDetector>


    </Modal>
}