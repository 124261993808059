import {useSwippyLoggedUserCtx} from "../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {ApolloCache, gql, useQuery} from "@apollo/client";
import {Ristopub} from "../../../ristopub/Ristopub";
import {DeliveryAreaDTO} from "../../../delivery/DeliveryArea/DeliveryAreaDTO";
import {DeliveryAreaDataMapper} from "../../../delivery/DeliveryArea/DeliveryAreaDataMapper";
import {useRef} from "react";
import _ from "lodash";


const GET_MY_RISTOPUB_DELIVERY_AREAS = gql`
    query getMyRistopubDeliveryAreas {
        getMyRistopubDeliveryAreas {
            id
            vertices {
                lat
                lng
            }
            deliveryPricePolicy {
                __typename
                ... on DeliveryAreaPerKmPricePolicy {
                    pricePerKm
                    type
                }

                ... on DeliveryAreaFlatPricePolicy {
                    price
                    type
                }
            }
            __typename
        }
    }
`


export function updateGetMyRistopubDeliveryAreasQuery(cache: ApolloCache<any>, newDeliveryAreas: DeliveryAreaDTO[]) {
    cache.writeQuery({
        query: GET_MY_RISTOPUB_DELIVERY_AREAS,
        data: {
            getMyRistopubDeliveryAreas: newDeliveryAreas
        }
    })
}



export function useGetMyRistopubDeliveryAreas() {


    const {loggedUser, loadingUser} = useSwippyLoggedUserCtx()


    const {current: deliveryAreaDataMapper} = useRef(new DeliveryAreaDataMapper())


    const {data, loading} = useQuery<{
        getMyRistopubDeliveryAreas: DeliveryAreaDTO[]
    }>(GET_MY_RISTOPUB_DELIVERY_AREAS, {
        skip: !loggedUser
    })





    return {
        loading: loading || loadingUser,
        deliveryAreas: _.map(data?.getMyRistopubDeliveryAreas, d => {
            return deliveryAreaDataMapper.toEntity(d)
        })
    }
}