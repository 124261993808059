import React, {useMemo} from "react";
import {Layout} from "antd";
import {SwippyMenuItem} from "./SwippyMenuItem/SwippyMenuItem";
import styles from "./SwippyMenu.module.scss"
import {useHistory, useLocation} from "react-router-dom"
import {ActiveRouteIdFactory} from "../../../menu/classes/ActiveRouteIdFactory";
import {OrdersToBePaidBadge} from "./OrdersToBePaidBadge/OrdersToBePaidBadge";
import {InStructureOrdersToBeHandledBadge} from "./InStructureOrdersToBeHandledBadge/InStructureOrdersToBeHandledBadge";
import {CloseOutlined} from "@ant-design/icons";
import {TakeawayOrdersToBeHandledBadge} from "./TakeawayOrdersToBeHandledBadge/TakeawayOrdersToBeHandledBadge";
import {LogoNew} from "./LogoNew";
import {useToModifiersScreen} from "../../../ProductModifier/useToModifiersScreen";
import {useToCompensationReport} from "../../../orders/useToCompensationReport";

const { Sider } = Layout;

type PropTypes = {
    opened?: boolean
    closeMenuOnMobile?: () => void
}

export function SwippyMenu(props: PropTypes) {

    const location = useLocation()
    const history = useHistory()


    const {toModifiers} = useToModifiersScreen()



    const {toCompensationReport} = useToCompensationReport()

    function toMenu() {
        history.push("/menu")
    }
    function toSettings() {
        history.push("/settings")
    }
    function toOrders() {
        history.push("/orders")
    }
    function toOrdersToBePaid() {
        history.push("/orderstopay")
    }
    function toQrCodes() {
        history.push("/qrcodes")
    }
    function toTakeawayOrders() {
        history.push("/takeawayorders")
    }
    function toPaidOrders() {
        history.push("/archive")
    }


    const factory = useMemo(() => {
        return new ActiveRouteIdFactory(location)
    }, [location])



    const routeId = factory.create()

    return <Sider className={[styles.menu, props.opened ? styles.opened : ""].join(" ")}>
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.logotypeWrapper}>
                    <LogoNew/>
                    {/*<SwippyLogotype/>*/}
                </div>
                <CloseOutlined className={styles.close} onClick={props.closeMenuOnMobile}/>
            </div>


            <SwippyMenuItem active={routeId === "menu" || routeId === "productsList"} onClick={toMenu} label={"Menu"}/>
            <SwippyMenuItem active={routeId === "product-modifiers"} onClick={toModifiers} label={"Modificatori prodotto"}/>
            <SwippyMenuItem

                render={(label) => {
                    return <InStructureOrdersToBeHandledBadge >
                        {label}
                    </InStructureOrdersToBeHandledBadge>
                }}
                active={routeId === "in-structure-orders"} onClick={toOrders} label={"Ordini in struttura"}/>

                <SwippyMenuItem

                render={(label) => {
                    return <TakeawayOrdersToBeHandledBadge >
                        {label}
                    </TakeawayOrdersToBeHandledBadge>
                }}
                active={routeId === "takeaway-orders"} onClick={toTakeawayOrders} label={"Ordini da asporto o consegna"}/>

            <SwippyMenuItem

                render={(label) => {
                    return <OrdersToBePaidBadge >
                        {label}
                    </OrdersToBePaidBadge>
                }}
                active={routeId === "orders-to-be-paid"} onClick={toOrdersToBePaid} label={"Conti"}/>
            <SwippyMenuItem active={routeId === "compensation-report"} onClick={toCompensationReport} label={"Corrispettivi"}/>
            <SwippyMenuItem active={routeId === "virtual-tables-archive"} onClick={toPaidOrders} label={"Storico ordini"}/>
            <SwippyMenuItem active={routeId === "qr-codes-page"} onClick={toQrCodes} label={"Gestione codici QR"}/>
            <SwippyMenuItem active={routeId === "settings"} onClick={toSettings} label={"Impostazioni"}/>
            {/*<div className={styles.linkBtnWrapper}>*/}
            {/*    <ShowOrderLink/>*/}
            {/*</div>*/}
        </div>
    </Sider>
}