import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {User} from "../../user/User";

type PropTypes = {
    children?: React.ReactNode
    path?: string
    user?: User | null
    ifAuthRedirectTo: string
} & RouteProps

export function UnauthGuardedRoute (props: PropTypes) {
    return <Route {...props} path={props.path}>
        {props.user ? <Redirect to={props.ifAuthRedirectTo}/> : (props.children || null) }
    </Route>
}