
import {Vertex} from "./DeliveryArea";



export enum DeliveryAreaPricePolicyType {
    FLAT = "FLAT",
    PER_KM = "PER_KM"
}


export type DeliveryAreaFlatPricePolicy = {
    type: DeliveryAreaPricePolicyType.FLAT
    price: number
}
export type DeliveryAreaPricePerKmPolicy = {
    type: DeliveryAreaPricePolicyType.PER_KM
    pricePerKm: number
}
export type DeliveryAreaPricePolicy = DeliveryAreaFlatPricePolicy | DeliveryAreaPricePerKmPolicy
export type DeliveryAreaDTO = {
    id: string
    vertices: Vertex[]
    deliveryPricePolicy: DeliveryAreaPricePolicy
}