import React from "react";
import {SwippyButton} from "../../SwippyButton/SwippyButton";
import styles from "./SwippyTitleWithCta.module.scss"
import {GaProps} from "../../../safeGtag/types";

type PropTypes = {
    title: string
    ctaLabel?: string
    subTitle?: string
    onClick?: () => void
} & GaProps

export function SwippyTitleWithCta(props: PropTypes) {

    const hasCta = props.ctaLabel && props.onClick

    return <div className={styles.titleWithCtaWrapper}>
        <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{props.title}</h2>
            {hasCta ? <SwippyButton
                gaLabel={props.gaLabel}
                gaCategory={props.gaCategory}
                gaAction={props.gaAction}
                onClick={props.onClick} type={"primary"}>{props.ctaLabel}</SwippyButton> : null}
        </div>
        {props.subTitle && props.subTitle.length ? <p className={styles.subTitle}>{props.subTitle}</p> : null}
    </div>
}