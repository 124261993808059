
import {FullPageLoading} from "../components/Loading/FullPageLoading";
import React from "react";
import {OnboardingGiveNameToRistopub} from "./GiveNameToRistopub/OnboardingGiveNameToRistopub";
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";

export function OnboardingScreen() {
    const {loadingUser,loggedUser:user} = useSwippyLoggedUserCtx()
    if (loadingUser) return <FullPageLoading/>
    if (!user) throw new Error("Missing user")

    return <OnboardingGiveNameToRistopub ristopub={user.getRistopub()}/>
}