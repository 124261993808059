import React from "react";
import styles from "./OrderCustomerNotes.module.scss"

type PropTypes = {
    customerNotes: string
}

export function OrderCustomerNotes(props: PropTypes) {
    return <div className={styles.wrapper}>
        <p className={styles.notesTitle}>
            Note del cliente
        </p>
        <p className={styles.notes}>
            {props.customerNotes}
        </p>
    </div>
}