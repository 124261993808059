import {CategoriesListComponent} from "./CategoriesListComponent/CategoriesListComponent";
import React from "react";
import {Category} from "../classes/Category";
import {useDeleteCategory} from "../useDeleteCategory";
import {useUpsertCategory} from "../useUpsertCategory";
import _ from "lodash";


type PropTypes = {
    onEdit?: (category: Category) => void
    categories: Category[]
}

export function CategoriesList(props: PropTypes) {

    const {upsertCategory} = useUpsertCategory()
    const {deleteCategory} = useDeleteCategory()

    async function updateCategoryPos(category: Category) {
        await upsertCategory(_.cloneDeep(category))
    }

    return <CategoriesListComponent
                onUpdatePos={updateCategoryPos}
                onEdit={props.onEdit}
                onCategoryClickDelete={deleteCategory}
                categories={props.categories}/>
}