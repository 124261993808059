import {SwippyModal} from "../../../../../components/SwippyModal/SwippyModal";
import React, {useEffect, useState} from "react";
import {DeliveryArea} from "../../../../../delivery/DeliveryArea/DeliveryArea";
import {Form, Select} from "antd";
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {SwippySelect} from "../../../../../components/SwippySelect/SwippySelect";
import _ from "lodash";
import {SwippyInputNumber} from "../../../../../components/SwippyInputNumber/SwippyInputNumber";
import {DeliveryAreaPricePolicyType} from "../../../../../delivery/DeliveryArea/DeliveryAreaDTO";


type PropTypes = {
    deliveryArea?: DeliveryArea
    onCancel?: () => void
    onEdit?: (deliveryArea: DeliveryArea) => void
    onDelete?: (deliveryArea: DeliveryArea) => void
}




const policyOptions = [
    {
        value: DeliveryAreaPricePolicyType.FLAT,
        label: "Costo fisso"
    },
    {
        value: DeliveryAreaPricePolicyType.PER_KM,
        label: "Costo per chilometro"
    }
]

export function EditDeliveryArea(props: PropTypes) {

    const [policy, setPolicy] = useState<DeliveryAreaPricePolicyType>()
    const [price, setPrice] = useState<number>(0)


    useEffect(() => {

        const area = props.deliveryArea

        if (area) {
            const policy = area.getPricePolicy()

            setPolicy(policy.type)
            setPrice(area.getPricePolicyPrice())
        } else {
            setPolicy(undefined)
            setPrice(0)
        }
    }, [props.deliveryArea ? props.deliveryArea.id : "no-id"])

    function saveArea() {
        const area = _.cloneDeep(props.deliveryArea)
        if (!area) return
        switch (policy) {
            case DeliveryAreaPricePolicyType.PER_KM:
                area.setPricePolicy({
                    type: DeliveryAreaPricePolicyType.PER_KM,
                    pricePerKm: price
                })
                break
            case DeliveryAreaPricePolicyType.FLAT:
                area.setPricePolicy({
                    type: DeliveryAreaPricePolicyType.FLAT,
                    price: price
                })
                break
        }

        if (props.onEdit) props.onEdit(area)
    }


    function deleteArea() {
        const area = _.cloneDeep(props.deliveryArea)
        if (!area) return
        if (props.onDelete) props.onDelete(area)
    }

    return <SwippyModal
        onCancel={props.onCancel}
        title={"Modifica area di consegna"}
        visible={!!props.deliveryArea}
        destroyOnClose={true}
        footer={<div className={"col-span-6 flex justify-end"}>
            <SwippyButton onClick={saveArea} type={"primary"} className={"mr-2"}>Salva</SwippyButton>
            <SwippyButton onClick={deleteArea} type={"primary"} className={"bg-red-600 border-red-600 hover:bg-red-800"}>Elimina</SwippyButton>
        </div>}
    >

        <Form layout={"vertical"}>
            <div className={"grid grid-cols-6"}>
                <div className={"col-span-6"}>
                    <Form.Item label={"Tipologia prezzo di consegna"}>
                        <SwippySelect onChange={v => {
                            setPolicy(v)
                            setPrice(0)
                        }} value={policy}>
                            {
                                _.map(policyOptions, p => {
                                    return <Select.Option value={p.value} key={p.value}>
                                        {p.label}
                                    </Select.Option>
                                })
                            }
                        </SwippySelect>
                    </Form.Item>
                </div>
                <div className={"col-span-6"}>
                    <Form.Item label={policy === DeliveryAreaPricePolicyType.FLAT ? "Prezzo fisso" : "Prezzo per KM"}>
                        <SwippyInputNumber
                            step={0.01}
                            onChange={(price) => {
                                if (typeof price !== "string") setPrice(price || 0)
                            }}
                            value={price}
                            min={0}
                            formatter={value => `€ ${value}`}
                            parser={value => value ? value.replace(/€\s*/g, '') : 0}
                        />
                    </Form.Item>
                </div>
            </div>
        </Form>
    </SwippyModal>
}