import {Category} from "../CategoriesScreen/classes/Category";
import {useQuery} from "@apollo/client";
import {GET_PRODUCTS_QUERY} from "./ProductsList/GetProductsQuery";
import {ProductDTO} from "./classes/ProductDTO";
import _ from "lodash";
import {ProductDataMapper} from "./classes/ProductDataMapper";

export function useGetProductsByCategory(category?: Category) {
    const {data, loading} = useQuery(GET_PRODUCTS_QUERY, {
        skip: !category,
        variables: {
            input: {
                categoryId: category ? category.id : undefined
            }
        }
    })

    const products: ProductDTO[] = _.get(data, "getProducts", [])

    const dataMapper = new ProductDataMapper()


    return {
        products: _.sortBy(_.map(products, p => dataMapper.toProduct(p)), p => p.pos),
        loading
    }
}