import {useEffect, useState} from "react";

function loadGoogleMapsAPI(onLoad: () => void) {
    if (window.googleMapsLoaded) return
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=drawing,geometry,places`
    script.type = "text/javascript"
    script.onload = () => {
        onLoad()
        window.googleMapsLoaded = true
    }
    document.head.appendChild(script)
}

export function useGoogleMapsAPI() {

    const [loading, setLoading] = useState(!window.googleMapsLoaded)

    useEffect(() => {
        if (window.googleMapsLoaded) return
        loadGoogleMapsAPI(() => {
            setLoading(false)
        })
    }, [])


    return {
        loadingGoogleAPI: loading
    }
}