import {OrderItem} from "./OrderItem";
import {OrderItemDTO} from "./OrderItemDTO";
import {ProductDataMapper} from "../../../menu/ProductsScreen/classes/ProductDataMapper";

export class OrderItemDataMapper {


    constructor(private productDataMapper: ProductDataMapper) {
    }

    toOrderItem(dto: OrderItemDTO): OrderItem {
        return OrderItem.create({
            id: dto.id,
            qty: dto.qty,
            total: dto.total,
            product: this.productDataMapper.toProduct(dto.product),
            variations: dto.variations
        })
    }
}