import {useState} from "react";
import moment from "moment-timezone";
import {gql, useQuery} from "@apollo/client";
import _ from "lodash";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import firebase from "firebase";
import {useRistopubUpdateListener} from "../orders/useRistopubUpdateListener";
import {OrderDTO} from "../virtualTable/Order/OrderDTO";
import {OrdersListItem} from "../orders/OrdersList/OrdersListItem";



const GET_TAKEAWAY_VIRTUALTABLES_WITHORDERS_TO_HANDLE_QUERY = gql`
    query getInStructureOrdersToBeHandled {
        getInStructureOrdersToBeHandled {
            friendlyOrderId
            createdByBackoffice
            total
            selfPickup
            type
            __typename
            statusChangedAt
            id
            createdAt
            archived
            inPlace
            qrCodeName
            customerNotes
            dueDate
            deliveryDate
            deliveryAddress {
                address
                city
                province
                postalCode
                country
            }
            orderStatus {
                id
                proposedDateChange
            }
            orderItems {
                total
                __typename
                id
                qty
                product {
                    __typename
                    id
                    name
                    description
                    subTitle
                }
                variations {
                    modifierId
                    modifierName
                    choices {
                        choiceId
                        choiceName
                        choicePrice
                        choiceQty
                    }
                }
            }

            virtualTable {
                id
                name
                customerName
                type
                customerPhone {
                    phone
                    __typename
                }
                __typename
            }
        }
    }
`


const orderItemDataMapper = new OrderItemDataMapper(
    new ProductDataMapper()
)




const orderDataMapper = new OrderDataMapper(orderItemDataMapper)

type UseFetchInStructureOrdersToBeHandledOptionsType = {
    skip?: boolean
}



export function useFetchInStructureOrdersToBeHandled(options: UseFetchInStructureOrdersToBeHandledOptionsType) {
    const [lastQueryExecutedAt, setLastQueryExecutedAt] = useState(moment())

    const {data, loading,refetch} = useQuery(GET_TAKEAWAY_VIRTUALTABLES_WITHORDERS_TO_HANDLE_QUERY, {
        fetchPolicy: "cache-and-network",
        skip: options.skip,
        pollInterval: 30 * 1000, //30s
    }) || {}


    async function refetchOrders() {
        if (refetch) {
            await refetch()
            setLastQueryExecutedAt(moment())
        }
    }

    async function handler(a: firebase.database.DataSnapshot, b?: string | null): Promise<void> {
        await refetchOrders()
    }


    useRistopubUpdateListener(handler, {
        skip: options.skip
    })



    const orderDtos = _.get(data, "getInStructureOrdersToBeHandled", []) as OrderDTO[]

    const orders = _.map(orderDtos, d => orderDataMapper.toOrder(d))



    return {
        orders: _.reduce(orders, (res: OrdersListItem[], o) => {



            if (o.virtualTable)
                res.push({
                    order: o,
                    virtualTable: o.virtualTable
                })

            return res
        }, []),
        loading,

        refetch,
        lastQueryExecutedAt
    }
}