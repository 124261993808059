import {Col, Form, Row, TimePicker} from "antd";
import {SwippyModal} from "../../components/SwippyModal/SwippyModal";
import React, {useEffect, useRef, useState} from "react";
import {RistopubTakeawaySlot} from "../../ristopub/RistopubTakeawaySlot";
import _ from "lodash";
import {Moment} from "moment-timezone"
import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import {Time} from "../../ristopub/Time";
import {SlotRangeInput} from "../../settings/SettingsScreen/TakeAwaySettings/SlotRangeInput/SlotRangeInput";
import {ITimeSlot} from "./ITimeSlot";

const {RangePicker} = TimePicker

type PropTypes = {
    timeSlot?: ITimeSlot
    onCancel: () => void
    index: number
    onSave: (takeawaySlot: ITimeSlot) => void
}



function EditTakeawaySlotModalComponent(props: PropTypes) {
    const [range, setRange] = useState<[Moment, Moment] | undefined | null>(props.timeSlot ? [props.timeSlot.start.getMoment(), props.timeSlot.end.getMoment()] : undefined)
    const [saving, setSaving] = useState(false)

    const rangePicker = useRef<any>(null)

    useEffect(() => {
        if (rangePicker.current) rangePicker.current.focus()
    }, [])

    useEffect(() => {
        setRange(props.timeSlot ? [props.timeSlot.start.getMoment(), props.timeSlot.end.getMoment()] : undefined)
    }, [props.timeSlot])

    async function save() {
        try {
            setSaving(true)
            const slot = _.cloneDeep(props.timeSlot)

            if (!slot || !range || range.length !== 2) throw new Error("Can't save in current state")
            const [start,end] = range
            slot.setStart(Time.create({
                time: start.format("HH:mm:ss")
            }))
            slot.setEnd(Time.create({
                time: end.format("HH:mm:ss")
            }))

            await props.onSave(slot)

        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            throw e
        } finally {
            setSaving(false)
        }
    }


    function printRange() {
        if (!range) return ""
        const [start,end] = range
        return ` (dalle ${start.format("HH:mm")} alle ${end.format("HH:mm")})`
    }


    function endIsBeforeStart() {
        if (!range) return true
        const [start,end] = range
        return end.isBefore(start)

    }


    const isEndBeforeStart = endIsBeforeStart()

    return <SwippyModal
        destroyOnClose
        title={"Modifica fascia oraria"}
        footer={<div>
        <SwippyButton

            gaCategory={"takeaway_settings"}
            gaLabel={"takeaway_settings_save_slot"}
            disabled={!props.timeSlot || !range || range.length !== 2 || isEndBeforeStart}
            loading={saving}
            onClick={save} type={"primary"}>
            Salva
        </SwippyButton>
    </div>}  onCancel={props.onCancel} visible={!!props.timeSlot}>
        <Form layout={"vertical"}>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        help={isEndBeforeStart ? <span style={{color: "red"}}>L' orario di fine non può essere prima dell' orario d' inizio</span> : undefined}
                        label={`Fascia oraria${printRange()}`}>
                        <SlotRangeInput
                            onChange={(v) => {
                                setRange(v)
                            }}

                            defaultValue={range}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </SwippyModal>
}

export function EditTimeSlotModal(props: PropTypes) {
    return <EditTakeawaySlotModalComponent
        {...props}
    />
}