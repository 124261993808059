import React, {useEffect, useState} from "react";
import {SwippyModal} from "../components/SwippyModal/SwippyModal";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";
import {QrCode} from "./QrCode";
import {QrCodeType} from "./QrCodeType";
import {SwippyCheckbox} from "../components/SwippyCheckbox/SwippyCheckbox";

type EditQrCodeModalProps = {
    newQrCode?: QrCode
    onCancel?: () => void
    onSave?: (newQrCode: QrCode) => void
    loading?: boolean
    type: QrCodeType
}

export function EditQrCodeModal(props: EditQrCodeModalProps) {

    const [enableDeliveryToLocation, setEnableDeliveryToLocation] = useState(false)
    const [enablePickup, setEnablePickup] = useState(false)
    const [enableDelivery, setEnableDelivery] = useState(false)
    const [enableTakeaway, setEnableTakeaway] = useState(false)
    const [name, setName] = useState<string>()


    useEffect(() => {
        if (!props.newQrCode) return
        setEnableDeliveryToLocation(props.newQrCode.enableDeliveryToLocation)
        setEnablePickup(props.newQrCode.enablePickup)
        setName(props.newQrCode.name)
        setEnableDelivery(!!props.newQrCode.enableDelivery)
        setEnableTakeaway(!!props.newQrCode.enableTakeaway)
    }, [props.newQrCode])


    return <SwippyModal
        title={"Modifica Codice QR"}
        onCancel={props.onCancel}
        visible={!!props.newQrCode}
        footer={<div>
            <SwippyButton
                type={"primary"}
                loading={props.loading}
                onClick={() => {
                    const newQRCode = new QrCode(
                        props.newQrCode?.id as string,
                        name || "",
                        enableDeliveryToLocation,
                        enablePickup,
                        props.type,
                        enableDelivery,
                        enableTakeaway
                    )


                    if (props.onSave) props.onSave(newQRCode)
                }}
            >
                Salva
            </SwippyButton>
        </div>}
    >
        <div>
            <div className={"flex flex-col"}>
                <label>Nome QR Code</label>
                <input
                    className={"border rounded border-gray-300 p-1 h-8"}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </div>
            {
                props.type === QrCodeType.IN_LOCO ? <>
                    <div className={"flex flex-col mt-2"}>
                        <SwippyCheckbox

                            checked={enablePickup}
                            onChange={e => setEnablePickup(e.target.checked)}
                        >
                            Abilita ritiro al banco
                        </SwippyCheckbox>

                    </div>
                    <div className={"flex flex-col mt-2"}>
                        <SwippyCheckbox
                            checked={enableDeliveryToLocation}
                            onChange={e => setEnableDeliveryToLocation(e.target.checked)}
                        >
                            Abilita consegna in loco
                        </SwippyCheckbox>

                    </div>

                </> : null
            }
            {
                props.type === QrCodeType.DELIVERY_AND_TAKEAWAY ? <>

                    <div className={"flex flex-col mt-2"}>
                        <SwippyCheckbox
                            checked={enableTakeaway}
                            onChange={e => setEnableTakeaway(e.target.checked)}
                        >
                            Abilita asporto
                        </SwippyCheckbox>

                    </div>

                    <div className={"flex flex-col mt-2"}>
                        <SwippyCheckbox

                            checked={enableDelivery}
                            onChange={e => setEnableDelivery(e.target.checked)}
                        >
                            Abilita consegna
                        </SwippyCheckbox>

                    </div>

                </> : null
            }
        </div>
    </SwippyModal>
}