import {gql, useQuery} from '@apollo/client';
import {UserDTO} from "../user/UserDTO";
import {User} from "../user/User";
import {Ristopub} from "../../ristopub/Ristopub";
import _ from "lodash";
import {RistopubTakeawaySlot} from "../../ristopub/RistopubTakeawaySlot";
import {TableQrCode} from "../../TableQrCodes/TableQrCode";
import {RistopubDeliverySlot} from "../../ristopub/RistopubDeliverySlot";
import {useRef} from "react";
import {AddressDataMapper} from "../../ristopub/Address/AddressDataMapper";

const GET_MYINFO_QUERY = gql`
    query getMyInfo {
        getMyInfo {
            id
            email
            
            stripeAccount {
                id
                detailsSubmitted
                email
            }
            ristopub {
                id
                slug
                enableTakeaway
                featuredImageSrc
                enableInAppPayments
                enableCashPayments
                emailForNotification
                name
                enableDelivery
                minimumDeliveryOrderValue
                minimumTakeawayOrderValue
                minimumOrderValueForFreeShipping
                address {
                    city
                    province
                    postalCode
                    country
                    address
                    geolocation {
                        latitude
                        longitude
                    }
                }
                

                takeawaySlots {
                    start {
                        time
                    }
                    end {
                        time
                    }
                    __typename
                }
                
                deliverySlots {
                    start {
                        time
                    }
                    end {
                        time
                    }
                    __typename
                }
                
                tableQrCodes {
                    id
                    tableName
                    __typename
                }
            }
            __typename
        }
    }
`;

export function useCurrentUser() {

    const {data,loading} = useQuery(GET_MYINFO_QUERY)



    const {current: addressDataMapper} = useRef(new AddressDataMapper())

    const userData: UserDTO | undefined = data ? data.getMyInfo : undefined




    const user = userData ? User.create({
        id: userData.id,
        email: userData.email,
        stripeAccount: userData.stripeAccount,
        ristopub: Ristopub.create({
            slug: userData.ristopub.slug,
            enableTakeaway: userData.ristopub.enableTakeaway,
            featuredImageSrc: userData.ristopub.featuredImageSrc,
            minimumDeliveryOrderValue: userData.ristopub.minimumDeliveryOrderValue,
            minimumTakeawayOrderValue: userData.ristopub.minimumTakeawayOrderValue,
            minimumOrderValueForFreeShipping: userData.ristopub.minimumOrderValueForFreeShipping,
            enableDelivery: userData.ristopub.enableDelivery,
            address: userData.ristopub.address ? addressDataMapper.toEntity(userData.ristopub.address) : undefined,
            enableCashPayments: userData.ristopub.enableCashPayments,
            enableInAppPayments: userData.ristopub.enableInAppPayments,
            id: userData.ristopub.id,
            name: userData.ristopub.name,
            emailForNotification: userData.ristopub.emailForNotification,

            takeawaySlots: _.map(userData.ristopub.takeawaySlots, slot => {
                return RistopubTakeawaySlot.create({
                    start: slot.start.time,
                    end: slot.end.time
                })
            }),
            deliverySlots: _.map(userData.ristopub.deliverySlots, slot => {
                return RistopubDeliverySlot.create({
                    start: slot.start.time,
                    end: slot.end.time
                })
            }),
            tableQrCodes: _.map(userData.ristopub.tableQrCodes, tqr => {
                return TableQrCode.create({
                    id: tqr.id,
                    tableName: tqr.tableName,
                    ristopubId: userData.ristopub.id
                })
            })
        }),

    }) : undefined

    return {
        user,
        loadingUser: loading
    }
}