import {gql, useMutation} from "@apollo/client";
import {Order} from "../../virtualTable/Order/Order";
import {VirtualTable} from "../../virtualTable/VirtualTable";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";
import {CreateTakeawayOrderInput} from "./CreateTakeawayOrderInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";

const CREATE_TAKEAWAY_ORDER = gql`
    mutation backofficeCreateTakeawayOrder($input: BackofficeCreateTakeawayOrderInput!) {
        backofficeCreateTakeawayOrder(input: $input) {
            id
            cartId
            
            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }
            
            virtualTable {
                id
            }
        }
    }
`

export function useBackofficeCreateTakeawayOrder() {


    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))


    const [createTakeawayOrderMutation, {
        loading
    }] = useMutation<{
        backofficeCreateTakeawayOrder: OrderDTO
    }>(CREATE_TAKEAWAY_ORDER)



    async function createTakeawayOrder(
        input: CreateTakeawayOrderInput
    ) {
       const res = await createTakeawayOrderMutation({
           variables: {
               input: {
                   dueDate: input.dueDate.toDate(),
                   customerName: input.customerName,
                   customerPhone: input.customerPhone,
                   customerNotes: input.customerNotes,
                   items: input.items.map(i => ({qty: i.qty, productId: i.productId}))
               }
           }
       })



        return res.data?.backofficeCreateTakeawayOrder ? orderDataMapper.toOrder(res.data.backofficeCreateTakeawayOrder) : undefined
    }


    return {
        createTakeawayOrder,
        loading
    }

}