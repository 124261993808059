import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import moment from "moment-timezone"
import {RistopubAvailabilityInput} from "../ristopub/RistopubAvailabilityInput";
import {AvailabilityType} from "../ristopub/useRistopubAvailability";


export function TestRouteScreen() {

    const history = useHistory()

    const [modal1opened, setmodal1opened] = useState(false)
    const [modal2opened, setmodal2opened] = useState(false)


    const [date, setDate] = useState(moment())


    return <>
        <RistopubAvailabilityInput
            type={AvailabilityType.TAKEAWAY}
            dateLabel={"Data ritiro"}
            timeLabel={"Ora ritiro"}
            />
        {/*<SwippyDateTimeInput*/}
        {/*    dateLabel={"Data"}*/}
        {/*    timeLabel={"Ora"}*/}
        {/*    onChange={(newDate) => {*/}

        {/*        setDate(newDate)*/}
        {/*    }}*/}

        {/*    date={date}*/}
        {/*    timeSlots={[*/}
        {/*        {*/}

        {/*            localFrom: Time.fromString("12:00:00"),*/}
        {/*            localTo: Time.fromString("12:10:00"),*/}
        {/*        },*/}
        {/*        {*/}

        {/*            localFrom: Time.fromString("12:10:00"),*/}
        {/*            localTo: Time.fromString("12:20:00"),*/}
        {/*        }*/}
        {/*]}/>*/}
            {/*<OpenDayConfig/>*/}
            {/*<OpenDayCard dayName={"Lunedì"} dayOfWeek={1}/>*/}
        </>
}
