import React from "react";
import {SwippyButton} from "../SwippyButton/SwippyButton";
import styles from "./SwippyInputLabelWithCta.module.scss"
import {GaProps} from "../../safeGtag/types";

type PropTypes = {
    title: string
    ctaLabel?: string
    onClickCta?: () => void
    ctaGaProps?: GaProps
}


export function SwippyInputLabelWithCta(props: PropTypes) {
    return <div className={styles.wrapper}>
        <h3 className={styles.title}>{props.title}</h3>
        {props.ctaLabel && props.onClickCta ? <SwippyButton
            {...props.ctaGaProps || {}}
            onClick={props.onClickCta} type={"link"}>
            {props.ctaLabel}
        </SwippyButton> : null}
    </div>

}