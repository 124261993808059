import {Order} from "../virtualTable/Order/Order";
import {OrderItem} from "../virtualTable/Order/OrderItem/OrderItem";
import _ from "lodash";
import {OrderItemReportItem} from "./OrderItemReportItem";


export class OrdersToOrderItemsReportList {



    toOrderItemsList(orders: Order[]): OrderItemReportItem[] {
        const orderItemsMap: Record<string, OrderItemReportItem> = {}
        orders.forEach(o => {
            o.items.forEach(i => {
                const key = i.getKey()
                orderItemsMap[key] = orderItemsMap[key] || new OrderItemReportItem(
                    i.product.name || "",
                    this.getOrderItemSubTitle(i),
                    key,
                    0,
                    0
                )

                orderItemsMap[key].increaseQty(i.qty)
                orderItemsMap[key].increaseTotal(i.total)
            })
        })

        return _.values(orderItemsMap)
    }

    private getOrderItemSubTitle(i: OrderItem): string {
        const res: string[] = []
        i.variations.forEach(v => {
            v.choices.forEach(c => {
                const choiceRes: string[] = []
                const qty = c.choiceQty ?? 1
                if (qty > 1) choiceRes.push(`x${c.choiceQty}`)
                choiceRes.push(`${c.choiceName}`)
                res.push(choiceRes.join(" "))
            })
        })

        return res.join(" - ")
    }
}