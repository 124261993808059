import React, {useState} from "react";
import {QrCode} from "./QrCode";
import {useCreateQrCodes} from "./useCreateQrCodes";
import {useUpdateQrCodes} from "./useUpdateQrCodes";
import {ListLoading} from "../components/Loading/ListLoading";
import {nanoid} from "nanoid";
import {QrCodeType} from "./QrCodeType";
import {EditQrCodeModal} from "./EditQrCodeModal";
import {SwippyTitleWithCta} from "../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import QRCode from "qrcode.react";
import {CopyIcon} from "../components/CopyIcon";
import copy from "copy-to-clipboard";
import {message} from "antd";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";
import {Ristopub} from "../ristopub/Ristopub";


type Props = {

    refetch: () => void
    loading?: boolean
    qrCodes: QrCode[]
    ristopub: Ristopub
    type: QrCodeType
}

export function QrCodesList(props: Props) {
    const [editingQrCode, setEditingQrCode] = useState<QrCode>()
    const [isNew, setIsNew] = useState(false)


    const {loading: creatingQrCodes, createQrCodes} = useCreateQrCodes()
    const {loading: updatingQrCodes, updateQrCodes} = useUpdateQrCodes()

    if (props.loading) return <ListLoading />



    async function save(qrCode: QrCode) {
        if (isNew) {
            await createQrCodes([qrCode])
        } else {
            await updateQrCodes([qrCode])
        }

        closeEditQrModal()
        await props.refetch()
    }



    function editQrCode(qrCode: QrCode) {
        setEditingQrCode(qrCode)
        setIsNew(false)
    }

    function openNewQrModal() {
        setEditingQrCode(new QrCode(
            nanoid(),
            "",
            false,
            false,
            props.type

        ))
        setIsNew(true)
    }


    function closeEditQrModal() {
        setEditingQrCode(undefined)
        setIsNew(false)
    }

    const saving = creatingQrCodes || updatingQrCodes

    return <div>
        <EditQrCodeModal
            type={props.type}
            loading={saving}
            onSave={save}
            newQrCode={editingQrCode}
            onCancel={closeEditQrModal}
        />
        <SwippyTitleWithCta title={"Codici QR"}
                            ctaLabel={"Aggiungi codice QR"}
                            onClick={openNewQrModal}
        />
        <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"}>
            {
                props.qrCodes.map(qr => {
                    return <div key={qr.id} className={"flex flex-col items-center mb-4"}>
                        <h3  className={"text-center font-bold"}>{qr.name}</h3>
                        <QRCode
                            value={qr.getQrLink(props.ristopub.id)}/>
                        <div className={"border border-gray-300 rounded p-1 mt-3 flex"}>
                            <span
                                style={{
                                    maxWidth: 150,
                                }}
                                className={"opacity-40 min-w-0 whitespace-nowrap overflow-hidden"}
                            >{qr.getQrLink(props.ristopub.id)}</span>
                            <div>
                                <CopyIcon
                                    className={"h-6 w-6 cursor-pointer text-swippy-blue"}
                                    onClick={async () => {
                                        copy(qr.getQrLink(props.ristopub.id))
                                        await message.info("Copiato negli appunti")
                                    }}
                                />
                            </div>

                        </div>
                        <div className={"flex mt-2"}>
                            <SwippyButton

                                style={{
                                    marginRight: 4
                                }}
                                onClick={() => {
                                    editQrCode(qr)
                                }} type={"link"}>
                                Modifica
                            </SwippyButton>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}