import {Category} from "../../menu/CategoriesScreen/classes/Category";
import {Product} from "../../menu/ProductsScreen/classes/Product";
import React from "react";
import styles from "./ProductPickerCategoryItem.module.scss"
import _ from "lodash";
import {ProductPickerItem} from "../ProductPickerItem/ProductPickerItem";
type PropTypes = {
    category: Category,
    onSelected?: (product: Product) => void
    products: Product[]
    qtyMap?: Record<string, number>
}

export function ProductPickerCategoryItem(props: PropTypes) {



    return <div className={styles.wrapper}>
        {/*<h3 className={styles.title}>{props.category.name}</h3>*/}
        <div className={styles.productsListWrapper}>
            {
                _.map(props.products, p => {
                    return <ProductPickerItem
                        key={p.id}
                        qty={props.qtyMap ? props.qtyMap[p.id as string] : undefined}
                        onSelected={props.onSelected}
                        product={p} />
                })
            }
        </div>
    </div>
}