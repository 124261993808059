import {gql, useQuery} from "@apollo/client";
import {VirtualTableDataMapper} from "../virtualTable/VirtualTableDataMapper";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";
import {VirtualTableDTO} from "../virtualTable/VirtualTableDTO";
import _ from "lodash";
import {useState} from "react";
import {GenericErrorHandler} from "../classes/GenericErrorHandler";

const GET_VIRTUAL_TABLES_WITH_PAID_ORDERS = gql`
    query getVirtualTablesHistorySortedByCreatedAtDesc($limit: Int!, $offset: Int!) {
        getVirtualTablesHistorySortedByCreatedAtDesc(limit: $limit, offset: $offset) {
            hasMore
            virtualTables {
                createdAt
                type
                status
                orders {
                    id
                    deliveryCost
                    friendlyOrderId
                    type
                    dueDate
                    statusChangedAt
                    deliveryDate
                    deliveryAddress {
                        address
                        city
                        province
                        country
                        postalCode
                    }
                    orderItems {
                        id
                        total
                        product {
                            id
                            price
                            __typename
                            name
                        }
                        __typename
                        qty
                        variations {
                            modifierId
                            modifierName
                            choices {
                                choiceId
                                choiceName
                                choicePrice
                                choiceQty
                            }
                        }
                    }
                    customerNotes
                    __typename
                }
                name
                id
                customerPhone {
                    phone
                    __typename
                }
                __typename
                customerName
            }
        }
        
    }
`



const virtualTableDataMapper = new VirtualTableDataMapper(
    new OrderDataMapper(
        new OrderItemDataMapper(
            new ProductDataMapper()
        )
    )
)


const PAGE_SIZE = 10


export function useVirtualTableHistory() {
    const [fetchingMore, setFetchingMore] = useState(false)

    const {data, fetchMore, loading} = useQuery(GET_VIRTUAL_TABLES_WITH_PAID_ORDERS, {
        fetchPolicy: "cache-and-network",
        variables: {
            offset: 0,
            limit: PAGE_SIZE
        },
    })

    const vtDtos: VirtualTableDTO[] = _.get(data, "getVirtualTablesHistorySortedByCreatedAtDesc.virtualTables", [])



    const hasMore = _.get(data, "getVirtualTablesHistorySortedByCreatedAtDesc.hasMore", false) as boolean
    async function fetchMoreVirtualTables(): Promise<void> {
        if (!hasMore) return

        const currentLength = vtDtos.length

        try {
            setFetchingMore(true)
            await fetchMore({

                variables: {
                    offset: currentLength
                },

                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;





                    const newRes: any = {
                        hasMore: fetchMoreResult.getVirtualTablesHistorySortedByCreatedAtDesc.hasMore,
                        virtualTables: [
                            ...prev.getVirtualTablesHistorySortedByCreatedAtDesc.virtualTables || [],
                            ...fetchMoreResult.getVirtualTablesHistorySortedByCreatedAtDesc.virtualTables || []
                        ]
                    }


                    return Object.assign({}, prev, {
                        getVirtualTablesHistorySortedByCreatedAtDesc: newRes
                    });
                }
            })





        } catch(e) {

            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            throw e
        } finally {
            setFetchingMore(false)
        }
    }

    return {
        virtualTables: _.map(vtDtos, d => virtualTableDataMapper.toVirtualTable(d)),
        fetchMoreVirtualTables,
        hasMore,
        loading,
        fetchingMore
    }
}