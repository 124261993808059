import {gql, useQuery} from "@apollo/client";
import {useMemo, useState} from "react";
import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../menu/ProductsScreen/classes/ProductDataMapper";
import {GenericErrorHandler} from "../classes/GenericErrorHandler";
import _ from "lodash";
import {OrdersAggregatedByDateDTO} from "./OrdersAggregatedByDateDTO";
import {OrderByDateDataMapper} from "./orderByDateDataMapper";

const GET_ORDERS_AGGREGATED_BY_DATE_QUERY = gql`
    query getOrdersAggregatedByDate($before: String) {
        getOrdersAggregatedByDate(before: $before) {
            hasNextPage
            orders {
                date
                orders {
                    type
                    id
                    total
                    friendlyOrderId
                    createdAt
                    deliveryCost
                    orderItems {
                        id
                        product {
                            id
                            name
                        }
                        qty
                        total
                        variations {
                            modifierId
                            modifierName
                            choices {
                                choiceId
                                choiceName
                                choiceQty
                                choicePrice
                            }
                        }
                    }
                }
            }
            nextCursor
        }
    }
`



type Response = {
    hasNextPage: boolean,
    nextCursor?: string
    orders: OrdersAggregatedByDateDTO[]
}

export function useOrdersAggregatedByDate() {
    const {data, loading, fetchMore} = useQuery<{
        getOrdersAggregatedByDate: Response
    }, {
        before?: string
    }>(GET_ORDERS_AGGREGATED_BY_DATE_QUERY)



    const [fetchingMore, setFetchingMore] = useState(false)

    const orderByDateDataMapper = useMemo(() => new OrderByDateDataMapper(
        new OrderDataMapper(
            new OrderItemDataMapper(
                new ProductDataMapper()
            ),
        )
    ), [])



    async function fetchNextPage() {
        if (!data?.getOrdersAggregatedByDate.hasNextPage) throw new Error(`Called fetchNextPage without next page`)
        try {
            setFetchingMore(true)
            await fetchMore({

                variables: {
                    before: data?.getOrdersAggregatedByDate.nextCursor
                },

                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;





                    const newRes: Response = {
                        hasNextPage: fetchMoreResult.getOrdersAggregatedByDate.hasNextPage,
                        nextCursor: fetchMoreResult.getOrdersAggregatedByDate.nextCursor,
                        orders: [
                            ...prev.getOrdersAggregatedByDate.orders || [],
                            ...fetchMoreResult.getOrdersAggregatedByDate.orders || []
                        ]
                    }


                    return Object.assign({}, prev, {
                        getOrdersAggregatedByDate: newRes
                    });
                }
            })





        } catch(e) {

            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            throw e
        } finally {
            setFetchingMore(false)
        }
    }

    return {
        hasNextPage: !!data?.getOrdersAggregatedByDate.hasNextPage,
        fetchNextPage,
        loading,
        ordersByDate: _.map(data?.getOrdersAggregatedByDate.orders, o => {
            return orderByDateDataMapper.toEntity(o)
        }),
        fetchingMore
    }

}