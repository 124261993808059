import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {TestRouteScreen} from "../testRoute/TestRouteScreen";
import {AuthGuardedRoute} from "../auth/components/AuthGuardedRoute/AuthGuardedRoute";
import {CategoriesScreen} from "../menu/CategoriesScreen/CategoriesScreen";
import {ProductsScreen} from "../menu/ProductsScreen/ProductsScreen";
import {GetTokenScreen} from "../auth/getToken/GetTokenScreen";
import {OnboardingScreen} from "../onboarding/OnboardingScreen";
import {RedirectPostAuthScreen} from "../auth/components/RedirectPostAuthScreen/RedirectPostAuthScreen";
import {SettingsScreen} from "../settings/SettingsScreen/SettingsScreen";
import {InStructureOrdersScreen} from "../orders/InStructureOrdersScreen/InStructureOrdersScreen";
import {UnauthGuardedRoute} from "../auth/components/UnauthGuardedRoute/UnauthGuardedRoute";
import {AuthScreen} from "../auth/components/AuthScreen/AuthScreen";
import React, {useEffect} from "react";
import {User} from "../auth/user/User";
import {safeGtag} from "../safeGtag/safeGtag";
import {VirtualTableToBePaidScreen} from "../VirtualTableToBePaidScreen/VirtualTableToBePaidScreen";
import {QrCodesScreen} from "../QrCodesScreen/QrCodesScreen";
import {OrderHistoryScreen} from "../VirtualTableWithPaidOrdersScreen/OrderHistoryScreen";
import {TakeawayAndDeliveryOrdersScreen} from "../orders/TakeawayAndDeliveryOrdersScreen/TakeawayAndDeliveryOrdersScreen";
import {ProductModifiersScreen} from "../ProductModifier/ProductModifiersScreen";
import {CompensationReportScreen} from "../orders/CompensationReportScreen";
import {OrderDetailScreen} from "../orders/OrderDetailScreen";

type PropTypes = {
    user?: User
}

export function SwitchContainer(props: PropTypes) {
    const user = props.user

    const history = useHistory()
    useEffect(() => {
        const unlisten = history.listen((location) => {
            safeGtag("event","page_view", {
                page_title: "Swippy",
                page_path: location.pathname,
                page_location: window.location.href
            })
        })


        return () => {
            unlisten()
        }
    }, [])
    return <Switch>
        <Route exact path={"/"}>
            <Redirect to={"/redirect-post-auth"} />
        </Route>
        <Route exact path={"/test"}>
            <TestRouteScreen/>
        </Route>
        <AuthGuardedRoute exact={true} user={user} path={"/menu"}>
            <CategoriesScreen />
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/archive"}>
            <OrderHistoryScreen />
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/menu/:categoryId"}>
            <ProductsScreen />
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/test-get-token"}>
            <GetTokenScreen />
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/onboarding"}>
            <OnboardingScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/redirect-post-auth"}>
            <RedirectPostAuthScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/settings"}>
            <SettingsScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/product-modifiers"}>
            <ProductModifiersScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute user={user} path={"/vt-to-pay"}>
            <VirtualTableToBePaidScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/orders"}>
            <InStructureOrdersScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/orders/:orderId"}>
            <OrderDetailScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/takeawayorders"}>
            <TakeawayAndDeliveryOrdersScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/orderstopay"}>
            <VirtualTableToBePaidScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/qrcodes"}>
            <QrCodesScreen/>
        </AuthGuardedRoute>
        <AuthGuardedRoute exact={true} user={user} path={"/compensation-report"}>
            <CompensationReportScreen/>
        </AuthGuardedRoute>
        <UnauthGuardedRoute user={user} path={"/auth"} ifAuthRedirectTo={"/menu"}>
            <AuthScreen />
        </UnauthGuardedRoute>
    </Switch>
}