import {useTakeawayOrdersToBeHandledCtx} from "../../TakeawayOrdersToBeHandled/useTakeawayOrdersToBeHandledCtx";
import {OrdersListPage} from "../OrdersListPage/OrdersListPage";
import React from "react";

export function TakeawayAndDeliveryOrdersScreen() {


    const {orders, loading,refetch, lastQueryExecutedAt} = useTakeawayOrdersToBeHandledCtx()

    const realLoading = loading && !orders.length

    return <OrdersListPage
        createOrderAvailableTypes={["TAKEAWAY", "DELIVERY"]}
        defaultNewOrderType={"TAKEAWAY"}
        title={"Ordini da asporto o consegna"}
        orders={orders}
        lastQueryExecutedAt={lastQueryExecutedAt}
        refetch={refetch}
        loading={realLoading}
    />

}