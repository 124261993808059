import shortid from "shortid"
import _ from "lodash";
import {createPolygonOptions} from "./CreatePolygonOptions";
import {DeliveryAreaPricePolicy, DeliveryAreaPricePolicyType} from "./DeliveryAreaDTO";


export type Vertex = {
    lat: number,
    lng: number
}



type ConstructorOptionsWithVertices = {
    vertices: Vertex[]
}

type ConstructorOptionsWithPolygon = {
    polygon: google.maps.Polygon
}

export class DeliveryArea {

    public id?: string
    public localId: string
    private readonly polygon: google.maps.Polygon

    constructor(id: string | undefined, options: ConstructorOptionsWithVertices, pricePolicy?: DeliveryAreaPricePolicy)
    constructor(id: string | undefined, options: ConstructorOptionsWithPolygon, pricePolicy?: DeliveryAreaPricePolicy)
    constructor(
        id: string | undefined,
        options: ConstructorOptionsWithVertices | ConstructorOptionsWithPolygon,
        private pricePolicy: DeliveryAreaPricePolicy  = {
            type: DeliveryAreaPricePolicyType.FLAT,
            price: 0
        }
    ) {

        this.id = id
        this.localId = this.id ? this.id : shortid.generate()
        this.polygon = "polygon" in options ? options.polygon : DeliveryArea.buildPolygonByVertices(options.vertices)
    }



    public getPricePolicyPrice() {
        switch (this.pricePolicy.type) {
            case DeliveryAreaPricePolicyType.FLAT:
                return this.pricePolicy.price
            case DeliveryAreaPricePolicyType.PER_KM:
                return this.pricePolicy.pricePerKm
        }
    }

    public setPricePolicy(policy: DeliveryAreaPricePolicy) {
        this.pricePolicy = policy
    }

    public getPricePolicy() {
        return this.pricePolicy
    }


    public drawOnMap(map: google.maps.Map) {
        this.polygon.setMap(map)
    }


    public getPolygon() {
        return this.polygon
    }

    public removeFromMap() {
        this.polygon.setMap(null)
    }

    public onClick(listener: () => void) {
        google.maps.event.clearListeners(this.polygon, "click")
        this.polygon.addListener("click", listener)
    }

    private static buildPolygonByVertices(vertices: Vertex[]) {
        return new google.maps.Polygon({
            paths: vertices,
            ...createPolygonOptions()
        })
    }


    public getVertices() {
        const vertices: Vertex[] = []
        this.polygon.getPaths().forEach((p)=> {
            p.forEach(p => {
                vertices.push({
                    lat: p.lat(),
                    lng: p.lng()
                })
            })
        })
        return vertices
    }

    public getAreaInSquareKilometers() {
        return google.maps.geometry.spherical.computeArea(this.polygon.getPath())/1e6
    }


    public onEdit(listener: () => void) {
        this.polygon.getPaths().forEach(function(path, index){
            google.maps.event.clearListeners(path, "insert_at")
            google.maps.event.clearListeners(path, "remove_at")
            google.maps.event.clearListeners(path, "set_at")
            path.addListener('insert_at', listener);
            path.addListener('remove_at', listener);
            path.addListener('set_at', listener);
        });
    }

    public onDrag(listener: () => void) {
        google.maps.event.clearListeners(this.polygon, "drag")
        this.polygon.addListener("drag", listener)
    }

    public getCenter() {
        const polygonCoords = _.map(this.getVertices(), v => {
            return new google.maps.LatLng(v.lat, v.lng)
        })
        const bounds = new google.maps.LatLngBounds();
        polygonCoords.forEach(coord => {
            bounds.extend(coord)
        })
        return bounds.getCenter()
    }
}