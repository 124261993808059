import {Moment} from "moment-timezone";
import {OrderItemInput} from "./OrderItemInput";



export class CreateTakeawayOrderInput {
    constructor(
        public readonly dueDate: Moment,
        public readonly items: OrderItemInput[],
        public readonly customerName?: string,
        public readonly customerPhone?: string,
        public readonly customerNotes?: string,
    ) {
    }
}