import React from "react";
import {OrdersListItem} from "../../../../orders/OrdersList/OrdersListItem";
import {Order} from "../../../../virtualTable/Order/Order";
import styles from "./OrdersToBeHandledBadge.module.scss";
import _ from "lodash";
import {Badge} from "antd";

type PropTypes = {
    children?: React.ReactNode
    className?: string,
    orders: OrdersListItem[]
    offset?: [React.ReactText,React.ReactText]
}

export function OrdersToBeHandledBadge(
    props: PropTypes
) {
    const classes: string[] = [styles.badge]
    if (props.className) classes.push(props.className)




    if (!props.orders.length) return <div className={classes.join(" ")}>
        {props.children || null}
        </div>

    return <Badge
        offset={props.offset || [10,0]} count={props.orders.length} className={classes.join(" ")}>
        {props.children || null}
        </Badge>
}
