import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import React from "react";


type PropTypes = {
    size?: number
}
export function Loading(props: PropTypes) {

    const antIcon = <LoadingOutlined style={{ fontSize: props.size || 55, color: "#2195d8" }} spin />;

    return <Spin indicator={antIcon} />
}