import React from "react";
import _ from "lodash";
import styles from "./BreadcrumbsComponent.module.scss"

type Path = {
    label: string
    onClick?: () => void
}

type PropTypes = {
    paths: Path[]
}

export function BreadcrumbsComponent(props: PropTypes) {
    return <div className={styles.breadcrumbWrapper}>
        {
            _.map(props.paths, (p, index) => {

                const classes: string[] = [styles.breadcrumbItem]
                if (p.onClick) classes.push(styles.clickable)
                return <React.Fragment key={index.toString()}>
                    <div className={classes.join(" ")} onClick={p.onClick}>
                        <span className={styles.label}>{p.label}</span>
                    </div>
                    {index < props.paths.length - 1 ? <span className={styles.separator}>/</span> : null}
                </React.Fragment>
            })
        }
    </div>

}