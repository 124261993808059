import {Order} from "../virtualTable/Order/Order";
import {SwippyModal} from "../components/SwippyModal/SwippyModal";
import React, {useMemo} from "react";
import {Dayjs} from "dayjs";
import {formatPrice} from "../formatPrice/formatPrice";
import _ from "lodash";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";
import {OrdersToOrderItemsReportList} from "./OrdersToOrderItemsReportList";

type Props = {
    orders: Order[]
    visible?: boolean
    date: Dayjs
    onCancel: () => void
}

export function CompensationReportModal(props: Props) {



    const mapper = useMemo(() => {
        return new OrdersToOrderItemsReportList()
    }, [])


    const reportItems = useMemo(() => {
        return mapper.toOrderItemsList(props.orders)
    }, [props.orders])


    const total = useMemo(() => _.reduce(props.orders, (sum, o) => {
        return sum + o.total
    }, 0), [props.orders])

    const totalShippingCost = useMemo(() => {
        return _.reduce(props.orders, (sum, o) => {
            return sum + (o.getShippingCost())
        }, 0)
    }, [props.orders])

    return <SwippyModal
        footer={
            <SwippyButton
                onClick={props.onCancel}
                type={"primary"}>
                Chiudi
            </SwippyButton>
        }
        onCancel={props.onCancel}
        title={props.date.format("[Corrispettivi del] DD MMM YYYY")}
        visible={props.visible}
    >
        <div className="flex flex-col">
            <div
                style={{
                    maxHeight: 400
                }}
                className="-my-2 overflow-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Prodotto
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Quantità
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Importo
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">


                            {
                                reportItems.map(i => {
                                    return <tr key={i.key}>
                                        <td className={"px-6 py-4"}>
                                            <div className={"flex flex-col"}>
                                                <span className={"font-bold"}>{i.title}</span>
                                                {i.subTitle && i.subTitle.length > 0 ? <span className={"max-w-xs block break-all"}>{i.subTitle}</span> : null}
                                            </div>
                                        </td>
                                        <td className={"px-6 py-4"}>
                                            {i.getQty()}
                                        </td>
                                        <td className={"px-6 py-4"}>
                                            {i.getTotalAmount()} €
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        {totalShippingCost > 0 ? <div className={"flex items-center mt-2 justify-end"}>
            <span className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                Totale spedizioni
            </span>
            <span className={"px-6 py-4 whitespace-nowrap"}>
                {formatPrice(totalShippingCost)} €
            </span>
        </div> : null}
        <div className={"flex items-center mt-2 justify-end"}>
            <span className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                Totale
            </span>
            <span className={"px-6 py-4 whitespace-nowrap"}>
                { formatPrice(total) } €
            </span>
        </div>
    </SwippyModal>

}
