import {RistopubTakeawaySlot} from "./RistopubTakeawaySlot";
import _ from "lodash";
import {TableQrCode} from "../TableQrCodes/TableQrCode";
import {Email} from "../auth/user/Email";
import {RistopubDeliverySlot} from "./RistopubDeliverySlot";
import {Address} from "./Address/Address";

type CreateRistopubInput = {
    id: string
    name?: string
    takeawaySlots?: RistopubTakeawaySlot[]
    deliverySlots?: RistopubTakeawaySlot[]
    tableQrCodes?: TableQrCode[]
    emailForNotification?: string
    enableInAppPayments?: boolean
    enableCashPayments?: boolean
    enableDelivery?: boolean
    enableTakeaway?: boolean
    address?: Address
    minimumOrderValueForFreeShipping?: number
    minimumDeliveryOrderValue?: number
    minimumTakeawayOrderValue?: number
    featuredImageSrc?: string
    slug?: string
}
export class Ristopub {
    private constructor(
        public id: string,
        public name?: string,
        private takeawaySlots: RistopubTakeawaySlot[] = [],
        private deliverySlots: RistopubDeliverySlot[] = [],
        public tableQrCodes: TableQrCode[] = [],
        private emailForNotification?: Email,
        public enableInAppPayments?: boolean,
        public enableDelivery?: boolean,
        public enableCashPayments?: boolean,
        public address?: Address,
        public minimumOrderValueForFreeShipping?: number,
        public minimumDeliveryOrderValue?: number,
        public minimumTakeawayOrderValue?: number,
        public readonly featuredImageSrc?: string,
        public slug?: string,
        public enableTakeaway?: boolean
    ) {
    }


    public static create({
        id,name,takeawaySlots,deliverySlots,
                             enableDelivery,
                             tableQrCodes,emailForNotification,enableInAppPayments,enableCashPayments,
        address,minimumOrderValueForFreeShipping,minimumDeliveryOrderValue,minimumTakeawayOrderValue,
        featuredImageSrc,
                             slug,enableTakeaway
                         }: CreateRistopubInput) {

        const emailForNotificationObj = emailForNotification ? new Email(emailForNotification) : undefined

        return new Ristopub(id,name,takeawaySlots,deliverySlots,
            tableQrCodes,emailForNotificationObj,enableInAppPayments,enableDelivery,
            enableCashPayments,address,minimumOrderValueForFreeShipping,
            minimumDeliveryOrderValue,minimumTakeawayOrderValue,featuredImageSrc,slug,enableTakeaway)
    }


    public getEmailForNotification(): string | undefined {
        return this.emailForNotification ? this.emailForNotification.email : undefined
    }


    public setEmailForNotification(emailForNotification?: Email): void {
        this.emailForNotification = emailForNotification
    }

    public getTakeawaySlots() {
        return this.takeawaySlots
    }

    public addTakeawaySlot(slot: RistopubTakeawaySlot) {
        this.takeawaySlots.push(slot)
    }


    public updateTakeAwaySlot(index: number, slot: RistopubTakeawaySlot) {
        this.takeawaySlots[index] = slot
    }
    public getDeliverySlots() {
        return this.deliverySlots
    }

    public addDeliverySlot(slot: RistopubDeliverySlot) {
        this.deliverySlots.push(slot)
    }


    public updateDeliverySlot(index: number, slot: RistopubDeliverySlot) {
        this.deliverySlots[index] = slot
    }


    public deleteTakeawaySlot(index: number) {
        _.remove(this.getTakeawaySlots(), (s,i) => {
            return index === i
        })
    }

    deleteDeliverySlot(index: number) {
        _.remove(this.getDeliverySlots(), (s,i) => {
            return index === i
        })
    }
}