import React, {useEffect, useRef} from "react";
import {DeliveryArea} from "./DeliveryArea/DeliveryArea";
import styles from "./MapRenderer.module.scss"
import {createPolygonOptions} from "./DeliveryArea/CreatePolygonOptions";
import _ from "lodash";
import ReactDOMServer from 'react-dom/server';


import "./MapRenderer.scss"

type PropTypes = {
    deliveryAreas: DeliveryArea[]
    onChange: (newDeliveryAreas: DeliveryArea[]) => void
    onClick?: (deliveryArea: DeliveryArea) => void
    className?: string
}




export function MapRenderer (props: PropTypes) {
    const map = useRef<google.maps.Map>()
    const debounceTimeout = useRef<NodeJS.Timeout>()
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const drawingManager = useRef<google.maps.drawing.DrawingManager>()
    const displayedInfoWindows = useRef<google.maps.InfoWindow[]>([])
    const displayedAreas = useRef<DeliveryArea[]>([])


    useEffect(() => {
        createMapIfNotExists()
        createDrawingManagerIfNotExists(map.current as google.maps.Map)
        refreshOnCreatePolygonListener()
        clearDisplayedInfoWindows()
        clearDisplayedAreas()
        drawPolygonsByDeliveryAreasOnMap(map.current as google.maps.Map)
    }, [props.deliveryAreas])




    function refreshOnCreatePolygonListener() {
        const dm = drawingManager.current as google.maps.drawing.DrawingManager
        google.maps.event.clearInstanceListeners(dm)
        dm.addListener("polygoncomplete", createNewDeliveryArea)
    }

    function createDrawingManagerIfNotExists(map: google.maps.Map) {
        if (drawingManager.current) return
        drawingManager.current = new google.maps.drawing.DrawingManager({
            //drawingMode: google.maps.drawing.OverlayType.,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.POLYGON,
                ],
            },
            polygonOptions: createPolygonOptions(),
        });
        drawingManager.current.setMap(map);
    }

    function clearDisplayedInfoWindows() {
        displayedInfoWindows.current.forEach(infoWindow => infoWindow.close())
        displayedInfoWindows.current = []
    }
    function clearDisplayedAreas() {
        displayedAreas.current.forEach(area => area.removeFromMap())
        displayedAreas.current = []
    }

    function debounceUpdateDeliveryArea() {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current)
        debounceTimeout.current = setTimeout(() => {
            props.onChange([
                ...props.deliveryAreas
            ])
        }, 200)
    }

    function removeArea(deliveryArea: DeliveryArea) {
        const newAreas = _.filter(props.deliveryAreas, delArea => {
            return delArea.id !== deliveryArea.id
        })


        props.onChange([
            ...newAreas
        ])
    }

    function drawPolygonsByDeliveryAreasOnMap(
        map: google.maps.Map
    ) {
        props.deliveryAreas.forEach((area) => {
            area.drawOnMap(map)
            displayedAreas.current.push(area)
            area.onEdit(debounceUpdateDeliveryArea)
            area.onDrag(debounceUpdateDeliveryArea)


            window.deleteAreaHandlers = window.deleteAreaHandlers || {}
            window.deleteAreaHandlers[area.localId] = () => {
                removeArea(area)
            }

            // const InfoWindowComponent = <div>
            //     <button onClick={() => {
            //         removeArea(area)
            //     }}>Rimuovi area</button>
            // </div>



            const deleteInfoWindow = new google.maps.InfoWindow({
                disableAutoPan: true,
                content: `<div style="display: flex; align-items: center; justify-content: center; width: 100%">
                        <button class="deliveryMapDeleteButton" onclick="window.deleteAreaHandlers['${area.localId}']()">Delete</button>
                    </div>`,


            });


            area.onClick(() => {

                if (props.onClick) props.onClick(area)

            })



        })
    }


    function createNewDeliveryArea(newPolygon: google.maps.Polygon) {
        const newArea = new DeliveryArea(undefined, {
            polygon: newPolygon
        })
        props.onChange([
            ...props.deliveryAreas,
            newArea
        ])

        if (props.onClick) props.onClick(newArea)


    }


    function createMapIfNotExists() {
        if (map.current) return
        map.current = new google.maps.Map(mapContainerRef.current as HTMLDivElement, {
            center: {
                lat: 44.494887,
                lng: 11.3426163
            },
            zoom: 13,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false
        });

        // Create the search box and link it to the UI element.
        const input = document.getElementById("pac-input") as HTMLInputElement;
        const searchBox = new google.maps.places.SearchBox(input);
        map.current.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.current.addListener("bounds_changed", () => {
            searchBox.setBounds(map.current!.getBounds() as google.maps.LatLngBounds);
        });


        let markers: google.maps.Marker[] = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {

            const bestMatch = _.first(searchBox.getPlaces())
            if (!bestMatch || !bestMatch.geometry) return
            map.current!.setCenter(new google.maps.LatLng(
                bestMatch.geometry.location.lat(),
                bestMatch.geometry.location.lng()
            ))
        })

    }

    return <div className={[styles.mapWrapper, props.className ? props.className : ""].join(" ")}>
        <input
            id="pac-input"
            className={["controls", styles.searchInput].join(" ")}
            type="text"
            placeholder="Ricerca un posto"
        />
        <div ref={mapContainerRef}/>
    </div>

}