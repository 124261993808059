import {EditTimeSlotModal} from "../../../../TimeSlot/EditTimeSlotModal/EditTimeSlotModal";
import {SwippyInputLabelWithCta} from "../../../../components/SwippyInputLabelWithCta/SwippyInputLabelWithCta";
import {TimeSlotsList} from "../../../../TimeSlot/TimeSlotsList/TimeSlotsList";
import React, {useState} from "react";
import {useSwippyLoggedUserCtx} from "../../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {useUpsertMyRistopub} from "../../../../ristopub/useUpsertMyRistopub";
import _ from "lodash";
import {RistopubDeliverySlot} from "../../../../ristopub/RistopubDeliverySlot";
import {RistopubTakeawaySlot} from "../../../../ristopub/RistopubTakeawaySlot";

export function DeliverySlotsInput() {

    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const [editingSlot, setEditingSlot] = useState<RistopubDeliverySlot>()
    const [currentSlotIndex, setCurrentSlotIndex] = useState<number>(-1)
    const {upsertMyRistopub} = useUpsertMyRistopub()
    if (!user) return null

    const slots = user.getRistopub().getDeliverySlots() || []


    function newSlot() {
        setEditingSlot(RistopubDeliverySlot.create({
            start: "00:00:00",
            end: "00:00:00"
        }))
    }


    function editSlot(slotIndex: number, slot: RistopubDeliverySlot) {
        if (slotIndex < 0) return
        setCurrentSlotIndex(slotIndex)
        setEditingSlot(slot)

    }


    function closeEditSlotModal() {
        setEditingSlot(undefined)
        setCurrentSlotIndex(-1)
    }


    async function deleteSlot(slot: RistopubDeliverySlot, index: number) {
        const ristopub = _.cloneDeep(user?.getRistopub())
        if (!ristopub) throw new Error("Missing ristopub")
        ristopub.deleteDeliverySlot(index)
        await upsertMyRistopub(ristopub)
    }




    async function saveDeliverySlot(slot: RistopubDeliverySlot) {
        if (!user) throw new Error("Missing user")
        const ristopub = _.cloneDeep(user.getRistopub())
        if (!ristopub) throw new Error("Missing ristopub")
        if (currentSlotIndex < 0) ristopub.addDeliverySlot(slot)
        else ristopub.updateDeliverySlot(currentSlotIndex, slot)
        await upsertMyRistopub(ristopub)
        closeEditSlotModal()
    }

    return <div>
        <EditTimeSlotModal
            index={currentSlotIndex}
            onSave={saveDeliverySlot}
            onCancel={closeEditSlotModal}
            timeSlot={editingSlot} />

        <SwippyInputLabelWithCta
            ctaGaProps={{
                gaCategory: "delivery_settings",
                gaLabel: "delivery_settings_open_add_slot",
            }}
            title={"Fasce orarie valide per ordinare"}
            ctaLabel={"Aggiungi fascia"}
            onClickCta={newSlot}/>
        <TimeSlotsList
            onDelete={deleteSlot}
            onClick={editSlot}
            ristopub={user.getRistopub()}
            timeSlots={slots}/>
    </div>
}