import {OrdersListItem} from "../OrdersListItem";
import React, {useEffect, useState} from "react";
import {OrdersListHeader} from "./OrdersListHeader/OrdersListHeader";
import styles from "./OrdersListItemRenderer.module.scss"
import {OrderItemsList} from "./OrderItemsList/OrderItemsList";
import {OrderActions} from "./OrderActions/OrderActions";
import moment from "moment-timezone"
import {OrderCustomerNotes} from "./OrderCustomerNotes/OrderCustomerNotes";
import {OrderTotal} from "./OrderTotal/OrderTotal";
import {useArchiveOrderMutation} from "../useArchiveOrderMutation";
import { OrderShippingCost } from "./OrderShippingCost";

type PropTypes = {
    order: OrdersListItem
    showAcceptedActions?: boolean
    showMinutesCounter?: boolean
    showNewTag?: boolean

    showMarkAsPaidAction?: boolean
    showMarkAsDeliveredAction?: boolean

    onEdit?: (order: OrdersListItem) => void
}

export function OrdersListItemRenderer(props: PropTypes) {



    const [now,setNow] = useState(moment())
    const {archiveOrderMutation} = useArchiveOrderMutation()
    useEffect(() => {


        const interval = setInterval(() => {
            setNow(moment())
        }, 1000 * 60)


        return () => {
            clearInterval(interval)
        }
    }, [])

    const phone = props.order.virtualTable.customerPhone
    const order = props.order.order

    async function archiveOrder() {
        await archiveOrderMutation({
            variables: {
                input: {
                    orderId: order.id,
                    virtualTableId: props.order.virtualTable.id
                }
            }
        })
    }



    return <div className={styles.wrapper}>
        <OrdersListHeader
            isInStructure={props.order.order.isInStructure()}
            onEdit={() => {
                if (props.onEdit) props.onEdit(props.order)
            }}
            createdAt={props.order.order.createdAt}
            deliveryDate={props.order.order.deliveryDate}
            deliveryAddress={props.order.order.deliveryAddress}
            tableName={props.order.virtualTable.name}
            isNew={props.showNewTag && order.createdAt && now.diff(order.createdAt, "minutes") < 2}
            showMinutesCounter={props.showMinutesCounter}
            inPlace={props.order.order.inPlace}
            statusColor={props.order.order.statusColor()}
            dueDate={props.order.order.dueDate}
            customerPhone={phone ? phone.phone : undefined}
            friendlyOrderId={props.order.order.friendlyOrderId}
            customerName={props.order.virtualTable.customerName}
            orderStatus={props.order.order.formatStatus()}
            typeBadgeLabel={order.getTypeLabel()}
            typeBadgeColor={order.getTypeColor()}
            showArchiveButton={order.isAccepted() || order.isPaidInApp()}
            onArchiveClicked={archiveOrder}
        />
        {order.qrCodeName ? <div className={"border-b p-2 border-gray-300 flex items-center justify-between"}>
            <h3 className={"mb-0 mr-2 flex-1"}>Nome codice QR</h3>
            <span className={"text-right"}>{order.qrCodeName}</span>
        </div> : null}
        <OrderItemsList items={props.order.order.items}/>
        <OrderShippingCost order={props.order.order} />
        <OrderTotal order={props.order.order}/>
        {order.customerNotes && order.customerNotes.length ? <OrderCustomerNotes customerNotes={order.customerNotes}/> : null}
        <OrderActions
            showMarkAsDeliveredAction={props.showMarkAsDeliveredAction}
            showMarkAsPaidAction={props.showMarkAsPaidAction}
            withAcceptedActions={props.showAcceptedActions}
            order={props.order}/>
    </div>
}