import {Order} from "./Order/Order";
import {PhoneDTO, VirtualTableStatus, VirtualTableType} from "./VirtualTableDTO";
import {OrderItem} from "./Order/OrderItem/OrderItem";
import _ from "lodash"
import {Moment} from "moment-timezone";

type CreateVirtualTableInput = {
    id?: string
    name: string
    customerName?: string
    customerPhone?: PhoneDTO
    orders?: Order[],
    type?: VirtualTableType,
    status: VirtualTableStatus,
    createdAt?: Moment

}

export class VirtualTable  {
    private constructor(public id: string | undefined,
                        public name: string,
                        public status: VirtualTableStatus,
                        public orders: Order[] = [],
                        public type: VirtualTableType = "TAKEAWAY",
                        public customerName?: string,
                        public customerPhone?: PhoneDTO,
                        public createdAt?: Moment
    ) {
    }



    public isOpened() {
        return this.status === "OPENED"
    }


    public isPaid() {
        return this.status === "PAID"
    }

    public static create({createdAt,id,name,customerPhone,customerName,orders,type,status}: CreateVirtualTableInput) {
        return new VirtualTable(id,name,status,orders,type,customerName,customerPhone,createdAt)
    }



    public isTakeAway() {
        return this.type === "TAKEAWAY"
    }


    public isFromTable() {
        return this.type === "ONTABLE"
    }

    public getTypeColor() {
        if (this.isTakeAway()) {
            return "#5d80d0"
        } else if (this.isFromTable()) {
            return "#27C46B"
        } else if (this.isDelivery()){
            return "#FFCD00"
        } else {
            return
        }
    }

    public getTypeLabel() {
        if (this.isTakeAway()) {
            return "Da asporto"
        } else if (this.isFromTable()) {
            return "Dal tavolo"
        } else if (this.isDelivery()){
            return "Consegna"
        } else {
            return
        }
    }



    private isTakeaway() {
        return this.type === "TAKEAWAY"
    }


    private isDelivery() {
        return this.type === "DELIVERY"
    }

    private isInStructure() {
        return this.type === "ONTABLE"
    }


    private hasTavoloInName() {
        return (this.name || "").toLowerCase().includes("tavolo")
    }

    public getDisplayName() {
        return this.isTakeAway() || this.isDelivery()
            ? this.customerName
            : (
                this.hasTavoloInName()
                    ? this.name
                    : `Tavolo ${this.name}`
            )
    }

    public getAggregatedCustomerNotes(): string | undefined {
        const cNotes = _.reduce(this.orders, (res: string[],o) => {

            if (o.customerNotes && o.customerNotes.length) res.push(o.customerNotes)

            return res
        }, []).join(" - ")

        return cNotes.length > 0 ? cNotes : undefined
    }




    public getAggregateOrderItemsByKey(): OrderItem[] {
        const items = _.reduce(this.orders, (res: Record<string, OrderItem>, o) => {

            _.forEach(o.items, i => {

                const key = i.getKey()
                const alreadyInItem = res[key]
                if (alreadyInItem) {
                    alreadyInItem.increaseQtyAndTotal(i.qty, i.total)
                } else {
                    res[key] = OrderItem.create({
                        qty: i.qty,
                        product: i.product,
                        total: i.total,
                        variations: i.variations
                    })
                }
            })

            return res
        }, {})



        return _.values(items)
    }



    public getAggregatedOrderItems(): OrderItem[] {
        const items = _.reduce(this.orders, (res: Record<string, OrderItem>, o) => {

            _.forEach(o.items, i => {

                const productId = i.product.id as string

                const alreadyInItem = res[productId]
                if (alreadyInItem) {
                    alreadyInItem.increaseQtyAndTotal(i.qty, i.total)
                } else {
                    res[productId] = OrderItem.create({
                        qty: i.qty,
                        product: i.product,
                        total: i.total
                    })
                }
            })

            return res
        }, {})



        return _.values(items)
    }


    private deliveryCost(): number {
        const firstOrder = _.first(this.orders)
        return firstOrder?.deliveryCost ?? 0
    }

    public getTotal(): number {
        return _.reduce(this.getAggregatedOrderItems(), (sum,oi) => {
            return sum + oi.total
        }, 0) + this.deliveryCost()
    }
}