import {MainLayout} from "../../../components/MainLayout/MainLayout";
import React, {useState} from "react";
import {SwippyCard} from "../../../components/SwippyCard/SwippyCard";
import "./AuthScreen.scss"
import {LoginWithGoogleButton} from "../LoginWithGoogleButton/LoginWithGoogleButton";
import {firebase} from "../../../firebase";
import {GenericErrorHandler} from "../../../classes/GenericErrorHandler";
import {safeGtag} from "../../../safeGtag/safeGtag";


function trackLoginWithGoogle() {
    safeGtag("event", "login", {
        "method": "Google"
    });
}

export function AuthScreen() {
    const [signinInWithGoogle, setSigninInWithGoogle] = useState(false)
    async function signinWithGoogle() {
        setSigninInWithGoogle(true)
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().useDeviceLanguage();
        try {
            trackLoginWithGoogle()
            await firebase.auth().signInWithPopup(provider)
            window.location.href = "/redirect-post-auth"

        } catch(e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            setSigninInWithGoogle(false)
            throw e
        }
    }

    return <MainLayout>
        <div className={"authScreenWrapper"}>
            <SwippyCard
                className={"authCard"}
                title={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <h1 className={"authScreenTitle"}>Accedi</h1>
                    </div>
                }
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <LoginWithGoogleButton loading={signinInWithGoogle} onClick={signinWithGoogle} style={{
                        marginBottom: 28
                    }}/>
                </div>
            </SwippyCard>
        </div>
    </MainLayout>
}
