import {BrowserRouter as Router} from "react-router-dom";
import React from "react";

import {FullPageLoading} from "../components/Loading/FullPageLoading";
import {SwitchContainer} from "./SwitchContainer";


import {SwippyLoggedUserCtxProvider} from "../swippyUserCtx/SwippyLoggedUserCtxProvider";
import {Sentry} from "../sentry";
import {User} from "../auth/user/User";
import {TakeawayOrdersToBeHandledCtxProvider} from "../TakeawayOrdersToBeHandled/TakeawayOrdersToBeHandledCtxProvider";
import {InStructureOrdersToBeHandledCtxProvider} from "../InStructureOrdersToBeHandled/InStructureOrdersToBeHandledCtxProvider";
import {safeGtag} from "../safeGtag/safeGtag";
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";


function setUserForSentryAndGa(user: User | undefined) {
    if (!user) {
        Sentry.setUser(null)
        safeGtag("config", process.env.REACT_APP_GA_PROP_ID as string, {
            "user_id": null
        });
    } else {

        const ristopub = user.getRistopub()
        Sentry.setUser({
            email: user.getEmail(),
            id: user.id,
            ristopubName: ristopub ? ristopub.name : undefined
        })
        safeGtag("config", process.env.REACT_APP_GA_PROP_ID as string, {
            "user_id": user.id
        });
    }
}




function RootComponentWithUser() {

    const {loggedUser:user,loadingUser} = useSwippyLoggedUserCtx()

    setUserForSentryAndGa(user)



    if (loadingUser) return <FullPageLoading/>


    return <InStructureOrdersToBeHandledCtxProvider>
            <TakeawayOrdersToBeHandledCtxProvider>

                    <Router>
                        <SwitchContainer user={user}/>
                    </Router>

            </TakeawayOrdersToBeHandledCtxProvider>
        </InStructureOrdersToBeHandledCtxProvider>



}


export function RootComponent() {
    return <SwippyLoggedUserCtxProvider>
        <RootComponentWithUser/>
    </SwippyLoggedUserCtxProvider>
}