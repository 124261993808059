import {MainLayout} from "../components/MainLayout/MainLayout";
import React from "react";
import {SwippyTabs} from "../components/SwippyTabs/SwippyTabs";
import {Tabs} from "antd"
import {TakeawayOrderQrCode} from "../components/MainLayout/SwippyMenu/ShowOrderLink/TakeawayOrderQrCode/TakeawayOrderQrCode";
import styles from "./QrCodesScreen.module.scss"
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";
import {NewTableQrCodes} from "./NewTableQrCodes";
import {NewDeliveryAndTakeawayQrCodes} from "./NewDeliveryAndTakeawayQrCodes";

export function QrCodesScreen() {

    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const ristopub = user ? user.getRistopub() : undefined
    return <MainLayout
        withHeader={true}
        withSider={true}>
        <div className={styles.wrapper}>
            <SwippyTabs>
                <Tabs.TabPane key={"takeaway"} tab={"Codice QR per asporto e consegna"}>
                    {ristopub ? <NewDeliveryAndTakeawayQrCodes ristopub={ristopub}/> : null}
                </Tabs.TabPane>
                <Tabs.TabPane key={"tables"} tab={"Codici QR dei tavoli"}>
                    {ristopub ? <NewTableQrCodes ristopub={ristopub}/> : null}
                </Tabs.TabPane>
            </SwippyTabs>
        </div>
    </MainLayout>
}