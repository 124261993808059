import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client";
import fetch from "isomorphic-fetch";
import _ from "lodash";
import {GraphqlServiceName} from "./GraphqlServiceName";
import {IApolloCredentials} from "./credentials/IApolloCredentials";
import {AnonymousCredentials} from "./credentials/AnonymousCredentials";

export class ApolloClientFactory {
    public create(credentials: IApolloCredentials | IApolloCredentials[] = new AnonymousCredentials()): ApolloClient<NormalizedCacheObject> {
        const realCredentials = Array.isArray(credentials) ? credentials : [credentials]

        let swippyFoodServerLink = createUploadLink({
            uri: process.env.REACT_APP_GRAPHQL_URL,
            fetch
        });

        _.forEach(realCredentials, credential => {
            swippyFoodServerLink = credential.injectAuth(swippyFoodServerLink)
        })

        const notificationServiceHttpLink = new HttpLink({
            uri: process.env.REACT_APP_SWIPPY_NOTIFICATION_SERVICE_GRAPHQL_BASE_URL
        })

        return new ApolloClient({
            link: ApolloLink.split(
                operation => {
                    const serviceName = operation.getContext().serviceName
                    return serviceName === GraphqlServiceName.NOTIFICATION_SERVER
                },
                notificationServiceHttpLink,
                swippyFoodServerLink
            ),
            cache: new InMemoryCache()
        });
    }
}