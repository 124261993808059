import {detect} from "detect-browser"
import {Warning} from "../Warning";
import React, {useState} from "react";


export function ChromeAdvice() {
    const browser = detect()
    const [opened, setOpened] = useState(true)
    if (browser?.name === "chrome" || browser?.name === "crios") return null

    if (!opened) return null



    return <div className={"p-2 fixed top-0 right-0 left-0 z-10"}>
        <Warning
            onClose={() => setOpened(false)}
            title={"Browser non totalmente supportato"}
            copy={<p>
                Per una massima esperienza nell'uso del backoffice di Swippy,
                ti consigliamo di utilizzare Chrome.<br/>
                Puoi scaricarlo <a className={"underline text-red-600"} href={"https://www.google.com/intl/it_it/chrome/"}>qui</a>
                { process.env.REACT_APP_SHOW_BROWSER_NAME_IN_STAGING === "true" ? <span>[staging-dev] nome browser: {browser?.name}</span> : null }
            </p>}
        />

    </div>
}