import React from "react";
import styles from "./VirtualTableOrderItem.module.scss"
import {OrderItem} from "../../../../virtualTable/Order/OrderItem/OrderItem";
import {formatPrice} from "../../../../formatPrice/formatPrice";
import {OrderItemVariationsRenderer} from "../../../../orders/OrderItemVariationsRenderer";

type PropTypes = {
    orderItem: OrderItem
}

export function VirtualTableOrderItem(props: PropTypes) {
    return <div className={"flex flex-col p-2"}>
        <div className={styles.wrapper}>
            <div>
                <p className={styles.productName}>
                    x{props.orderItem.qty} {props.orderItem.product.name}
                </p>
            </div>
            <div>
                <p className={styles.price}>
                    {formatPrice(props.orderItem.total)} €
                </p>
            </div>
        </div>
        <OrderItemVariationsRenderer variations={props.orderItem.variations} />
    </div>
}