import React from "react";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import {TakeAwaySettings} from "./TakeAwaySettings/TakeAwaySettings";
import {GeneralSettings} from "./GeneralSettings/GeneralSettings";

import {SwippyTabs} from "../../components/SwippyTabs/SwippyTabs";
import {Tabs} from "antd"
import {PaymentsSettings} from "./PaymentsSettings/PaymentsSettings";
import {useSwippyLoggedUserCtx} from "../../swippyUserCtx/useSwippyLoggedUserCtx";
import {DeliverySettingsTab} from "./DeliverySettingsTab/DeliverySettingsTab";
import {useQueryParams} from "../../useQueryParams";
import {useLocation, useHistory} from "react-router-dom"
import {OpenDaySettingsTab} from "./OpenDaySettings/OpenDaySettingsTab";
import {MailSettingsTab} from "./MailSettingsTab";
import {NotificationSettingsTab} from "./NotificationSettingsTab";
export function SettingsScreen() {


    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const ristopub = user ? user.getRistopub() : undefined



    const query = useQueryParams()

    const selectedTab = query.get("t")

    const history = useHistory()
    const location = useLocation()

    function onChangeTab(key: string) {


        history.push(`${location.pathname}?t=${key}`)

    }

    return <MainLayout
        withHeader={true}
        withSider={true}
    >
        <div style={{padding: 16}}>
            <SwippyTabs activeKey={selectedTab ? selectedTab : "generals"} onChange={onChangeTab}>
                <Tabs.TabPane key={"generals"} tab={"Generali"}>
                    {ristopub ? <GeneralSettings ristopub={ristopub} /> : null}
                </Tabs.TabPane>
                <Tabs.TabPane key={"takeaway"} tab={"Asporto"}>
                    {user ? <TakeAwaySettings user={user}/> : null}
                </Tabs.TabPane>
                <Tabs.TabPane key={"delivery"} tab={"Consegna"}>
                    <DeliverySettingsTab/>
                </Tabs.TabPane>
                <Tabs.TabPane key={"payments"} tab={"Pagamenti"}>
                    {user ? <PaymentsSettings user={user}/> : null}
                </Tabs.TabPane>
                <Tabs.TabPane key={"opendays"} tab={"Giorni di apertura"}>
                    <OpenDaySettingsTab />
                </Tabs.TabPane>
                <Tabs.TabPane key={"mail"} tab={"Personalizzazione email"}>
                    <MailSettingsTab />
                </Tabs.TabPane>
                <Tabs.TabPane key={"notification"} tab={"Notifiche"}>
                    {user ? <NotificationSettingsTab user={user} /> : null}

                </Tabs.TabPane>

            </SwippyTabs>
        </div>

    </MainLayout>

}