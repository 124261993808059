import shortid from "shortid";


type CreateTableQrCodeInput = {
    id?: string
    tableName?: string
    ristopubId: string
}

export class TableQrCode {
    private constructor(
        public id: string = shortid.generate(),
        public ristopubId: string,
        public tableName?: string,

    ) {
    }



    public static create({id,tableName,ristopubId}: CreateTableQrCodeInput) {
        return new TableQrCode(id,ristopubId,tableName)
    }

    public createTableLink() {
        return `${process.env.REACT_APP_WEB_APP_BASE_URL}/ristopub/${this.ristopubId}/table/${this.tableName}/menu`
    }
}