import {CustomerEmailType} from "./CustomerEmailType";
import {CustomerEmailTemplateVariableDTO} from "./CustomerEmailTemplateRendererDTO";


export class CustomerEmailTemplateRenderer {
    constructor(
        public readonly customerEmailType: CustomerEmailType,
        public readonly variables: CustomerEmailTemplateVariableDTO[]
    ) {
    }


    public getName() {
        switch (this.customerEmailType) {
            case CustomerEmailType.DELIVERY_ORDER_CONFIRMATION:
                return "Conferma ordine con consegna"
            case CustomerEmailType.TAKEAWAY_ORDER_CONFIRMATION:
                return "Conferma ordine da asporto"
        }
    }

    public hasVariables() {
        return this.variables.length > 0
    }

}