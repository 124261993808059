import _ from "lodash";

import React from "react";
import {Product} from "../../classes/Product";
import {ProductItem} from "./ProductItem/ProductItem";
import styles from "./ProductsListComponent.module.scss"
import {useDragAndDropHelpers} from "../../../useDragAndDropHelpers";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";

type PropTypes = {
    products: Product[]
    onProductClicked?: (product: Product) => void
    onProductClickDelete?: (product: Product) => Promise<void> | void
    onUpdatePos?: (product: Product) => void
}

export function ProductsListComponent(props: PropTypes) {

    const {items: products, updatePosOnDrag} = useDragAndDropHelpers<Product>(props.products)


    async function onDragEnd(result: DropResult, provided: ResponderProvided) {
        const product2move = updatePosOnDrag(result)
        if (!product2move) return
        if (props.onUpdatePos)
            await props.onUpdatePos(product2move)
    }

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"products"}>
            {(provided, snapshot) => (
                <div

                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.productsList}>
                    {
                        _.map(products, (p, index) => {
                            return <Draggable key={p.id} draggableId={p.id || "new"} index={index}>

                                {(provided, snapshot) => {
                                    return <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                    >

                                        <ProductItem
                                            key={p.id}
                                            onDelete={props.onProductClickDelete}
                                            onClick={props.onProductClicked}
                                            product={p}
                                        />
                                    </div>
                                }}
                            </Draggable>


                        })
                    }
                    {provided.placeholder}
                </div>

            )}

        </Droppable>

    </DragDropContext>
}

