import _ from "lodash";
import React, {useState} from "react";
import {Category} from "../../CategoriesScreen/classes/Category";
import {ProductsListComponent} from "./ProductsListComponent/ProductsListComponent";
import {Product} from "../classes/Product";
import {EditProductModal} from "../EditProductModal/EditProductModal";
import {useDeleteProduct} from "../useDeleteProduct";
import {useUpsertProduct} from "../useUpsertProduct";


type PropTypes = {
    category: Category
    products: Product[]
}

export function ProductsList(props: PropTypes) {


    const {deleteProduct} = useDeleteProduct()
    const {upsertProduct} = useUpsertProduct()
    const [editingProduct, setEditingProduct] = useState<Product>()

    function openEditProductModal(product: Product) {
        setEditingProduct(product)
    }
    function closeEditProductModal() {
        setEditingProduct(undefined)
    }


    async function updateProductPos(product: Product) {
        await upsertProduct(_.cloneDeep(product), props.category)
    }


    return <>
        <EditProductModal
            onCancel={closeEditProductModal}
            category={props.category}
            product={editingProduct}
            visible={!!editingProduct}
        />
        <ProductsListComponent
            onUpdatePos={updateProductPos}
            onProductClicked={openEditProductModal}
            onProductClickDelete={(p: Product) => {
                return deleteProduct(p, props.category)
            }}
            products={props.products}/>
    </>
}