import React, {useEffect, useState} from "react"
import {SwippyLoggedUserCtx} from "./SwippyLoggedUserCtx";
import {firebase} from "../firebase";
import {getFirebaseCurrentUser} from "../auth/user/getFirebaseUser";
import {useCurrentUser} from "../auth/hooks/useCurrentUser";
import {useSwippyLoggedUserCtx} from "./useSwippyLoggedUserCtx";
import _ from "lodash";

type PropTypes = {
    children?: React.ReactNode
}

export function SwippyLoggedUserCtxProvider(props: PropTypes) {

    const {user, loadingUser} = useCurrentUser()




    async function logout() {
        await firebase.auth().signOut()
    }

    return <SwippyLoggedUserCtx.Provider value={_.cloneDeep({
        loggedUser: user,
        logout,
        loadingUser
    })}>
        {props.children || null}
    </SwippyLoggedUserCtx.Provider>
}
