import {useFetchOrdersToBePaid} from "../../../../orders/useFetchOrdersToBePaid";
import React from "react";
import {Badge} from "antd";
import _ from "lodash";
import styles from "./OrdersToBePaidBadge.module.scss"

type PropTypes = {
    children?: React.ReactNode
    className?: string

}

export function OrdersToBePaidBadge(props: PropTypes) {

    const classes: string[] = [styles.badge]
    if (props.className) classes.push(props.className)



    const {orders} = useFetchOrdersToBePaid()

    const ordersGroupedByVt = _.groupBy(orders, o => o.virtualTable.id)


    const ordersCount = _.size(ordersGroupedByVt)


    return <Badge
        showZero={false}
        offset={[10,0]} count={ordersCount} className={classes.join(" ")}>
        {props.children || null}
    </Badge>


}