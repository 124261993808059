import {gql, useMutation} from "@apollo/client";
import {ProductModifier} from "./ProductModifier";
import {ProductModifierType} from "./ProductModifierType";
import {ProductModifierDTO} from "./ProductModifierDTO";

const CREATE_PRODUCT_MODIFIER_MUTATION = gql`
    mutation createModifier($input: CreateModifierInput!) {
        createModifier(input: $input) {
            id
        }
    }
`



type CreateModifierInput = {
    choices: {
        name: string
        price?: number
        maxQty?: number
    }[]
    description?: string
    maxSelectableChoices?: number
    name: string
    required?: boolean
    type: ProductModifierType
}

export function useCreateProductModifier() {
    const [createModifierMutation, {
        loading
    }] = useMutation<{
        createModifier: ProductModifierDTO
    }, {
        input: CreateModifierInput
    }>(CREATE_PRODUCT_MODIFIER_MUTATION)

    async function createProductModifier(modifier: ProductModifier) {
        await createModifierMutation({
            variables: {
                input: {
                    choices: modifier.choices.map(c => {
                        return {
                            name: c.name as string,
                            price: c.price,
                            maxQty: modifier.type === ProductModifierType.MULTIPLE_CHOICE_WITH_QTY ? c.maxQty : undefined
                        }
                    }),
                    description: modifier.description,
                    maxSelectableChoices: modifier.maxSelectableChoices,
                    name: modifier.name as string,
                    required: modifier.required,
                    type: modifier.type,
                }
            }
        })
    }

    return {
        createProductModifier,
        loading
    }

}