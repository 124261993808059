import React, {useEffect, useState} from "react";

type RistopubImagePreviewPropTypes = {
    src?: string
    imageFile?: File
    width?: number | string
}

export function RistopubImagePreview(props: RistopubImagePreviewPropTypes) {
    const [fileContent, setFileContent] = useState<string>()


    function loadImageFileContent(file: File) {
        const reader = new FileReader();

        reader.onload = function (e) {
            if (!e.target) return
            setFileContent(e.target.result as string)
        }

        reader.readAsDataURL(file);
    }

    useEffect(() => {
        if (props.imageFile) loadImageFileContent(props.imageFile)
        else setFileContent(undefined)
    }, [props.imageFile])


    if (!fileContent && !props.src) return null

    function openImage(src: string) {
        window.open(src, "_blank", "noopener")
    }

    return <div style={{
        width: props.width,
    }}>
        <div
            className={"bg-cover bg-no-repeat bg-center rounded w-full"}
            onClick={!fileContent ? () => openImage(props.src as string) : undefined}
            style={{

                paddingTop: "55.555555%",
                backgroundImage: `url(${fileContent as string || props.src as string})`
            }}/>
    </div>


}