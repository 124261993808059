import {safeGtag} from "../../safeGtag/safeGtag";
import React from "react";
import {GaProps} from "../../safeGtag/types";


function track( gaAction = "click", gaCategory?: string, gaLabel?: string) {
    if (!gaCategory && !gaLabel) return
    safeGtag("event",gaAction, {
        event_category: gaCategory,
        event_label: gaLabel
    })
}

type PropTypes = {
    children?: React.ReactNode
    className?: string,
    style?: React.CSSProperties
} & GaProps

export function TrackingContainer(props: PropTypes) {

    const classes: string[] = []
    if (props.className) classes.push(props.className)



    return <div
        style={props.style}
        onClick={() => {
        track(props.gaAction,props.gaCategory,props.gaLabel)
    }} className={classes.join(" ")}>
        {props.children || null}
    </div>
}
