import {Select} from "antd"
import {RefSelectProps, SelectProps} from "antd/es/select";
import React, {useEffect, useRef} from "react";
import styles from "./SwippySelect.module.scss"

type PropTypes = SelectProps<any> & {
    hasError?: boolean
}


export function SwippySelect(props: PropTypes) {

    const classes = [styles.swippySelect, "swippySelect"]

    const {hasError, ...propsWithoutHasError} = props

    if (hasError) {
        classes.push("hasError")
    }

    const selectWrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {

        const input: HTMLInputElement = selectWrapperRef.current?.querySelector("input") as HTMLInputElement
        if (input) input.autocomplete = "nope"

    }, [selectWrapperRef])



    if (props.className) classes.push(props.className)

    return <div ref={selectWrapperRef}>
        <Select  {...propsWithoutHasError} className={classes.join(" ")}>
            {props.children || null}
        </Select>
    </div>
}