import {gql, useMutation} from "@apollo/client";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";
import {CreateInStructureOrderInput} from "./CreateInStructureOrderInput";
import {OrderItemInput} from "./OrderItemInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";

const CREATE_INSTRUCTURE_ORDER = gql`

    mutation backofficeCreateInStructureOrder($input: BackofficeCreateInStructureOrderInput!) {
        backofficeCreateInStructureOrder(input: $input) {
            id
            cartId

            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }

            virtualTable {
                id
            }
        }
    }
`

export function useBackofficeCreateInStructureOrder() {

    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))


    const [createInStructureOrderMutation, {
        loading
    }] = useMutation<{
        backofficeCreateInStructureOrder: OrderDTO
    }>(CREATE_INSTRUCTURE_ORDER)


    async function createInStructureOrder(
        input: CreateInStructureOrderInput
    ) {
        const res = await createInStructureOrderMutation({
            variables: {
                input: {
                    virtualTableName: input.virtualTableName,
                    items: input.items,
                    customerNotes: input.customerNotes
                }
            }
        })


        return res.data?.backofficeCreateInStructureOrder ? orderDataMapper.toOrder(res.data.backofficeCreateInStructureOrder) : undefined
    }


    return {
        createInStructureOrder,
        loading
    }



}