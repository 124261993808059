import React from "react";
import {Button} from "antd";
import styles from "./ConnectStripeButton.module.scss"
import {useCreateStripeOnboardingLink} from "./useCreateStripeOnboardingLink";
import {GenericErrorHandler} from "../../../../classes/GenericErrorHandler";

type PropTypes = {
    className?: string
}

export function ConnectStripeButton(props: PropTypes) {

    const {createStripeOnboardingLink,creatingStripeOnboardingLink} = useCreateStripeOnboardingLink()

    const classes = [styles.connectStripeButton, "connectStripeButton"]
    if (props.className) classes.push(props.className)



    function displayErrorToUI(e: Error) {
        const handler = new GenericErrorHandler()
        handler.handleError(e)
    }

    async function createAndRedirectToStripeOnboardingLink() {
        try {
            const link = await createStripeOnboardingLink()
            if (!link) return displayErrorToUI(new Error("Something went wrong"))
            window.location.href = link
        } catch (e) {
            displayErrorToUI(e as Error)
            throw e
        }




    }

    return <Button
        onClick={createAndRedirectToStripeOnboardingLink}
        loading={creatingStripeOnboardingLink}
        className={classes.join(" ")}>
        Connetti stripe
    </Button>
}