import {SwippyCard} from "../../components/SwippyCard/SwippyCard";
import * as React from "react";
import {useState} from "react";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import styles from "./OnboardingGiveNameToRistopub.module.scss"
import {Ristopub} from "../../ristopub/Ristopub";
import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import {SwippyInput} from "../../components/SwippyInput/SwippyInput";
import {gql, useMutation} from "@apollo/client";
import _ from 'lodash'
import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import {useHistory} from "react-router-dom";

type PropTypes = {
    ristopub: Ristopub
}

const UPSERT_MY_RISTOPUB_MUTATION = gql`
    mutation upsertMyRistopub($input: UpsertMyRistopubInput!) {
        upsertMyRistopub(input: $input) {
            id
            name
        }
    }
`


export function OnboardingGiveNameToRistopub(props: PropTypes) {

    const [ristopubName, setRistopubName] = useState<string>()
    const [savingName, setSavingName] = useState(false)
    const [upsertMyRistopub] = useMutation(UPSERT_MY_RISTOPUB_MUTATION)
    const history = useHistory()
    async function saveName() {

        if (_.size(ristopubName) <= 0) return

        setSavingName(true)
        try {
            await upsertMyRistopub({
                variables: {
                    input: {
                        name: ristopubName
                    }
                }
            })
            history.replace({
                pathname: "/menu"
            })
        } catch(e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            console.error(e)
            setSavingName(false)
            throw e
        }


    }
    return <MainLayout>
        <div className={styles.rootContainer}>
            <SwippyCard  title={<h3 className={styles.title}>Come si chiama il tuo locale?</h3>}>

                <SwippyInput onPressEnter={saveName} autoFocus={true} className={styles.input} onChange={e => setRistopubName(e.target.value)} placeholder={"Il nome del tuo locale"}/>

                <div className={styles.btnWrapper}>
                    <SwippyButton
                        gaCategory={"onboarding"}
                        gaLabel={"save_ristopub_name"}
                        loading={savingName}
                        disabled={_.size(ristopubName) <= 0}
                        onClick={saveName}
                        type={"primary"}>
                        Avanti
                    </SwippyButton>
                </div>
            </SwippyCard>
        </div>
    </MainLayout>
}
