import {gql, useApolloClient} from "@apollo/client";
import {useState} from "react";

const IS_SLUG_BUSY_QUERY = gql`
    query isBusyRistopubSlug($input: IsBusyRistopubSlugInput!) {
        isBusyRistopubSlug(input: $input)
    }
`

export function useIsSlugBusy() {
    const client = useApolloClient()

    const [loading, setLoading] = useState(false)

    async function isBusySlug(slug: string): Promise<boolean> {
        setLoading(true)
        try {
            const res = await client.query<{
                isBusyRistopubSlug: boolean
            }, {
                input: {
                    slug: string
                }
            }>({
                query: IS_SLUG_BUSY_QUERY,
                variables: {
                    input: {
                        slug
                    }
                }
            })


            return res.data.isBusyRistopubSlug
        } finally {
            setLoading(false)
        }
    }


    return {
        isBusySlug,
        loading
    }
}