import * as H from 'history';
import {matchPath} from "react-router";

type RouteId = "menu"
    | "auth"
    | "productsList"
    | "settings"
    | "in-structure-orders"
    | "takeaway-orders"
    | "orders-to-be-paid"
    | "qr-codes-page"
    | "virtual-tables-archive"
    | "product-modifiers"
    | "compensation-report"

export class ActiveRouteIdFactory {
    constructor(private location: H.Location) {
    }


    private isActiveTakeawayOrdersPage() {


        return !!matchPath(this.location.pathname, {
            path: "/takeawayorders",
            exact: true
        })


    }


    private isActiveArchivePage() {
        return !!matchPath(this.location.pathname, {
            path: "/archive",
            exact: true
        })
    }
    private isActiveCategoriesListPage() {
        return !!matchPath(this.location.pathname, {
            path: "/menu",
            exact: true
        })
    }


    private isActiveProductsListPage() {
        return !!matchPath(this.location.pathname, {
            path: "/menu/:categoryId",
            exact: true
        })
    }
    private isActiveQrCodesPage() {
        return !!matchPath(this.location.pathname, {
            path: "/qrcodes",
            exact: true
        })
    }

    private isActiveSettingsPage() {
        return !!matchPath(this.location.pathname, {
            path: "/settings",
            exact: true
        })
    }
    private isActiveInStructureOrdersPage() {
        return !!matchPath(this.location.pathname, {
            path: "/orders",
            exact: true
        })
    }
    private isTakeawayOrdersToBePaidScreen() {
        return !!matchPath(this.location.pathname, {
            path: "/orderstopay",
            exact: true
        })
    }

    private isCompensationReport() {
        return !!matchPath(this.location.pathname, {
            path: "/compensation-report",
            exact: true
        })
    }


    create(): RouteId | undefined {
        if (this.isActiveCategoriesListPage()) {
            return "menu"
        } else if (this.isActiveProductsListPage()) {
            return "productsList"
        } else if (this.isActiveSettingsPage()) {
            return "settings"
        } else if (this.isActiveInStructureOrdersPage()) {
            return "in-structure-orders"
        } else if(this.isTakeawayOrdersToBePaidScreen()) {
            return "orders-to-be-paid"
        } else if (this.isActiveQrCodesPage()) {
            return "qr-codes-page"
        } else if (this.isActiveArchivePage()) {
            return "virtual-tables-archive"
        } else if (this.isActiveTakeawayOrdersPage()) {
            return "takeaway-orders"
        } else if (this.isActiveProductModifiersPage()) {
            return "product-modifiers"
        } else if (this.isCompensationReport()) {
            return "compensation-report"
        }
    }

    private isActiveProductModifiersPage(): boolean{
        return !!matchPath(this.location.pathname, {
            path: "/product-modifiers",
            exact: true
        })
    }
}