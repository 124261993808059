
import React, {useEffect, useState} from "react";

import styles from "./DeliverySettings.module.scss"
import {Col} from "antd";
import {useUpsertMyRistopubDeliveryAreas} from "../useUpsertMyRistopubDeliveryAreas";
import {User} from "../../../../auth/user/User";
import {DeliveryArea} from "../../../../delivery/DeliveryArea/DeliveryArea";
import {DeliverySlotsInput} from "../DeliverySlotsInput/DeliverySlotsInput";
import {MapRenderer} from "../../../../delivery/MapRenderer";
import {SwippyButton} from "../../../../components/SwippyButton/SwippyButton";
import {useGetMyRistopubDeliveryAreas} from "../useGetMyRistopubDeliveryAreas";
import {useGoogleMapsAPI} from "../useGoogleMapsAPI";
import {useSwippyLoggedUserCtx} from "../../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {ListLoading} from "../../../../components/Loading/ListLoading";
import {EditDeliveryArea} from "./EditDeliveryArea/EditDeliveryArea";
import _ from "lodash";
import {SwippyCheckbox} from "../../../../components/SwippyCheckbox/SwippyCheckbox";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useUpsertMyRistopub} from "../../../../ristopub/useUpsertMyRistopub";
import {SwippyInputNumber} from "../../../../components/SwippyInputNumber/SwippyInputNumber";



type PropTypes = {
    deliveryAreas: DeliveryArea[]
    user: User
}

function DeliverySettingsComponent(props: PropTypes) {



    const [enableDelivery, setEnableDelivery] = useState(props.user.getRistopub().enableDelivery)


    const [minimumOrderValue, setMinimumOrderValue] = useState(props.user.getRistopub().minimumDeliveryOrderValue)
    const [minimumFreeShippingValue, setMinimumFreeShippingValue] = useState(props.user.getRistopub().minimumOrderValueForFreeShipping)

    const [selectedDeliveryArea, setSelectedDeliveryArea] = useState<DeliveryArea>()
    const [dirty, setDirty] = useState(false)
    const [
        deliveryAreas, setDeliveryAreas
    ] = useState(props.deliveryAreas)

    useEffect(() => {
        setDeliveryAreas(props.deliveryAreas)
    }, [props.deliveryAreas])

    const {
        upsertMyRistopubDeliveryAreas,
        loading: upsertingDeliveryAreas
    } = useUpsertMyRistopubDeliveryAreas()


    const {
        upsertMyRistopub,
        upsertingMyRistopub
    } = useUpsertMyRistopub()


    const saving = upsertingDeliveryAreas || upsertingMyRistopub



    function onChangeEnableDelivery(e: CheckboxChangeEvent) {
        setEnableDelivery(e.target.checked)
        setDirty(true)
    }


    function closeEditDeliveryArea() {
        setSelectedDeliveryArea(undefined)
    }


    async function save() {

        const ristopub = _.cloneDeep(props.user.getRistopub())

        ristopub.enableDelivery = enableDelivery
        ristopub.minimumOrderValueForFreeShipping = minimumFreeShippingValue
        ristopub.minimumDeliveryOrderValue = minimumOrderValue


        await Promise.all(
            [
                upsertMyRistopub(ristopub),
                upsertMyRistopubDeliveryAreas(
                    deliveryAreas
                )
            ]
        )
        setDirty(false)
    }


    function openEditDeliveryArea(deliveryArea: DeliveryArea) {
        setSelectedDeliveryArea(deliveryArea)
    }


    function editDeliveryArea(delArea: DeliveryArea) {
        const index = _.findIndex(deliveryAreas, a => delArea.localId === a.localId)
        if (index < 0) return
        const areas = _.cloneDeep(deliveryAreas)
        areas[index] = delArea
        setDeliveryAreas(areas)
        setDirty(true)
    }
    function deleteDeliveryArea(delArea: DeliveryArea) {
        const areas = _.cloneDeep(deliveryAreas)
        _.remove(areas, a => a.localId === delArea.localId)
        setDeliveryAreas(areas)
        setDirty(true)
    }

    return <>

        <div className={"p-2"}>
            <SwippyCheckbox
                onChange={onChangeEnableDelivery}
                checked={enableDelivery}>
                Abilita consegna a domicilio
            </SwippyCheckbox>
        </div>



        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4 p-2 w-full "}>

            <div className={"flex flex-col col-span-1 "}>
                <label>
                    Ordine minimo
                </label>
                <SwippyInputNumber
                    className={"mt-2"}
                    step={0.01}
                    onChange={(price) => {
                        if (typeof price !== "string") {
                            setDirty(true)
                            setMinimumOrderValue(price)
                        }
                    }}
                    value={minimumOrderValue}
                    min={0}
                    formatter={value => `€ ${value}`}
                    parser={value => value ? value.replace(/€\s*/g, '').replace(",", ".") : 0}
                />

            </div>
            <div className={"flex flex-col col-span-1"}>
                <label>
                    Ordine minimo per consegna gratuita
                </label>
                <SwippyInputNumber
                    className={"mt-2"}
                    step={0.01}
                    onChange={(price) => {
                        if (typeof price !== "string") {
                            setDirty(true)
                            setMinimumFreeShippingValue(price)
                        }
                    }}
                    value={minimumFreeShippingValue}
                    min={0}
                    formatter={value => `€ ${value}`}
                    parser={value => value ? value.replace(/€\s*/g, '').replace(",", ".") : 0}
                />

            </div>

        </div>



        <EditDeliveryArea
            onEdit={(area) => {
                editDeliveryArea(area)
                closeEditDeliveryArea()
            }}
            onDelete={(area) => {
                deleteDeliveryArea(area)
                closeEditDeliveryArea()
            }}
            onCancel={closeEditDeliveryArea}
            deliveryArea={selectedDeliveryArea}

        />
        <Col  span={24}>
            <DeliverySlotsInput/>
        </Col>
        <Col style={{marginTop: 16, position: "relative"}} span={24}>
            <h3 className={styles.label}>Aree di consegna</h3>
            <p>
                Utilizza la mappa qui sotto per specificare le aree in cui consegni.
                Puoi utilizzare lo strumento trascina (icona a forma di mano in basso) per spostarti sulla mappa
                e ridimensionare o spostare le aree che hai già disegnato. Per creare un&apos;area invece clicca sull&apos;icona in basso a forma di
                poligono e disegnala sulla mappa.<br/>
                Il sistema non permetterà a un cliente fuori da queste aree di selezionare l&apos;opzione per la consegna a domicilio
            </p>
            <MapRenderer
                onClick={openEditDeliveryArea}
                className={styles.map}
                         deliveryAreas={deliveryAreas}
                         onChange={(newDeliveryAreas) => {
                             setDeliveryAreas(newDeliveryAreas)
                             setDirty(true)
                         }}/>
        </Col>

        <Col span={24} className={styles.saveBtnWrapper}>
            <SwippyButton
                disabled={!dirty}
                loading={saving}
                onClick={save}
                type={"primary"}>
                Salva
            </SwippyButton>
        </Col>

    </>
}


type DeliverySettingsWithDeliveryAreasProps = {
    user: User
}

export function DeliverySettingsWithDeliveryAreas(
    props: DeliverySettingsWithDeliveryAreasProps
) {






    const {
        deliveryAreas,
    } = useGetMyRistopubDeliveryAreas()




    return <DeliverySettingsComponent
        deliveryAreas={deliveryAreas}
        user={props.user}

    />

}



type DeliverySettingsProps = {
    user: User
}

export function DeliverySettings(props: DeliverySettingsProps) {
    const {loadingGoogleAPI} = useGoogleMapsAPI()

    const {loadingUser, loggedUser} = useSwippyLoggedUserCtx()

    if (loadingGoogleAPI || loadingUser) return <ListLoading/>

    if (!loggedUser) return null

    return <DeliverySettingsWithDeliveryAreas user={props.user}/>
}