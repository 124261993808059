import {gql, useMutation} from "@apollo/client";
import {DeliveryArea} from "../../../delivery/DeliveryArea/DeliveryArea";
import _ from 'lodash'
import {DeliveryAreaDTO} from "../../../delivery/DeliveryArea/DeliveryAreaDTO";
import {updateGetMyRistopubDeliveryAreasQuery} from "./useGetMyRistopubDeliveryAreas";

const UPSERT_MY_RISTOPUB_DELIVERY_AREAS_MUTATION = gql`

    mutation upsertMyRistopubDeliveryAreas($input: UpsertDeliveryAreasInput!) {
        upsertMyRistopubDeliveryAreas(input: $input) {
            id
            vertices {
                lat
                lng
            }
            deliveryPricePolicy {
                __typename
                ... on DeliveryAreaPerKmPricePolicy {
                    pricePerKm
                    type
                }
                
                ... on DeliveryAreaFlatPricePolicy {
                    price
                    type
                }
            }
            __typename
        }
    }
`

export function useUpsertMyRistopubDeliveryAreas() {
    const [upsertMyRistopubDeliveryAreasMutation, {
        loading
    }] = useMutation<{
        upsertMyRistopubDeliveryAreas: DeliveryAreaDTO[]
    }>(UPSERT_MY_RISTOPUB_DELIVERY_AREAS_MUTATION)


    async function upsertMyRistopubDeliveryAreas(
        deliveryAreas: DeliveryArea[]
    ) {
        await upsertMyRistopubDeliveryAreasMutation({
            variables: {
                input: {
                    deliveryAreas: _.map(deliveryAreas, da => {


                        const pricePolicy = da.getPricePolicy()

                        return {
                            id: da.id,
                            vertices: da.getVertices(),
                            pricePolicy: {
                                type: pricePolicy.type,
                                price: da.getPricePolicyPrice()
                            }
                        }
                    })

                }
            },
            update: (cache, res) => {
                const newDelAreas = res.data?.upsertMyRistopubDeliveryAreas
                if (!newDelAreas) return
                updateGetMyRistopubDeliveryAreasQuery(cache,newDelAreas)
            }
        })
    }


    return {
        loading,
        upsertMyRistopubDeliveryAreas
    }

}