import {gql} from "@apollo/client";

export const GET_PRODUCTS_QUERY = gql`
    query getProducts($input: GetProductsInput!) {
        getProducts(input: $input) {
            name
            id
            image {
                src
            }
            published
            price
            description
            pos
            subTitle
        }
    }
`