import {Button} from "antd";
import React, {CSSProperties} from "react";
import googleLogo from "./google-logo.svg"
import "./LoginWithGoogleButton.scss"

type PropTypes = {
    style?: CSSProperties
    onClick?: React.MouseEventHandler<HTMLElement>;
    loading?: boolean
}

export function LoginWithGoogleButton(props: PropTypes) {
    return <Button loading={props.loading} onClick={props.onClick} style={props.style} className={"loginWithGoogleButton"}>
        <img className={"googleLogo"} alt={"Google"} src={googleLogo}/>
        Accedi con Google
    </Button>
}