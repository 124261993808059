import React, {useState} from "react";
import styles from "./PayVirtualTableActions.module.scss"
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {useMarkAsPaidAllAcceptedOrdersOfVirtualTable} from "../../../useMarkAsPaidAllAcceptedOrdersOfVirtualTable";
import {GenericErrorHandler} from "../../../../../classes/GenericErrorHandler";
import {VirtualTable} from "../../../../../virtualTable/VirtualTable";


type PropTypes = {
    virtualTable: VirtualTable
}

export function PayVirtualTableActions(props: PropTypes) {

    const [paying, setPaying] = useState(false)
    const {markAsPaidAllAcceptedOrderOfVirtualTable} = useMarkAsPaidAllAcceptedOrdersOfVirtualTable()
    async function markAsPaid() {
        setPaying(true)

        try {
            await markAsPaidAllAcceptedOrderOfVirtualTable({
                variables: {
                    input: {
                        virtualTableId: props.virtualTable.id
                    }
                }
            })
        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            throw e
        } finally {
            setPaying(false)
        }
    }

    return <div className={styles.wrapper}>
        <SwippyButton
            gaCategory={"virtual_table_management"}
            gaLabel={"virtual_table_management_pay_action"}

            onClick={markAsPaid} loading={paying} type={"link"}>
            Contrassegna come pagato
        </SwippyButton>
    </div>
}