import {VirtualTable} from "../../virtualTable/VirtualTable";
import React from "react";
import _ from "lodash";
import {VirtualTableListItem} from "../../VirtualTableToBePaidScreen/VirtualTableToBePaidList/VirtualTableListItem/VirtualTableListItem";

type PropTypes = {
    virtualTables: VirtualTable[]
}

export function VirtualTableWithPaidOrdersList(props: PropTypes) {


    return <div>
        {
            _.map(props.virtualTables, vt => {
                return <VirtualTableListItem
                    showCreatedAt={true}
                    key={vt.id} virtualTable={vt}
                    showActions={false}
                />
            })
        }
    </div>
}