import {RistopubTakeawaySlot} from "../../ristopub/RistopubTakeawaySlot";
import React from "react";
import styles from "./TimeSlotsList.module.scss"
import _ from "lodash";
import {TimeSlotItem} from "./TimeSlotItem/TimeSlotItem";
import {useUpsertMyRistopub} from "../../ristopub/useUpsertMyRistopub";
import {Ristopub} from "../../ristopub/Ristopub";
import {ITimeSlot} from "../EditTimeSlotModal/ITimeSlot";

type PropTypes = {
    timeSlots: ITimeSlot[]
    ristopub: Ristopub
    onClick?: (slotIndex: number, slot: ITimeSlot) => Promise<void> | void
    onDelete?: (slot: ITimeSlot, index: number) => void
}

export function TimeSlotsList (props: PropTypes) {

    const {upsertMyRistopub} = useUpsertMyRistopub()


    // async function deleteSlot(slot: RistopubTakeawaySlot, index: number) {
    //     const ristopub = _.cloneDeep(props.ristopub)
    //     ristopub.deleteTakeawaySlot(index)
    //     await upsertMyRistopub(ristopub)
    // }

    return <div className={styles.wrapper}>
        {_.map(props.timeSlots, (s, slotIndex) => {
            return <TimeSlotItem

                onClick={props.onClick ? async (slot) => {
                    if (props.onClick) await props.onClick(slotIndex, slot)
                } : undefined}

                key={slotIndex} onDelete={async (slot) => {
                    if (props.onDelete) await props.onDelete(slot, slotIndex)
                }} takeAwaySlot={s}/>
        })}
    </div>

}