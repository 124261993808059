import {Moment} from "moment-timezone";
import {gql, useQuery} from "@apollo/client";
import {useCurrentUser} from "../auth/hooks/useCurrentUser";
import {RistopubAvailabilityDTO} from "./RistopubAvailabilityDTO";
import {useRef} from "react";
import {RistopubAvailabilityDataMapper} from "./RistopubAvailabilityDataMapper";
import _ from "lodash";


export enum AvailabilityType {
    DELIVERY = "DELIVERY",
    TAKEAWAY = "TAKEAWAY"
}


const GET_AVAILABILITY = gql`

    query getRistopubAvailability($input: GetRistopubAvailabilityInput!) {
        getRistopubAvailability(input: $input) {
            availability {
                start {
                    time
                }
                end {
                    time
                }
            }
        }
    }
`

export function useRistopubAvailability(
    type: AvailabilityType,
    date?: Moment,
) {

    const {user} = useCurrentUser()

    const ristopub = user?.getRistopub()


    const {current: dataMapper} = useRef(new RistopubAvailabilityDataMapper())

    const {data, loading} = useQuery<{
        getRistopubAvailability: {
            availability: RistopubAvailabilityDTO[]
        }
    }>(GET_AVAILABILITY, {
        fetchPolicy: "network-only",
        skip: !date || !ristopub || !ristopub.id,
        variables: {
            input: {
                date: date?.toDate(),
                ristopubId: ristopub?.id,
                type: type
            }
        }
    })


    return {
        availabilities:_.sortBy(_.map(data?.getRistopubAvailability.availability, av => {
            return dataMapper.toEntity(av, date as Moment)
        }), av => {
            return av.start.getMoment(date).valueOf()
        }),
        loading
    }
}