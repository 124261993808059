import {gql, useMutation} from "@apollo/client";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";
import {UpdateInStructureOrderInput} from "./UpdateInStructureOrderInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";

const UPDATE_INSTRUCTURE_ORDER = gql`
    mutation backofficeUpdateInStructureOrder($input: BackofficeUpdateInStructureOrderInput!) {
        backofficeUpdateInStructureOrder(input: $input) {
            id
            cartId

            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }

            virtualTable {
                id
            }
        }
    }
`

export function useBackofficeUpdateInStructureOrder() {

    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))


    const [updateInStructureOrderMutation, {
        loading
    }] = useMutation<{
        backofficeUpdateInStructureOrder: OrderDTO
    }>(UPDATE_INSTRUCTURE_ORDER)


    async function updateInStructureOrder(
        input: UpdateInStructureOrderInput
    ) {
        const res = await updateInStructureOrderMutation({
            variables: {
                input: {
                    orderId: input.orderId,
                    customerNotes: input.customerNotes,
                    items: input.items?.map(i => ({qty: i.qty, productId: i.productId})),
                }
            }
        })

        return res.data?.backofficeUpdateInStructureOrder ? orderDataMapper.toOrder(res.data.backofficeUpdateInStructureOrder)  : undefined
    }


    return {
        updateInStructureOrder,
        loading
    }
}