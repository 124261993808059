import styles from "./CategoriesListComponent.module.scss"
import {Category} from "../../classes/Category";
import React from "react";
import _ from "lodash";
import {CategoryItem} from "./CategoryItem/CategoryItem";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {useDragAndDropHelpers} from "../../../useDragAndDropHelpers";

type PropTypes = {
    categories: Category[]
    onCategoryClickDelete?: (category: Category) => void | Promise<void>
    onEdit?: (category: Category) => void
    onUpdatePos?: (category: Category) => void
}

export function CategoriesListComponent(props: PropTypes) {


    const {
        items: categories,
        updatePosOnDrag
    } = useDragAndDropHelpers<Category>(props.categories)

    async function onDragEnd(result: DropResult, provided: ResponderProvided) {
        const category2move = updatePosOnDrag(result)
        if (!category2move) return
        if (props.onUpdatePos)
            await props.onUpdatePos(category2move)
    }

    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"categories"}>
                {(provided, snapshot) => {
                    return <div
                        className={styles.categoriesList}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {
                            _.map(categories, (c,index) => {
                                return <Draggable key={c.id} draggableId={c.id || "new"} index={index}>
                                    {(provided, snapshot) => (
                                        <div

                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <CategoryItem
                                                onEdit={props.onEdit}
                                                onDelete={props.onCategoryClickDelete}
                                                key={c.id}
                                                category={c}/>
                                        </div>
                                    )}

                                </Draggable>
                            })
                        }
                        {provided.placeholder}
                    </div>
                }}
            </Droppable>
        </DragDropContext>
    </>


}