
import {useEffect} from "react";
import {firebase} from "../firebase";
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";


type FirebaseCallback = (a: firebase.database.DataSnapshot, b?: string | null) => void | Promise<void>

type RistopubUpdateListenerOptions = {
    skip?:boolean
}

export function useRistopubUpdateListener(handler: FirebaseCallback, options: RistopubUpdateListenerOptions = {}) {
    const {loggedUser: user} = useSwippyLoggedUserCtx()


    const ristopub = user ? user.getRistopub() : undefined
    const ristopubId = ristopub ? ristopub.id : undefined



    useEffect(() => {
        if (options.skip) return
        if (!ristopubId) return

        const db = firebase.database()
        const ref = db.ref(`ristopubs/${ristopubId}/lastUpdatedDate`)

        ref.on("value", handler)

        return () => {
            ref.off("value",handler)
        }
    }, [ristopubId, options.skip])
}