import {Tabs} from "antd"
import styles from "./SwippyTabs.module.scss"
import React, {PropsWithChildren} from "react";
import {TabsProps} from "antd/lib/tabs";

type PropTypes = PropsWithChildren<TabsProps>

export function SwippyTabs(props: PropTypes) {
    const classes = [styles.swippyTabs, "swippyTabs"]
    if (props.className) classes.push(props.className)

    return <Tabs {...props} className={classes.join(" ")}>
        {props.children || null}
    </Tabs>
}