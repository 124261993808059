import React from "react";
import styles from "./OrdersListHeader.module.scss"
import {Moment} from "moment-timezone";
import {TakeawayOrdersMinutesCounter} from "./OrdersMinutesCounter/TakeawayOrdersMinutesCounter";
import {Tag} from "antd";
import {OrderActionsDropdown} from "./OrderActionsDropdown/OrderActionsDropdown";
import {Address} from "../../../../ristopub/Address/Address";

type PropTypes = {
    createdAt?: Moment
    customerName?: string
    tableName?: string
    customerPhone?: string
    orderStatus: string
    statusColor?: string
    dueDate?: Moment
    deliveryDate?: Moment
    deliveryAddress?: Address
    inPlace?: boolean
    showMinutesCounter?: boolean
    isNew?: boolean
    typeBadgeLabel?: string
    typeBadgeColor?: string
    showArchiveButton?: boolean
    onArchiveClicked?: () => Promise<void>
    onEdit?: () => void
    friendlyOrderId?: string
    isInStructure?: boolean
}

export function OrdersListHeader(props: PropTypes) {



    function getHeaderTitle() {
        if (props.isInStructure) {
            const res: string[] = [`${props.tableName}`]
            if (props.friendlyOrderId) {
                res.push(`Ordine ${props.friendlyOrderId}`)
            }
            return res.join(" - ")
        }
        else if (props.friendlyOrderId) return `Ordine ${props.friendlyOrderId}`
        else return props.customerName

    }

    return <div className={styles.wrapper}>

        <div className={["flex flex-col", styles.headerMainWrapper].join(" ")}>


            <div className={styles.titleWrapper}>
                <h2 className={styles.title}>{props.isNew ? <Tag className={styles.newTag}>
                    Nuovo
                </Tag> : null}{getHeaderTitle()}</h2>

                <div className={styles.rightWrapper}>
                    {props.typeBadgeLabel ? <Tag className={
                        styles.typeBadge
                    }
                                                 style={{
                                                     backgroundColor: props.typeBadgeColor
                                                 }}
                    >{props.typeBadgeLabel}</Tag> : null}
                    <OrderActionsDropdown
                        onEdit={props.onEdit}
                        onArchive={props.onArchiveClicked}
                        showArchiveButton={props.showArchiveButton}/>
                </div>
            </div>

            {props.createdAt ? <p className={styles.dueDate}>Ricevuto il <span className={styles.date}>{props.createdAt.format("DD MMMM YYYY [alle] HH:mm")}</span></p> : null}
        </div>


        <div className={styles.bottomHeaderWrapper}>

            {props.dueDate ? <p className={styles.dueDate}>Data ritiro: <span className={styles.date}>{props.dueDate.format("DD MMMM YYYY HH:mm")}</span> {props.showMinutesCounter ? <TakeawayOrdersMinutesCounter dueDate={props.dueDate}/> : null}</p> : null}

            {props.deliveryDate ? <p className={styles.dueDate}>Data consegna: <span className={styles.date}>{props.deliveryDate.format("DD MMMM YYYY HH:mm")}</span> {props.showMinutesCounter ? <TakeawayOrdersMinutesCounter dueDate={props.deliveryDate}/> : null}</p> : null}

            {props.deliveryAddress ? <p className={styles.addressRow}>Indirizzo di consegna: <span className={styles.address}>{props.deliveryAddress.toString()}</span></p> : null}
            <p style={{color: props.statusColor}} className={styles.status}>{props.orderStatus}</p>
            {props.customerPhone ? <p className={styles.phone}>Telefono del cliente: {props.customerPhone}</p> : null}
        </div>

    </div>



}