import {Order} from "../../../../virtualTable/Order/Order";
import React from "react";
import styles from "./OrderTotal.module.scss"
import {formatPrice} from "../../../../formatPrice/formatPrice";

type PropTypes = {
    order: Order
}

export function OrderTotal(props: PropTypes) {
    return <div className={styles.wrapper}>
        <p className={styles.total}>
            <span>Totale</span>
            <span>{formatPrice(props.order.total)} €</span>
        </p>
    </div>

}