import {useOrdersActions} from "../useOrdersActions";
import React, {useState} from "react";
import {OrderActionsWrapper} from "../OrderActionsWrapper/OrderActionsWrapper";
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";


type PropTypes = {
    virtualTableId?: string
    orderId?: string
}

export function ProposedDateChangeOrderActions(props: PropTypes) {
    const {cancelOrderMutation} = useOrdersActions()
    const [cancelling, setCancelling] = useState(false)






    async function cancelOrder() {
        setCancelling(true)
        try {
            await cancelOrderMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setCancelling(false)
        }
    }
    if (!props.virtualTableId || !props.orderId) return null
    return <OrderActionsWrapper>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
            <SwippyButton

                gaCategory={"order_management"}
                gaLabel={"order_management_cancel_action"}

                onClick={cancelOrder} loading={cancelling} type={"link"}>
                Cancella ordine
            </SwippyButton>
        </div>
    </OrderActionsWrapper>
}