import {OrdersListItemRenderer} from "./OrdersList/OrdersListItemRenderer/OrdersListItemRenderer";
import React from "react";
import {useOrderById} from "./useOrderById";
import {MainLayout} from "../components/MainLayout/MainLayout";
import {ListLoading} from "../components/Loading/ListLoading";
import {Order} from "../virtualTable/Order/Order";
import {VirtualTable} from "../virtualTable/VirtualTable";
import {useOrderId} from "./useOrderId";

export function OrderDetailScreen() {


    const {orderId} = useOrderId()

    const {order, loading} = useOrderById(orderId)


    if (loading) return <MainLayout
    withSider={true}
    withHeader={true}
    >
        <ListLoading />
    </MainLayout>


    if (!order) return <MainLayout withSider={true}
                                   withHeader={true} /> //TODO mostrare errore

    return <MainLayout withSider={true}
                       withHeader={true}>
       <div className={"p-2"}>
           <OrdersListItemRenderer order={
               {
                   order: order as Order,
                   virtualTable: (order as Order).virtualTable as VirtualTable,
               }
           } />
       </div>

    </MainLayout>
}