import {EmailConfigDTO} from "./RistopubEmailConfigDTO";
import {EmailConfig} from "./EmailConfig";

export class RistopubEmailConfig {
    constructor(
        public readonly mailConfig: EmailConfig[],
        public readonly ristopubId: string,
        public readonly logoSrc?: string
    ) {
    }
}