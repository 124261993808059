import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {Col, Form, Row, Select} from "antd";
import moment, {Moment} from "moment-timezone";
import {SwippyModal} from "../../../../../components/SwippyModal/SwippyModal";
import React, {useEffect, useState} from "react";
import {Ristopub} from "../../../../../ristopub/Ristopub";
import {useOrdersActions} from "../useOrdersActions";
import {AvailabilityType} from "../../../../../ristopub/useRistopubAvailability";
import {RistopubAvailabilityInput} from "../../../../../ristopub/RistopubAvailabilityInput";

const {Option} = Select


type PropTypes = {
    onCancel?: () => void
    visible?: boolean
    ristopub: Ristopub
    orderId: string
    virtualTableId: string
    type: AvailabilityType
    initialDate?: Moment
}

export function ProposeDateChangeModal(props: PropTypes) {
    const {proposeDateChangeMutation} = useOrdersActions()
    const [proposing, setProposing] = useState(false)

    const [date, setDate] = useState<Moment>(props.initialDate || moment())

    useEffect(() => {
        setDate(props.initialDate || moment())
    }, [props.visible])

    // const availableSlots = _.map(props.availability, a => {
    //         return <Option key={a.start.formatTime()} value={a.start.getMoment().toISOString()}>{a.start.formatTime()} - {a.end.formatTime()}</Option>
    //     })


    async function proposeDate() {
        if (!date) return

        setProposing(true)


        try {
            await proposeDateChangeMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId,
                        proposedDateChange: moment(date).toISOString()
                    }
                }
            })
        } finally {
            setProposing(false)
        }

    }

    return <SwippyModal
        destroyOnClose={true}
        onCancel={props.onCancel}
        footer={<div>
            <SwippyButton
                disabled={!date} loading={proposing} onClick={proposeDate} type={"primary"}>
                Invia proposta
            </SwippyButton>
        </div>}
        title={"Seleziona orario"}
        visible={props.visible}>
        <Form preserve={false} layout={"vertical"}>
            <Row gutter={16}>
                <Col span={24}>
                    {date ? <p>
                        Stai proponendo il ritiro alle {moment(date).format("HH:mm [del] DD MMMM YYYY")}
                    </p>: <p style={{
                    }}>Nessun orario selezionato</p>}
                </Col>
                <Col span={24}>

                    <RistopubAvailabilityInput
                        value={date}
                        onChange={newDate => setDate(newDate)}
                        type={props.type}
                        dateLabel={"Data"}
                        timeLabel={"Orario"}

                    />
                </Col>
            </Row>
        </Form>
    </SwippyModal>
}