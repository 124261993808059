import {CustomerEmailType} from "./CustomerEmailType";
import {convertFromRaw} from "draft-js";

export class EmailConfig {
    constructor(
        private readonly wysiwygJsonState: string,
        public readonly type: CustomerEmailType
    ) {
    }


    private safelyParseState() {
        try {
            return JSON.parse(this.wysiwygJsonState)
        } catch (e) {
            return undefined
        }
    }

    public getWysiwygContentState() {
        const state = this.safelyParseState()
        return state ? convertFromRaw(state) : undefined
    }
}