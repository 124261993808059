import React, {useState} from "react";
import {OpenDayCard} from "./OpenDayCard";
import dayjs from "dayjs";
import {TimeRange} from "./TimeRange";
import _ from "lodash";
import {OpenDayItem} from "./OpenDayItem";
import {useUpsertOpenDays} from "./useUpsertOpenDays";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import {useOpenDays} from "./useOpenDays";
import {ListLoading} from "../../../components/Loading/ListLoading";
import {Time} from "../../../ristopub/Time";
import {nanoid} from "nanoid";



type OpenDayConfigWithOpenDaysPropTypes = {
    initialOpenDays: Record<number, OpenDayItem>
}


function OpenDayConfigWithOpenDays(props: OpenDayConfigWithOpenDaysPropTypes) {
    const openDays: React.ReactNode[] = []

    const [openDayStatus, setOpenDayStatus] = useState<Record<number, OpenDayItem>>(props.initialOpenDays)


    const {upsertOpenDays, loading} = useUpsertOpenDays()


    function getOrCreateDayOfWeekOpenDayItem(dayOfWeek: number): OpenDayItem {
        if (openDayStatus[dayOfWeek]) return _.cloneDeep(openDayStatus[dayOfWeek])
        return {
            dayOfWeek,
            closeAllDay: false,
            timeSlots: []
        }
    }


    function onChangeAllDayClosed(dayOfWeek: number, allDayClosed: boolean) {
        const item = getOrCreateDayOfWeekOpenDayItem(dayOfWeek)
        item.closeAllDay = allDayClosed
        const newOpenDayStatus = _.cloneDeep(openDayStatus)
        newOpenDayStatus[dayOfWeek] = item
        setOpenDayStatus(newOpenDayStatus)
    }

    function onChangeDayRanges(dayOfWeek: number, ranges: TimeRange[]) {
        const item = getOrCreateDayOfWeekOpenDayItem(dayOfWeek)
        const newOpenDayStatus = _.cloneDeep(openDayStatus)
        item.timeSlots = ranges.map(r => {
            return {
                localFrom: r.start.toString(),
                localTo: r.end.toString(),
                timezone: "Europe/Rome"
            }
        })
        newOpenDayStatus[dayOfWeek] = item
        setOpenDayStatus(newOpenDayStatus)

    }


    async function save() {
        await upsertOpenDays(_.values(openDayStatus))
    }

    for (let i = 1; i <= 7; i++) {

        const day = i%7

        const currentOpenDay = openDayStatus[day]

        openDays.push(<div className={"w-full sm:w-1/2 flex flex-col p-2"}>
            <div className={"flex-1"}>
                <OpenDayCard
                    onChangeAllDayClosed={(v) => {
                        onChangeAllDayClosed(day, v)
                    }}
                    initialRanges={currentOpenDay ? currentOpenDay.timeSlots.map(ts => {
                        return {
                            start: Time.fromLocalTime(ts.localFrom, ts.timezone),
                            end: Time.fromLocalTime(ts.localTo, ts.timezone),
                            id: nanoid()
                        }
                    }) : []}
                    initialAllDayClosed={currentOpenDay ? currentOpenDay.closeAllDay : false}
                    onChangeRanges={(ranges) => {
                        onChangeDayRanges(day, ranges)
                    }}
                    key={day}
                    dayOfWeek={day}
                    dayName={_.capitalize(dayjs().day(day).format("dddd"))}
                />
            </div>
        </div>)
    }



    return <div>
        <div className={"flex flex-wrap w-full -mx-2"}>
            {openDays}
        </div>
        <div className={"mt-2 flex justify-end"}>
            <SwippyButton loading={loading} onClick={save} type={"primary"}>
                Salva
            </SwippyButton>
        </div>
    </div>

}


export function OpenDayConfig() {

    const {openDays, loading} = useOpenDays()


    if (loading) return <div className={"flex justify-center items-center"} style={{
        minHeight: 200
    }}>

        <ListLoading />
    </div>


    return <OpenDayConfigWithOpenDays initialOpenDays={_.keyBy(openDays, od => od.dayOfWeek)} />

}