import {Product} from "../../../menu/ProductsScreen/classes/Product";
import shortid from "shortid";
import {VariationGroupedByModifier} from "./OrderItemDTO";
import _ from "lodash";

type CreateOrderItemInput = {
    id?: string
    qty: number
    product: Product
    total?: number
    variations?: VariationGroupedByModifier[]
}


export class OrderItem {

    private constructor(
        public id: string = shortid.generate(),

        public qty: number,
        public product: Product,
        public total: number,
        public readonly variations: VariationGroupedByModifier[] = []
    ) {
    }


    public getProductSubTitle() {
        return this.product.subTitle
    }


    public getProductName() {
        return this.product.name
    }

    public getProductDescription() {
        return this.product.description
    }


    public increaseQtyAndTotal(qty: number = 1, total: number = 0) {
        this.qty = this.qty + qty
        this.total = this.total + total
    }


    public decreaseQty(amount: number = 1) {
        this.qty = this.qty - amount
        this.total = this.total - (amount * this.product.price)
    }
    public increaseQty(amount: number = 1) {
        this.qty = this.qty + amount
        this.total = this.total + (amount * this.product.price)
    }


    public static create({id,qty,product, total, variations}: CreateOrderItemInput) {
        return new OrderItem(id,qty,product, total || 0, variations)
    }


    private getVariationsKey(): string {
        const sortedVariations = _.sortBy(this.variations, v => v.modifierId)

        return _.reduce(sortedVariations, (res: string[], v) => {
            const sortedChoices = _.sortBy(v.choices, c => c.choiceId)
            const r: string[] = [`modifierId:${v.modifierId}`]
            _.forEach(sortedChoices, (c, index) => {
                r.push(`choice${index}:${c.choiceId}`)
            })
            res.push(r.join("-"))
            return res
        }, []).join("-")

    }

    public getKey(): string {
        const res: string[] = []
        res.push(`productId:${this.product.id}`)
        const vKeys = this.getVariationsKey()
        if (vKeys.length > 0) res.push(vKeys)
        return res.join("-")
    }
}