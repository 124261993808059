import {OrderActionsWrapper} from "../OrderActionsWrapper/OrderActionsWrapper";
import React, {useState} from "react";
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import {useOrdersActions} from "../useOrdersActions";
import {Order} from "../../../../../virtualTable/Order/Order";


type PropTypes = {
    virtualTableId?: string
    orderId?: string
    showMarkAsPaidAction?: boolean
    showMarkAsDeliveredAction?: boolean
    order: Order
}

export function AcceptedOrderActions(props: PropTypes) {


    const {payOrderMutation,markOrderAsDeliveredMutation} = useOrdersActions()
    const [paying, setPaying] = useState(false)
    const [markingAsDelivered, setMarkingAsDelivered] = useState(false)



    function getMarkAsDeliveredLabel() {
        if (props.order.selfPickup) return "Pronto per il ritiro"
        else return "Contrassegna come consegnato"
    }




    async function markAsDelivered() {
        setMarkingAsDelivered(true)
        try {
            await markOrderAsDeliveredMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setMarkingAsDelivered(false)
        }
    }

    async function markAsPaid() {
        setPaying(true)
        try {
            await payOrderMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setPaying(false)
        }
    }
    if (!props.virtualTableId || !props.orderId) return null
    return <OrderActionsWrapper>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
            {props.showMarkAsPaidAction ? <SwippyButton

                gaCategory={"order_management"}
                gaLabel={"order_management_pay_action"}

                onClick={markAsPaid} loading={paying} type={"link"}>
                Contrassegna come pagato
            </SwippyButton> : null}
            {props.showMarkAsDeliveredAction ? <SwippyButton

                gaCategory={"order_management"}
                gaLabel={"order_management_mark_as_delivered_action"}

                onClick={markAsDelivered}
                loading={markingAsDelivered}
                type={"link"}>
                {getMarkAsDeliveredLabel()}
            </SwippyButton> : null}
        </div>
    </OrderActionsWrapper>
}