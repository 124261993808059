import React from "react";
import {RistopubTakeawaySlot} from "../../../ristopub/RistopubTakeawaySlot";
import {TimeSlotItemComponent} from "./TimeSlotItemComponent/TimeSlotItemComponent";
import {ITimeSlot} from "../../EditTimeSlotModal/ITimeSlot";


type PropTypes = {
    takeAwaySlot: ITimeSlot
    onDelete?: (slot: ITimeSlot) => void | Promise<void>
    onClick?: (slot: ITimeSlot) => void | Promise<void>
}

export function TimeSlotItem(props: PropTypes) {
    return <div>
        <TimeSlotItemComponent
            onClick={props.onClick ? async () => {
                if (props.onClick) await props.onClick(props.takeAwaySlot)
            } : undefined}
            onDelete={props.onDelete ? async () => {
                if (props.onDelete) await props.onDelete(props.takeAwaySlot)
            } : undefined}
            start={props.takeAwaySlot.start.formatTime()} end={props.takeAwaySlot.end.formatTime()}/>
    </div>
}