import {gql, useQuery} from "@apollo/client";
import {NotificationConfigDTO} from "../../ristopub/NotificationConfigDTO";
import {GraphqlServiceName} from "../../apolloClient/GraphqlServiceName";

const GET_NOTIFICATION_CONFIG_QUERY = gql`
    query getRistopubNotificationConfig($ristopubId: String!) {
        getRistopubNotificationConfig(ristopubId: $ristopubId) {
            ristopubId
            slackPushUrl
        }
    }
`
export function useNotificationConfig(ristopubId?: string) {
    const {data, loading} = useQuery<{
        getRistopubNotificationConfig: NotificationConfigDTO
    }, {
        ristopubId: string
    }>(GET_NOTIFICATION_CONFIG_QUERY, {
        skip: !ristopubId,
        variables: {
            ristopubId: ristopubId as string
        },
        context: {
            serviceName: GraphqlServiceName.NOTIFICATION_SERVER
        }
    })

    return {
        config: data?.getRistopubNotificationConfig,
        loading
    }
}