import styles from "../menu/ProductsScreen/EditProductModal/EditProductModal.module.scss";
import {InboxOutlined} from "@ant-design/icons";
import {SwippyDragger} from "../components/SwippyDragger/SwippyDragger";
import React, {useRef, useState} from "react";
import {RcFile} from "antd/es/upload";
import ReactCrop from 'react-image-crop';
import {SwippyModal} from "../components/SwippyModal/SwippyModal";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";
import {nanoid} from "nanoid";
import {RistopubImagePreview} from "./RistopubImagePreview";



function getCroppedImg(
    image: HTMLImageElement,
    crop: ReactCrop.Crop,
    fileName: string
): Promise<File> {
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const scaleX = image.naturalWidth / image.width;


    const scaleY = image.naturalHeight / image.height;

    const cropWidth = (crop.width || 0) * scaleX
    const cropHeight = (crop.height || 0) * scaleY


    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error("Can't get canvas 2d context")




    ctx.drawImage(
        image,
        (crop.x || 0) * scaleX,
        (crop.y || 0) * scaleY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) return reject("Can't convert canvas to blob")
            const b: any = blob
            b.name = fileName
            b.lastModifiedDate = new Date();
            resolve(blob as File);
        }, 'image/jpeg', 1);
    });
}


type PropTypes = {
    onChange?: (file: File) => void
    initialSrc?: string
    value?: File,
    aspectRatio?: number
}



export function CroppableImageInput(props: PropTypes) {

    const [image2upload, setImage2Upload] = useState<RcFile>()
    const [imgSrc, setImgSrc] = useState<string>()
    const [showCrop, setShowCrop] = useState(false)
    const [crop, setCrop] = useState<ReactCrop.Crop>({
        aspect: props.aspectRatio || 1.8
    })

    const cropRef = useRef<ReactCrop>(null)
    const [cropping, setCropping] = useState(false)
    function loadImgSrc(img: RcFile) {
        return new Promise<void>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function(e) {
                if (!e.target) return reject()
                setImgSrc(e.target.result as string)
                resolve()
            }

            reader.readAsDataURL(img);
        })
    }


    function onChangeCrop(crop: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) {
        setCrop(crop)

    }

    async function confirmCrop() {


        if (!imgSrc || !crop) return

        // @ts-ignore
        const imgRef = cropRef.current?.imageRef as HTMLImageElement


        setCropping(true)
        try {
            const imgFile = await getCroppedImg(imgRef,crop,nanoid())
            if (props.onChange) props.onChange(imgFile)
            setShowCrop(false)

        } finally {
            setCropping(false)
        }
        // const img = document.createElement("img") as HTMLImageElement
        // img.src = imgSrc
        // img.style.visibility = "hidden"
        // img.onload = async () => {
        //
        //     try {
        //         const imgFile = await getCroppedImg(img,crop,nanoid())
        //         if (props.onChange) props.onChange(imgFile)
        //         setShowCrop(false)
        //
        //     } finally {
        //         setCropping(false)
        //     }
        // }
        // document.body.appendChild(img)
    }

    return <>

        <SwippyModal
            destroyOnClose={true}
            footer={<div>
                <SwippyButton
                    loading={cropping}
                    onClick={confirmCrop}
                    type={"primary"}>
                    Conferma
                </SwippyButton>
            </div>}
            onCancel={() => setShowCrop(false)}
            title={"Taglia immagine"}
            visible={showCrop && !!imgSrc}>
            {imgSrc ? <ReactCrop
                    ref={cropRef}
                    onImageLoaded={image => {
                        setCrop({
                            ...crop,
                            width: image.width,
                        })
                        return false;
                    }}
                src={imgSrc} crop={crop} onChange={onChangeCrop}/> : null}
        </SwippyModal>


        <div className={"mb-2"}>
            <RistopubImagePreview
                src={props.initialSrc}
                width={300}
                imageFile={props.value}
            />
        </div>


        <SwippyDragger
            showUploadList={false}
            className={styles.imageInputDragger}
            accept={"image/jpeg,image/png"}
            customRequest={async ({file, onSuccess}) => {
                setImage2Upload(file)
                await loadImgSrc(file)
                setShowCrop(true)
                onSuccess({
                    status: "ok"
                }, file)
            }}
        >
            <div className={styles.imageDraggerInner}>
                <InboxOutlined style={{
                    fontSize: 36,
                    color: "#2195d8"
                }}/>
                <p>
                    Trascina o clicca per caricare un' immagine
                </p>
            </div>


        </SwippyDragger>
        </>


}