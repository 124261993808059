export class ProductModifierChoice {
    constructor(
        public id: string,
        public name?: string,
        public price: number = 0,
        public maxQty?: number,
        public readonly _isNew?: boolean
    ) {
    }


    public hasUnlimitedChoices(): boolean {
        const maxQty = this.maxQty ?? 0
        return maxQty <= 0
    }
}