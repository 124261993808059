import styles from "./SwippyCheckbox.module.scss"
import {Checkbox} from "antd";
import {CheckboxProps} from "antd/lib/checkbox/Checkbox";
import React from "react";


type PropTypes = CheckboxProps

export function SwippyCheckbox (props: PropTypes) {


    const classes = [styles.swippyCheckbox, "swippyCheckbox"]
    if (props.className) classes.push(props.className)
    return <Checkbox {...props} className={classes.join(" ")}>
        {props.children || null}
    </Checkbox>
}