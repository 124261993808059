import {SwippyButton} from "../../components/SwippyButton/SwippyButton";
import {SwippyInput} from "../../components/SwippyInput/SwippyInput";
import {SwippyModal} from "../../components/SwippyModal/SwippyModal";
import React, {useEffect, useState} from "react";
import {Category} from "./classes/Category";
import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import _ from "lodash";
import {Col, Form, Row} from "antd";
import {SwippyTextarea} from "../../components/SwippyTextarea/SwippyTextarea";
import {useUpsertCategory} from "./useUpsertCategory";


type PropTypes = {
    category?: Category,
    onCancel?: () => void
    visible?: boolean
}




function EditCategoryModalComponent(props: PropTypes) {
    const {upsertCategory, upsertingCategory} = useUpsertCategory()

    const [categoryName, setCategoryName] = useState(props.category?.name)
    const [categoryDescription, setCategoryDescription] = useState(props.category?.description)


    useEffect(() => {
        setCategoryDescription(props.category?.description)
        setCategoryName(props.category?.name)
    }, [props.category])

    async function saveCategory() {
        try {
            if (!props.category) throw new Error("Category is missing")
            if (_.size(categoryName) <= 0) return


            const category = _.cloneDeep(props.category)
            category.name = categoryName
            category.description = categoryDescription
            await upsertCategory(category)
            if (props.onCancel) props.onCancel()

        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            console.error(e)
            throw e
        }
    }

    return <SwippyModal
        destroyOnClose
        maskClosable={false}
        footer={<div>
            <SwippyButton
                gaCategory={"menu_management"}
                gaLabel={"menu_management_save_category"}

                disabled={_.size(categoryName) <= 0}
                loading={upsertingCategory} onClick={saveCategory} type={"primary"}>
                Salva
            </SwippyButton>
        </div>}
        title={"Nuova categoria"} onCancel={props.onCancel} visible={props.visible}>
        <Form layout={"vertical"}>
            <Row gutter={16}>
                <Col span={24}>


                    <Form.Item label={"Nome categoria"}>
                        <SwippyInput
                            autoFocus={true}
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder={"Es. Aperitivo"}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"Descrizione categoria"}>
                        <SwippyTextarea
                            value={categoryDescription}
                            onChange={(e) => setCategoryDescription(e.target.value)}
                            placeholder={"Il campo descrizione è utile per le categorie che contengono prodotti in promozione " +
                            "o che hanno modalità di consegna particolari"}/>
                    </Form.Item>
                </Col>


            </Row>

        </Form>
    </SwippyModal>
}

export function EditCategoryModal(props: PropTypes) {
    return <EditCategoryModalComponent
        {...props}/>


}