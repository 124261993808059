import {gql, useQuery} from "@apollo/client";
import {ProductModifierDTO} from "../../../ProductModifier/ProductModifierDTO";
import _ from "lodash";
import {useRef} from "react";
import {ProductModifierDataMapper} from "../../../ProductModifier/ProductModifierDataMapper";

const GET_MODIFIERS_OF_PRODUCT_QUERY = gql`
    query getModifiersOfProduct($input: GetModifiersOfProductInput!) {
        getModifiersOfProduct(input: $input) {
            id
            choices {
                id
                name
                price
            }
            maxSelectableChoices
            required
            description
            name
        }
    }
`

export function useGetModifiersOfProduct(productId?: string) {
    const {data, loading} = useQuery<{
        getModifiersOfProduct: ProductModifierDTO[]
    }, {
        input: {
            productId: string
        }
    }>(GET_MODIFIERS_OF_PRODUCT_QUERY, {
        skip: !productId,
        fetchPolicy: "network-only",
        variables: {
            input: {
                productId: productId as string
            }
        }
    })


    const {current: dataMapper} = useRef(new ProductModifierDataMapper())

    return {
        modifiers: _.map(data?.getModifiersOfProduct, mod => dataMapper.toEntity(mod)),
        loading
    }

}