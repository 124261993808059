import React, {useState} from "react"
import {Layout} from 'antd';
import styles from "./MainLayout.module.scss"
import {SwippyHeader} from "./SwippyHeader/SwippyHeader";
import {SwippyMenu} from "./SwippyMenu/SwippyMenu";
import {ChromeAdvice} from "./ChromeAdvice";

const { Header, Content, Sider } = Layout;

type PropTypes = {
    children?: React.ReactNode,
    withHeader?: boolean
    withSider?: boolean
}

export function MainLayout (props: PropTypes) {
    const [mobileOpenedMenu, setMobileOpenedMenu] = useState(false)
    const mainContentLayoutClasses = [styles.mainContentLayout]
    if (props.withSider) mainContentLayoutClasses.push(styles.withSider)
    if (mobileOpenedMenu) mainContentLayoutClasses.push(styles.siderOpened)


    function openMenuOnMobile() {
        setMobileOpenedMenu(true)
    }


    function closeMenu() {
        setMobileOpenedMenu(false)
    }

    return (

        <>
            <ChromeAdvice />
            <Layout className={styles.mainLayout} style={{
                minHeight: "100vh"
            }}>
                {props.withSider ? <SwippyMenu
                    closeMenuOnMobile={closeMenu}
                    opened={mobileOpenedMenu}/> : null}
                <Layout className={mainContentLayoutClasses.join(" ")}>
                    {props.withHeader ? <SwippyHeader
                        openMenuOnMobile={openMenuOnMobile}
                    /> : null}
                    <Content
                        className={styles.content}>{
                        props.children || null
                    }</Content>
                    {/*<Footer>Footer</Footer>*/}
                </Layout>
            </Layout>

        </>
    );
}
