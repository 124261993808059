import {gql} from "@apollo/client";

export const GET_CATEGORIES_QUERY = gql`
    query getCategories {
        getCategories {
            id
            name
            description
            __typename
            pos
        }
    }
`