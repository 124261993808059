import {gql, useMutation} from "@apollo/client";
import {QrCodeDTO} from "./QrCodeDTO";
import {QrCode} from "./QrCode";
import {QrCodeType} from "./QrCodeType";

const UPDATE_QR_CODES_MUTATION = gql`

    mutation updateQrCodes($input: UpdateQrCodesInput!) {
        updateQrCodes(input: $input) {
            enableDeliveryToLocation
            enablePickup
            id
            name
            ristopubId
            type
            enableDelivery
            enableTakeaway
        }
    }
`

export function useUpdateQrCodes() {
    const [updateQrCodesMutation, {
        loading
    }] = useMutation<{
        updateQrCodes: QrCodeDTO[]
    }, {
        input: {
            qrCodes: {
                enableDeliveryToLocation: boolean
                enablePickup: boolean
                id: string
                name: string
                type: QrCodeType
                enableDelivery?: boolean
                enableTakeaway?: boolean
            }[]
        }
    }>(UPDATE_QR_CODES_MUTATION)


    async function updateQrCodes(
        qrCodes: QrCode[]
    ) {
        await updateQrCodesMutation({
            variables: {
                input: {
                    qrCodes: qrCodes.map(qr => {
                        return {
                            name: qr.name,
                            id: qr.id,
                            enableDeliveryToLocation: qr.enableDeliveryToLocation,
                            enablePickup: qr.enablePickup,
                            type: qr.type,
                            enableDelivery: qr.enableDelivery,
                            enableTakeaway: qr.enableTakeaway
                        }
                    })
                }
            }
        })
    }


    return {
        updateQrCodes,
        loading
    }
}