import {VirtualTable} from "../../../virtualTable/VirtualTable";
import React from "react";
import styles from "./VirtualTableListItem.module.scss"
import _ from "lodash";
import {VirtualTableOrderItem} from "./VirtualTableOrderItem/VirtualTableOrderItem";
import {PayVirtualTableActions} from "./VirtualTableActions/PayVirtualTableActions/PayVirtualTableActions";
import {Tag} from "antd";
import {formatPrice} from "../../../formatPrice/formatPrice";

type PropTypes = {
    virtualTable: VirtualTable
    showActions: boolean
    showCreatedAt?: boolean
}

export function VirtualTableListItem(props: PropTypes) {

    const items = props.virtualTable.getAggregateOrderItemsByKey()
    const aggregatedNotes = props.virtualTable.getAggregatedCustomerNotes()
    const firstOrder = _.first(props.virtualTable.orders)



    const displayName = props.virtualTable.getDisplayName()

    const nameParts: string[] = []


    if (displayName && displayName.length) {
        nameParts.push(displayName)
    }

    if (firstOrder?.friendlyOrderId) {
        nameParts.push(firstOrder.friendlyOrderId)
    }


    return <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>

            <div className={["flex flex-col", styles.mainHeaderWrapper].join(" ")}>
                <div className={styles.topHeader}>
                    <h5 className={styles.name}>{nameParts.join(" - ")}</h5>
                    <Tag className={styles.typeBadge} style={{
                        backgroundColor: props.virtualTable.getTypeColor()
                    }}>{props.virtualTable.getTypeLabel()}</Tag>
                </div>
                {props.showCreatedAt && props.virtualTable.createdAt ? <p className={"text-sm m-0"}>Ricevuto il {props.virtualTable.createdAt.format("DD MMM YYYY [alle] HH:mm")}</p> : null}
            </div>



            <div className={styles.header}>
                <div>
                    {firstOrder?.isDelivery() && firstOrder?.deliveryDate ? <p className={"text-sm m-0"}>Data consegna: {firstOrder.deliveryDate.format("DD MMM YYYY HH:mm")}</p> : null}
                    {firstOrder?.isDelivery() && firstOrder?.deliveryAddress ? <p className={"text-sm m-0"}>Indirizzo di consegna: {firstOrder.deliveryAddress.toString()}</p> : null}
                    {firstOrder?.isTakeAway() && firstOrder?.dueDate ? <p className={"text-sm m-0"}>Data ritiro: {firstOrder.dueDate.format("DD MMM YYYY HH:mm")}</p> : null}
                    {props.virtualTable.customerPhone ? <p className={"text-sm m-0"}>Telefono del cliente: {props.virtualTable.customerPhone.phone}</p> : null}
                </div>
            </div>
        </div>

        <div className={styles.orderItemsList}>
            {
                _.map(items, i => {
                    return <VirtualTableOrderItem key={i.id} orderItem={i}/>
                })
            }
        </div>
        {firstOrder?.deliveryCost  ? <div className={styles.totalWrapper}>
            <span className={styles.label}>Consegna</span>
            <span className={styles.total}>{formatPrice(firstOrder.deliveryCost)} €</span>
        </div> : null}
        <div className={styles.totalWrapper}>
            <span className={styles.label}>Totale</span>
            <span className={styles.total}>{formatPrice(props.virtualTable.getTotal())} €</span>
        </div>
        {aggregatedNotes ? <div className={styles.notesWrapper}>
            <span className={styles.label}>Note del cliente</span>
            <span >{aggregatedNotes}</span>
        </div> : null}
        {props.virtualTable.isOpened() && props.showActions ? <div>
            <PayVirtualTableActions virtualTable={props.virtualTable}/>
        </div> : null}
    </div>
}