import {gql, useQuery} from "@apollo/client";
import {ProductModifierDTO} from "./ProductModifierDTO";
import {useRef} from "react";
import {ProductModifierDataMapper} from "./ProductModifierDataMapper";
import _ from "lodash";

const GET_PRODUCT_MODIFIERS = gql`
    query getModifiers {
        getModifiers {
            id
            name
            description
            choices {
                id
                price
                name
                maxQty
                
            }
            required
            maxSelectableChoices
            type
        }
    }
`

export function useAllModifiers() {
    const {data, loading, refetch} = useQuery<{
        getModifiers: ProductModifierDTO[]
    }>(GET_PRODUCT_MODIFIERS)

    const {current: dataMapper} = useRef(new ProductModifierDataMapper())


    return {
        modifiers: _.map(data?.getModifiers, mod => dataMapper.toEntity(mod)),
        loading,
        refetch
    }

}