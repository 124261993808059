import {VirtualTableDTO} from "./VirtualTableDTO";
import {VirtualTable} from "./VirtualTable";
import {OrderDataMapper} from "./Order/OrderDataMapper";
import _ from "lodash";
import moment from "moment-timezone";

export class VirtualTableDataMapper {

    constructor(private orderDataMapper: OrderDataMapper) {
    }

    toVirtualTable(d: VirtualTableDTO): VirtualTable {
        return VirtualTable.create({
            id: d.id,
            createdAt: d.createdAt ? moment(d.createdAt) : undefined,
            name: d.name,
            status: d.status,
            type: d.type,
            customerPhone: d.customerPhone,
            customerName: d.customerName,
            orders: _.map(d.orders, o => this.orderDataMapper.toOrder(o))
        })
    }
}