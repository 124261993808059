import {OrdersListItem} from "./OrdersListItem";
import React, {useState} from "react";
import _ from "lodash";
import {OrdersListItemRenderer} from "./OrdersListItemRenderer/OrdersListItemRenderer";
import {EditOrderModal} from "../EditOrderModal/EditOrderModal";
import {OrderType} from "../../virtualTable/Order/OrderDTO";


type PropTypes = {
    orders: OrdersListItem[]
    showAcceptedActions?: boolean
    showMinutesCounter?: boolean
    showMarkAsPaidAction?: boolean
    showMarkAsDeliveredAction?: boolean
    showNewTag?: boolean
}



export function OrdersList(props: PropTypes) {




    const [editingOrder, setEditingOrder] = useState<OrdersListItem>()


    return <div>
        <EditOrderModal createOrderAvailableTypes={[
            "TAKEAWAY",
            "ONTABLE",
            "DELIVERY"
        ]} isNew={false} onCancel={() => setEditingOrder(undefined)} order={editingOrder}/>

        {
            _.map(props.orders, (o,index) => <OrdersListItemRenderer
                onEdit={order => {
                    setEditingOrder(order)}
                }
                showNewTag={props.showNewTag}
                showMinutesCounter={props.showMinutesCounter}
                showAcceptedActions={props.showAcceptedActions}
                showMarkAsPaidAction={props.showMarkAsPaidAction}
                showMarkAsDeliveredAction={props.showMarkAsDeliveredAction}
                key={o.order.id}
                order={o}/>)
        }

    </div>


}