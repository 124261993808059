import {Category} from "../../CategoriesScreen/classes/Category";

type Image = {
    src: string
}

type CreateProductInput = {
    id?: string
    name?: string
    image?: Image
    price?: number
    description?: string
    published?:boolean,
    pos: number,
    category?: Category
    subTitle?: string
}

export class Product {
    private constructor(
        public id: string | undefined,
        public price: number,
        public published: boolean | undefined,
        public pos: number, public name?: string, private image?: Image, public description?: string,
        private category?: Category,
        public subTitle?: string
        ) {
    }


    public getCategory() {
        return this.category
    }


    public getImageSrc() {
        return this.image ? this.image.src : undefined
    }


    public static create({
        id,
        name,
        image,
        price,
        description,
        pos,
        category,
        published,subTitle
                         }: CreateProductInput) {

        return new Product(id,price || 0,published,pos,name,image,description,
            category,subTitle
        )
    }
}