import {gql, useMutation} from "@apollo/client";
import {ProductModifierDTO} from "../../../ProductModifier/ProductModifierDTO";

const SET_MODIFIERS_OF_PRODUCT_MUTATION = gql`
    mutation setModifiersOfProduct($input: SetModifiersOfProductInput!) {
        setModifiersOfProduct(input: $input) {
            id
        }
    }
`


export function useSetModifiersOfProduct() {

    const [setModifiersOfProductMutation, {
        loading
    }] = useMutation<{
        setModifiersOfProduct: ProductModifierDTO[]
    }, {
        input: {
            modifiersIds: string[]
            productId: string
        }
    }>(SET_MODIFIERS_OF_PRODUCT_MUTATION)

    async function setModifiersOfProduct(productId: string, modifiersIds: string[]) {
        await setModifiersOfProductMutation({
            variables: {
                input: {
                    productId,
                    modifiersIds
                }
            }
        })
    }


    return {
        setModifiersOfProduct,
        loading
    }
}