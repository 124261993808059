import {gql, useQuery} from "@apollo/client";
import {QrCodeDTO} from "./QrCodeDTO";
import {useRef} from "react";
import {QrCodeDataMapper} from "./QrCodeDataMapper";
import _ from "lodash";
import {QrCodeType} from "./QrCodeType";

const GET_QR_CODES = gql`
    query getQrCodes($type: QrCodeType) {
        getQrCodes(type: $type) {
            enableDeliveryToLocation
            enablePickup
            id
            name
            type
            enableDelivery
            enableTakeaway

        }
    }
`

export function useQrCodes(type?: QrCodeType) {
    const {data, loading, refetch} = useQuery<{
        getQrCodes: QrCodeDTO[]
    }, {
        type?: QrCodeType
    }>(GET_QR_CODES, {
        variables: {
            type
        }
    })


    const {current: dataMapper} = useRef(new QrCodeDataMapper())


    return {
        qrCodes: _.map(data?.getQrCodes, qr => dataMapper.toEntity(qr)),
        loading,
        refetch
    }
}