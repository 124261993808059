import {Moment} from "moment-timezone";
import {OrderItemInput} from "./OrderItemInput";

export class UpdateDeliveryOrderInput {
    constructor(
        public readonly orderId: string,
        public readonly customerName?: string,
        public readonly customerNotes?: string,
        public readonly customerPhone?: string,
        public readonly deliveryAddress?: string,
        public readonly deliveryDate?: Moment,
        public readonly items?: OrderItemInput[]
    ) {
    }
}