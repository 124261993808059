import styles from "./SwippyTextarea.module.scss"
import {Input} from "antd";
import React from "react";
import {TextAreaProps} from "antd/lib/input/TextArea";

type PropTypes = {} & TextAreaProps

export function SwippyTextarea(props: PropTypes) {

    const classes = [styles.swippyTextarea]
    if (props.className) classes.push(props.className)
    return <Input.TextArea {...props} className={classes.join(" ")}/>
}