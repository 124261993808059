import React from "react"
import {firebase} from "../firebase";
import {User} from "../auth/user/User";


type FirebaseUserCtxType = {
    loggedUser?: User,
    logout: () => Promise<void>
    loadingUser: boolean
}

const SwippyLoggedUserCtx = React.createContext<FirebaseUserCtxType>({
    loggedUser: undefined,
    logout: async () => {},
    loadingUser: false
})

export {
    SwippyLoggedUserCtx
}
