export function createPolygonOptions() {
    return {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        //bounds: bounds,
        draggable: true,
        editable: true
    }
}