import {gql, useMutation} from "@apollo/client";

const MARK_AS_PAID_MUTATION = gql`
    mutation markAsPaidAllAcceptedOrderOfVirtualTable($input: MarkAsPaidAllAcceptedOrderOfVirtualTableInput!) {
        markAsPaidAllAcceptedOrderOfVirtualTable(input: $input) {
            __typename
            id
            type
            customerName
            customerPhone {
                phone
            }
            orders {
                id
                __typename
                orderStatus {
                    id
                    
                }
                statusChangedAt
            }
        }
    }
`

export function useMarkAsPaidAllAcceptedOrdersOfVirtualTable() {
    const [markAsPaidAllAcceptedOrderOfVirtualTable] = useMutation(MARK_AS_PAID_MUTATION)


    return {
        markAsPaidAllAcceptedOrderOfVirtualTable
    }
}