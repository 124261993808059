import React, {useEffect, useState} from "react";

import _ from "lodash";
import {Product} from "../classes/Product";
import {GenericErrorHandler} from "../../../classes/GenericErrorHandler";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import {SwippyModal} from "../../../components/SwippyModal/SwippyModal";
import {SwippyInput} from "../../../components/SwippyInput/SwippyInput";
import {Category} from "../../CategoriesScreen/classes/Category";
import {Col, Form, Row, Space} from "antd";
import {InboxOutlined} from '@ant-design/icons';
import {SwippyDragger} from "../../../components/SwippyDragger/SwippyDragger";
import styles from "./EditProductModal.module.scss"
import {RcFile} from "antd/es/upload";
import {SwippyInputNumber} from "../../../components/SwippyInputNumber/SwippyInputNumber";
import {SwippyTextarea} from "../../../components/SwippyTextarea/SwippyTextarea";
import {ProductImagePreview} from "../ProductImagePreview/ProductImagePreview";
import {useUpsertProduct} from "../useUpsertProduct";
import {SwippyCheckbox} from "../../../components/SwippyCheckbox/SwippyCheckbox";
import {ImageUploader} from "./ImageUploader";
import {ModifiersOfProductInput} from "./ModifiersOfProductInput";
import {useSetModifiersOfProduct} from "./useSetModifiersOfProduct";

type PropTypes = {
    product?: Product,
    category: Category,
    onCancel?: () => void
    visible?: boolean
}
function EditProductModalComponent(props: PropTypes) {
    const {upsertProduct} = useUpsertProduct()


    const [productName, setProductName] = useState(props.product?.name)
    const [productDescription, setProductDescription] = useState(props.product?.description)
    const [image2upload, setImage2Upload] = useState<RcFile>()
    const [productPrice, setProductPrice] = useState<number | undefined>((props.product?.price))
    const [productPublished, setProductPublished] = useState<boolean | undefined>((typeof props.product?.published !== "undefined") ? props.product?.published : true )

    const [productModifierIds, setProductModifierIds] = useState<string[] | undefined>()
    const [subTitle, setSubTitle] = useState<string | undefined>(props.product?.subTitle)
    const [form] = Form.useForm();


    const [uploading, setUploading] = useState(false)

    const {setModifiersOfProduct, loading: settingModifiersOfProduct} = useSetModifiersOfProduct()

    useEffect(() => {
        setProductName(props.product?.name)
        setProductDescription(props.product?.description)
        setProductPrice(props.product?.price)
        setImage2Upload(undefined)
        setProductPublished(props.product?.published)
        setProductModifierIds(undefined)
        setSubTitle(props.product?.subTitle)
    }, [props.product])


    async function uploadImageIfNeeded() {
        const imgUploader = new ImageUploader()
        if(image2upload) {
            return await imgUploader.uploadImage(image2upload)
        }
    }

    async function saveProduct() {
        try {
            if (!props.product) throw new Error("Product is missing")
            if (_.size(productName) <= 0) return
            setUploading(true)

            const uploadRes = await uploadImageIfNeeded()




            const product = _.cloneDeep(props.product)
            const category = _.cloneDeep(props.category)
            product.name = productName
            product.price = productPrice || 0
            product.description = productDescription
            product.published = productPublished
            product.subTitle = subTitle

            const newProduct = await upsertProduct(product, category, {
                image2upload: uploadRes
            })

            if (productModifierIds !== undefined) {
                await setModifiersOfProduct(newProduct.id as string,  productModifierIds)
            }

            if (props.onCancel) props.onCancel()

        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            console.error(e)
            throw e
        } finally {
            setUploading(false)
        }
    }





    return <SwippyModal
        destroyOnClose
        maskClosable={false}
        footer={<div>
            <SwippyButton

                gaCategory={"menu_management"}
                gaLabel={"menu_management_save_product"}
                disabled={_.size(productName) <= 0}
                loading={uploading}
                onClick={saveProduct}
                type={"primary"}>
                Salva
            </SwippyButton>
        </div>}
        title={"Nuovo prodotto"}
        onCancel={props.onCancel}
        visible={props.visible}>
        <Form
            form={form}
            layout={"vertical"}>
            <Row gutter={16}>
                <Col xs={24} sm={12}>
                    <Form.Item label={"Nome"}>
                        <SwippyInput
                            autoFocus={true}

                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder={"Nome prodotto"}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item label={"Sottotitolo"}>
                        <SwippyInput
                            value={subTitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                            placeholder={"Sottotitolo"}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                    <Form.Item label={"Prezzo"}>
                        <SwippyInputNumber
                            step={0.01}
                            onChange={(price) => {
                                if (typeof price !== "string") setProductPrice(price)
                            }}
                            value={productPrice}
                            min={0}
                            formatter={value => `€ ${value}`}
                            parser={value => value ? value.replace(/€\s*/g, '').replace(",", ".") : 0}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item  label={"Descrizione"}>
                        <SwippyTextarea
                            value={productDescription}
                            onChange={e => setProductDescription(e.target.value)}
                            placeholder={"Scrivi qui una breve descrizione del prodotto (Max 300 caratteri)"}
                            maxLength={750}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"Immagine"}>
                        <div className={styles.productImageInputWrapper}>

                            <ProductImagePreview
                                src={props.product?.getImageSrc()}
                                className={styles.productImage}
                                imageFile={image2upload}
                            />

                            {props.product ? <SwippyDragger
                                showUploadList={false}
                                className={styles.imageInputDragger}
                                accept={"image/jpeg,image/png"}
                                customRequest={({file, onSuccess}) => {
                                    setImage2Upload(file)
                                    onSuccess({
                                        status: "ok"
                                    }, file)
                                }}
                            >
                                <div className={styles.imageDraggerInner}>
                                    <InboxOutlined style={{
                                        fontSize: 36,
                                        color: "#2195d8"
                                    }}/>
                                    <p>
                                        Trascina o clicca per caricare un' immagine
                                    </p>
                                </div>


                            </SwippyDragger> : null}
                        </div>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                    <Space>
                    Pubblicato:
                        <SwippyCheckbox defaultChecked={productPublished} onChange={(e)=>{ setProductPublished(e.target.checked)}}
                        />
                    </Space>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={"Modificatori associati"}
                    >
                        <ModifiersOfProductInput
                            product={props.product}
                            onChange={newModifiers => setProductModifierIds(newModifiers)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    </SwippyModal>
}

export function EditProductModal(props: PropTypes) {


    return <EditProductModalComponent
        {...props} />
}