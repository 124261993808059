import {gql, useQuery} from "@apollo/client";
import _ from "lodash";
import {CategoryDTO} from "../menu/CategoriesScreen/classes/CategoryDTO";
import {CategoryDataMapper} from "../menu/CategoriesScreen/classes/CategoryDataMapper";

const GET_CATEGORIES_WITH_PRODUCTS_QUERY = gql`
    query getCategoriesWithProductsForBackoffice {
        getCategoriesWithProductsForBackoffice {
            __typename
            id
            name
            products {
                id
                __typename
                name
                price
                published
                image {
                    src
                }
                category {
                    id
                }
            }
        }
    }
`


const catDataMapper = new CategoryDataMapper()

export function useGetCategoriesWithProducts() {
    const {data,loading} = useQuery(GET_CATEGORIES_WITH_PRODUCTS_QUERY, {
        fetchPolicy: "cache-and-network"
    })



    const dtos = _.get(data, "getCategoriesWithProductsForBackoffice", []) as CategoryDTO[]




    const categories = _.map(dtos, d => catDataMapper.toCategory(d))


    return {
        categories,
        loading
    }

}
