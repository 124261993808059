import {gql, useMutation} from "@apollo/client";
import {Category} from "./classes/Category";
import {GET_CATEGORIES_QUERY} from "./CategoriesList/GetCategoriesQuery";
import {GenericErrorHandler} from "../../classes/GenericErrorHandler";
import {useState} from "react";

const DELETE_CATEGORY_MUTATION = gql`
    mutation deleteCategory($input: DeleteCategoryInput!) {
        deleteCategory(input: $input)
    }
`
export function useDeleteCategory() {
    const [deleteCategoryMutation] = useMutation(DELETE_CATEGORY_MUTATION)
    const [deletingCategory, setDeletingCategory] = useState(false)

    async function deleteCategory(category: Category) {
        try {
            setDeletingCategory(true)
            await deleteCategoryMutation({
                variables: {
                    input: {
                        categoryId: category.id
                    }
                },
                update: (cache) => {
                    const data = cache.readQuery<{
                        getCategories: Category[]
                    }>({
                        query: GET_CATEGORIES_QUERY,
                    });
                    const newData = {
                        getCategories: data?.getCategories.filter((c) => c.id !== category.id)
                    }
                    cache.writeQuery({
                        query: GET_CATEGORIES_QUERY,
                        data: newData,
                    });
                }
            })
        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
            console.error(e)
            throw e
        } finally {
            setDeletingCategory(false)
        }
    }

    return {
        deleteCategory
    }
}