import {Email} from "./Email";
import {Ristopub} from "../../ristopub/Ristopub";
import {StripeAccountDTO} from "./UserDTO";
import {getFirebaseCurrentUser} from "./getFirebaseUser";

type CreateUserInput = {
    id: string
    email?: string
    ristopub: Ristopub
    stripeAccount?: StripeAccountDTO
}

export class User {
    private constructor(
        public id: string,
        private ristopub: Ristopub,
        private email?: Email,
        public stripeAccount?: StripeAccountDTO,

    ) {
    }

    public getEmail() {
        return this.email ? this.email.email : undefined
    }


    public getRistopub() {
        return this.ristopub
    }
    public static create({
        id,
        email,
        ristopub,
                             stripeAccount,
                         }: CreateUserInput) {
        const emObj = email ? new Email(email) : undefined
        return new User(id,ristopub,emObj,stripeAccount)
    }

    async getIdToken(refreshIfNeeded: boolean) {
        const user = await getFirebaseCurrentUser()
        return user ? await user.getIdToken(refreshIfNeeded) : undefined
    }
}