
import React, {useState} from "react";
import {TimeSlotsList} from "../../../../TimeSlot/TimeSlotsList/TimeSlotsList";
import styles from "./TakeAwaySlotsInput.module.scss"
import {SwippyInputLabelWithCta} from "../../../../components/SwippyInputLabelWithCta/SwippyInputLabelWithCta";
import {RistopubTakeawaySlot} from "../../../../ristopub/RistopubTakeawaySlot";
import {EditTimeSlotModal} from "../../../../TimeSlot/EditTimeSlotModal/EditTimeSlotModal";
import _ from "lodash";
import {useUpsertMyRistopub} from "../../../../ristopub/useUpsertMyRistopub";
import {useSwippyLoggedUserCtx} from "../../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {useCurrentUser} from "../../../../auth/hooks/useCurrentUser";


export function TakeAwaySlotsInput() {
    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const [editingSlot, setEditingSlot] = useState<RistopubTakeawaySlot>()
    const [currentSlotIndex, setCurrentSlotIndex] = useState<number>(-1)
    const {upsertMyRistopub} = useUpsertMyRistopub()
    if (!user) return null

    const slots = user.getRistopub().getTakeawaySlots() || []


    function newSlot() {
        setEditingSlot(RistopubTakeawaySlot.create({
            start: "00:00:00",
            end: "00:00:00"
        }))
    }


    function editSlot(slotIndex: number, slot: RistopubTakeawaySlot) {
        if (slotIndex < 0) return
        setCurrentSlotIndex(slotIndex)
        setEditingSlot(slot)

    }


    function closeEditSlotModal() {
        setEditingSlot(undefined)
        setCurrentSlotIndex(-1)
    }




    async function saveTakeawaySlot(slot: RistopubTakeawaySlot) {
        if (!user) throw new Error("Missing user")
        const ristopub = _.cloneDeep(user.getRistopub())
        if (!ristopub) throw new Error("Missing ristopub")
        if (currentSlotIndex < 0) ristopub.addTakeawaySlot(slot)
        else ristopub.updateTakeAwaySlot(currentSlotIndex, slot)
        await upsertMyRistopub(ristopub)
        closeEditSlotModal()
    }


    async function deleteSlot(slot: RistopubTakeawaySlot, index: number) {
        const ristopub = _.cloneDeep(user?.getRistopub())
        if (!ristopub) throw new Error("Missing ristopub")
        ristopub.deleteTakeawaySlot(index)
        await upsertMyRistopub(ristopub)
    }


    return <div className={styles.wrapper}>
        {/*<Divider style={{fontWeight: "bold"}}>Take Away</Divider>*/}


        <EditTimeSlotModal
            index={currentSlotIndex}
            onSave={saveTakeawaySlot}
            onCancel={closeEditSlotModal}
            timeSlot={editingSlot} />

        <SwippyInputLabelWithCta
            ctaGaProps={{
                gaCategory: "takeaway_settings",
                gaLabel: "takeaway_settings_open_add_slot",
            }}
            title={"Fasce orarie valide per ordinare"}
            ctaLabel={"Aggiungi fascia"}
            onClickCta={newSlot}/>
        <TimeSlotsList
            onDelete={deleteSlot}
            onClick={editSlot}
            ristopub={user.getRistopub()} timeSlots={slots}/>
    </div>

}