import {gql, useMutation} from "@apollo/client";
import {Product} from "./classes/Product";
import {GET_PRODUCTS_QUERY} from "./ProductsList/GetProductsQuery";
import {Category} from "../CategoriesScreen/classes/Category";
import {useRef, useState} from "react";
import {ProductDTO} from "./classes/ProductDTO";
import {ProductDataMapper} from "./classes/ProductDataMapper";
import {CategoryDataMapper} from "../CategoriesScreen/classes/CategoryDataMapper";

const UPSERT_PRODUCT_MUTATION = gql`
    mutation upsertProduct($input: UpsertProductInput!) {
        upsertProduct(input:$input) {
            id
            name
            price
            description
            published
            image {
                src
            }
            __typename
            pos
            subTitle
        }
    }
`


type UpsertProductOptions = {
    image2upload?: {
        s3Key: string,
        src: string
    }
}

export function useUpsertProduct() {
    const [upsertProductMutation] = useMutation<{
        upsertProduct: ProductDTO
    }>(UPSERT_PRODUCT_MUTATION)
    const [upserting, setUpserting] = useState(false)


    const {current: dataMapper} = useRef(new ProductDataMapper(
        new CategoryDataMapper()
    ))

    async function upsertProduct(
        product: Product,
        category: Category,
        options: UpsertProductOptions = {}
    ): Promise<Product> {
        setUpserting(true)
        try {
            const res = await upsertProductMutation({
                variables: {
                    input: {
                        productId: product.id,
                        name: product.name,
                        categoryId: category.id,
                        published: product.published,
                        image: options.image2upload,
                        price: product.price,
                        description: product.description,
                        pos: product.pos,
                        subTitle: product.subTitle
                    },

                },
                update: !product.id ? (cache, { data }) => {
                    const d = cache.readQuery<{
                        getProducts: Product[]
                    }>({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            input: {
                                categoryId: category.id
                            }
                        }
                    });

                    if (!data?.upsertProduct) throw new Error(`Upsert product return value is undefined`)

                    const newData = {
                        getProducts: [
                            ...d?.getProducts || [],
                            data?.upsertProduct
                        ]
                    }
                    cache.writeQuery({
                        query: GET_PRODUCTS_QUERY,
                        data: newData,
                        variables: {
                            input: {
                                categoryId: category.id
                            }
                        }
                    });
                } : undefined
            })

            const dto = res.data?.upsertProduct as ProductDTO


            return dataMapper.toProduct(dto)


        } finally {
            setUpserting(false)
        }
    }


    return {
        upsertProduct,
        upsertingProduct: upserting
    }
}