import {DeliveryAreaDTO} from "./DeliveryAreaDTO";
import {DeliveryArea} from "./DeliveryArea";
import _ from "lodash";

export class DeliveryAreaDataMapper {
    toEntity(dto: DeliveryAreaDTO): DeliveryArea {
        return new DeliveryArea(
            dto.id,
            {
                vertices: dto.vertices
            },
            dto.deliveryPricePolicy
        )
    }


}