import React from "react";
import {InputNumber} from "antd";
import {InputNumberProps} from "antd/lib/input-number";
import styles from "./SwippyInputNumber.module.scss"

type PropTypes = {} & InputNumberProps
export function SwippyInputNumber(props: PropTypes) {
    const classes = [styles.swippyInputNumber]
    if (props.className) classes.push(props.className)
    if (props.disabled) classes.push("bg-gray-200")
    return <InputNumber {...props} className={classes.join(" ")} />
}