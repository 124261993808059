import {Ristopub} from "./Ristopub";
import _ from "lodash";
import {gql, useMutation} from "@apollo/client";
import {useRef} from "react";
import {AddressDataMapper} from "./Address/AddressDataMapper";


const UPSERT_RISTOPUB_MUTATION = gql`
    mutation upsertMyRistopub($input: UpsertMyRistopubInput!) {
        upsertMyRistopub(input: $input) {
            id
            name
            slug
            enableTakeaway
            enableDelivery
            featuredImageSrc
            minimumDeliveryOrderValue
            minimumTakeawayOrderValue
            minimumOrderValueForFreeShipping
            address {
                city
                province
                postalCode
                country
                address
                geolocation {
                    latitude
                    longitude
                }
            }
            
            takeawaySlots {
                __typename
                start {
                    time
                }
                end {
                    time
                }
            }
            deliverySlots {
                __typename
                start {
                    time
                }
                
                end {
                    time
                }
            }
            
            tableQrCodes {
                id
                tableName
                __typename
            }
            __typename
            enableCashPayments
            enableInAppPayments
        }
    }

`


type UpsertMyRistopubOptions = {
    featuredImageToUpload?: {
        src: string,
        s3Key: string
    }
}

export function useUpsertMyRistopub() {


    const {current: addressDataMapper} = useRef(new AddressDataMapper())

    const [upsertMyRistopubMutation, {
        loading
    }] = useMutation(UPSERT_RISTOPUB_MUTATION)

    async function upsertMyRistopub(ristopub: Ristopub, options: UpsertMyRistopubOptions = {}) {
        await upsertMyRistopubMutation({
            variables: {
                input: {
                    enableTakeaway: ristopub.enableTakeaway,
                    slug: ristopub.slug,
                    minimumOrderValue: {
                        takeaway: ristopub.minimumTakeawayOrderValue,
                        delivery: ristopub.minimumDeliveryOrderValue
                    },
                    minimumOrderValueForFreeShipping: ristopub.minimumOrderValueForFreeShipping,
                    enableDelivery: ristopub.enableDelivery,
                    address: ristopub.address ? addressDataMapper.toDto(ristopub.address) : undefined,
                    enableInAppPayments: ristopub.enableInAppPayments,
                    enableCashPayments: ristopub.enableCashPayments,
                    emailForNotification: ristopub.getEmailForNotification(),
                    name: ristopub.name,
                    deliverySlots: _.map(ristopub.getDeliverySlots(), s => {
                       return {
                           start: s.start.time,
                           end: s.end.time
                       }
                    }),
                    takeawaySlots: _.map(ristopub.getTakeawaySlots(), s => {
                        return {
                            start: s.start.time,
                            end: s.end.time
                        }
                    }),
                    tableQrCodes: _.map(ristopub.tableQrCodes, tqr => {
                        return {
                            id: tqr.id,
                            tableName: tqr.tableName
                        }
                    }),
                    featuredImage: options.featuredImageToUpload
                }
            }
        })
    }


    return {
        upsertMyRistopub,
        upsertingMyRistopub: loading
    }
}