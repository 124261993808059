import {OrderItem} from "../../../../virtualTable/Order/OrderItem/OrderItem";
import React from "react";
import _ from "lodash";
import {OrderItemsListItem} from "./OrderItemsListItem/OrderItemsListItem";
import styles from "./OrderItemsList.module.scss"

type PropTypes = {
    items: OrderItem[]
}

export function OrderItemsList(props: PropTypes) {


    return <div className={styles.wrapper}>
        {
            _.map(props.items, i => {
                return <OrderItemsListItem
                    variations={i.variations}
                    qty={i.qty}
                    key={i.id}
                    subTitle={i.getProductSubTitle()}
                    description={i.getProductDescription()}
                    name={i.getProductName() || "Prodotto senza nome"}
                    total={i.total}/>
            })
        }

    </div>
}