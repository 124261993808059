import {gql, useMutation} from "@apollo/client";
import {QrCodeDTO} from "./QrCodeDTO";
import {QrCode} from "./QrCode";
import {QrCodeType} from "./QrCodeType";

const CREATE_QR_CODE_MUTATION = gql`
    mutation createQrCodes($input: CreateQrCodesInput!) {
        createQrCodes(input: $input) {
            enableDeliveryToLocation
            enablePickup
            id
            name
            ristopubId
            type
            enableDelivery
            enableTakeaway
        }
    }
`

export function useCreateQrCodes() {
    const [createQrCodesMutation, {
        loading
    }] = useMutation<{
        createQrCodes: QrCodeDTO[]
    }, {
        input: {
            qrCodes: {
                enableDeliveryToLocation: boolean
                enablePickup: boolean
                name: string,
                type: QrCodeType
                enableDelivery?: boolean
                enableTakeaway?: boolean
            }[]
        }
    }>(CREATE_QR_CODE_MUTATION)


    async function createQrCodes(
        qrCodes: QrCode[]
    ) {
        await createQrCodesMutation({
            variables: {
                input: {
                    qrCodes: qrCodes.map(qr => {
                        return {
                            name: qr.name,
                            enablePickup: qr.enablePickup,
                            enableDeliveryToLocation: qr.enableDeliveryToLocation,
                            type: qr.type,
                            enableDelivery: qr.enableDelivery,
                            enableTakeaway: qr.enableTakeaway
                        }
                    })
                }
            }
        })
    }



    return {
        createQrCodes,
        loading
    }
}