import {gql, useMutation} from "@apollo/client";
import {EmailConfigDTO, RistopubEmailConfigDTO} from "./RistopubEmailConfigDTO";

const UPSERT_EMAIL_CONFIG_MUTATION = gql`
    mutation upsertEmailConfig($input: UpsertEmailConfigInput!) {
        upsertEmailConfig(input: $input) {
            mailConfigs {
                wysiwygJsonState
                type
            }
            ristopubId
            logoSrc
        }
    }
`

export function useUpsertEmailConfig() {
    const [upsertEmailConfigMutation, {
        loading
    }] = useMutation<{
        upsertEmailConfig: RistopubEmailConfigDTO
    }, {
        input: {
            logoSrc?: string
            mailConfigs: EmailConfigDTO[]
        }
    }>(UPSERT_EMAIL_CONFIG_MUTATION)



    async function upsertEmailConfig(
        mailConfigs: EmailConfigDTO[],
        logoSrc?: string
    ) {
        await upsertEmailConfigMutation({
            variables: {
                input: {
                    mailConfigs,
                    logoSrc
                }
            }
        })
    }


    return {
        upsertEmailConfig,
        loading
    }


}