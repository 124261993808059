import {RistopubEmailConfigDTO} from "./RistopubEmailConfigDTO";
import {RistopubEmailConfig} from "./RistopubEmailConfig";
import {EmailConfig} from "./EmailConfig";

export class RistopubEmailConfigDataMapper {
    toEntity(d: RistopubEmailConfigDTO): RistopubEmailConfig {
        return new RistopubEmailConfig(
            d.mailConfigs.map(mc => {
                return new EmailConfig(
                    mc.wysiwygJsonState,
                    mc.type
                )
            }),
            d.ristopubId,
            d.logoSrc
        )
    }
}