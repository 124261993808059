import {Collapse} from "antd"
import React, {useState} from "react";
import _ from "lodash";
import {PlusCircleOutlined} from "@ant-design/icons"
import {Product} from "../menu/ProductsScreen/classes/Product";
import {SwippyModal} from "../components/SwippyModal/SwippyModal";
import FuzzySearch from "fuzzy-search"


import styles from "./ProductPickerModal.module.scss"
import {Category} from "../menu/CategoriesScreen/classes/Category";
import {useGetCategoriesWithProducts} from "./useGetCategoriesWithProducts";
import {ProductPickerCategoryItem} from "./ProductPickerCategoryItem/ProductPickerCategoryItem";
import {SwippyInput} from "../components/SwippyInput/SwippyInput";
import {SwippyButton} from "../components/SwippyButton/SwippyButton";

const {Panel} = Collapse
type PropTypes = {
    onSelected: (p: Product) => void
    qtyMap?: Record<string, number>
}

export function ProductPickerModal(props: PropTypes) {

    const [showModal,setShowModal] = useState(false)

    const {categories} = useGetCategoriesWithProducts()

    const [openedCategories, setOpenedCategories] = useState<string | string[] | undefined>()
    const [timeout] = useState<{timeout?: NodeJS.Timeout}>({})
    const [productsToShow, setProductsToShow] = useState<Record<string, boolean>>()


    const products = _.reduce(categories, (res: Product[], c) => {
        res.push(...c.getProducts())
        return res
    }, [])


    function search(val?: string) {

        if (timeout.timeout) clearTimeout(timeout.timeout)
        timeout.timeout = setTimeout(() => {


            if (!val) {
                setProductsToShow(undefined)
            } else {
                const searcher = new FuzzySearch(products, ['name'], {
                    caseSensitive: false,
                });
                const res = searcher.search(val) as Product[]

                const activeCategoriesIds: Record<string, string> = {}
                const productsToShow: Record<string, boolean> = {}
                 _.forEach(res, p => {
                    const category = p.getCategory() as Category
                    activeCategoriesIds[category.id as string] = category.id as string
                    productsToShow[p.id as string] = true
                })

                setOpenedCategories(_.values(activeCategoriesIds))
                setProductsToShow(productsToShow)
            }


            timeout.timeout = undefined
        }, 400)



    }

    return <>
        <div>
            <PlusCircleOutlined
                className={styles.addProductButton}
                onClick={() => setShowModal(true)} />
        </div>
        <SwippyModal title={"Scegli prodotto"}
                     footer={<div>
                         <SwippyButton
                             onClick={() => setShowModal(false)}
                             type={"primary"}
                             gaCategory={"order_management"}
                             gaLabel={"order_management_edit_order"}

                         >
                             Fatto
                         </SwippyButton>
                     </div>}
                     onCancel={() => setShowModal(false)}
                     visible={showModal}>
            <SwippyInput
                allowClear={true}
                onChange={(e) => {
                    search(e.target.value)
                }}
                className={styles.searchInput}
                placeholder={"Cerca prodotto"} />
            <Collapse activeKey={openedCategories} onChange={k => setOpenedCategories(k)}>
                {
                    _.map(categories, c => {



                        const filteredProducts = !productsToShow
                            ? c.getProducts()
                            : _.filter(c.getProducts(), p => {
                                if (!productsToShow) return false
                                return productsToShow[p.id as string]
                            })

                        if (filteredProducts.length <= 0) return null


                        return <Panel key={c.id as string} header={c.name}>
                            <ProductPickerCategoryItem
                                qtyMap={props.qtyMap}
                                products={filteredProducts}
                                category={c}
                                onSelected={props.onSelected}
                            />
                        </Panel>


                    })
                }
            </Collapse>
        </SwippyModal>
    </>
}