import {OrderItem} from "../../../../virtualTable/Order/OrderItem/OrderItem";
import React from "react";
import styles from "./EditOrderItemsListItem.module.scss"
import {formatPrice} from "../../../../formatPrice/formatPrice";
import {DeleteOutlined} from "@ant-design/icons";

type PropTypes = {
    orderItem: OrderItem
    onDelete?: () => void
}
export function EditOrderItemsListItem(props: PropTypes) {



    return <div className={styles.wrapper}>
        <h5 className={styles.productName}>x{props.orderItem.qty} {props.orderItem.product.name}</h5>
        <div className={styles.rightWrapper}>
            <span className={styles.price}>{formatPrice(props.orderItem.total)}€</span>
            <DeleteOutlined onClick={props.onDelete} className={styles.delete} />
        </div>
    </div>
}