import React from "react";

import {TakeawayOrdersToBeHandledCtx} from "./TakeawayOrdersToBeHandledCtx";
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";
import {useFetchTakeawayOrdersToBeHandled} from "./useFetchTakeawayOrdersToBeHandled";


type PropTypes = {
    children?: React.ReactNode
}

export function TakeawayOrdersToBeHandledCtxProvider(props: PropTypes) {




    const {loggedUser} = useSwippyLoggedUserCtx()

    const {orders, loading, refetch,lastQueryExecutedAt} = useFetchTakeawayOrdersToBeHandled({
        skip: !loggedUser
    })





    async function refetchOrders() {
        await refetch()
    }



    return <TakeawayOrdersToBeHandledCtx.Provider value={{
        orders,
        loading,
        refetch: refetchOrders,
        lastQueryExecutedAt
    }}>
        {props.children || null}
    </TakeawayOrdersToBeHandledCtx.Provider>


}