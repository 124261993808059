import React from "react";
import styles from "./OrderItemsListItem.module.scss"
import {formatPrice} from "../../../../../formatPrice/formatPrice";
import {VariationGroupedByModifier} from "../../../../../virtualTable/Order/OrderItem/OrderItemDTO";
import {OrderItemVariationsRenderer} from "../../../../OrderItemVariationsRenderer";

type PropTypes = {
    name: string
    qty: number,
    total: number
    variations: VariationGroupedByModifier[]
    description?: string
    subTitle?: string
}

export function OrderItemsListItem(props: PropTypes) {
    return <div className={"flex flex-col p-2"}>
        <div className={styles.wrapper}>
            <h4 className={styles.title}>x{props.qty} {props.name}</h4>
            <span className={styles.price}>{formatPrice(props.total)} €</span>
        </div>
        {props.description ? <div className={"text-sm mt-1 mb-1"}>

                <h3 className={"mb-0 font-bold "}>
                    Descrizione
                </h3>
                <p className={"mb-0"}>
                    {props.description}
                </p>
        </div>
        : null}
        {props.subTitle ? <div className={"text-sm mt-1 mb-1"}>

                <h3 className={"mb-0 font-bold "}>
                    Sottotitolo
                </h3>
                <p className={"mb-0"}>
                    {props.subTitle}
                </p>
        </div>
        : null}

        <OrderItemVariationsRenderer variations={props.variations} />
    </div>
}