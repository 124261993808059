import {Category} from "../../../classes/Category";
import styles from "./CategoryItem.module.scss"
import React from "react";
import {useHistory} from "react-router-dom"
import {SwippyListItem} from "../../../../../components/SwippyListItem/SwippyListItem";

type PropTypes = {
    category: Category
    onDelete?: (category: Category) => Promise<void> | void
    onEdit?: (category: Category) => Promise<void> | void
}

export function CategoryItem(props: PropTypes) {

    const history = useHistory()

    function goToProductsList() {
        history.push("/menu/"+props.category.id)
    }


    return <SwippyListItem

        editGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_open_edit_category"
        }}
        deleteGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_delete_category"
        }}
        clickGaProps={{
            gaCategory: "menu_management",
            gaLabel: "menu_management_click_category"
        }}

        onEdit={props.onEdit ? async () => {
            if (props.onEdit) await props.onEdit(props.category)
        } : undefined}
        onDelete={async () => {
            if (props.onDelete) await props.onDelete(props.category)
        }}
        onClick={goToProductsList}
    >
        <div className={styles.wrapper}>
            <div className={styles.rightWrapper}>
                <span className={styles.name}>{props.category.name}</span>
                {props.category.description && props.category.description.length ? <p className={styles.description}>{props.category.description}</p> : null}
            </div>
        </div>
    </SwippyListItem>
}