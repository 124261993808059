import {SwippyListItem} from "../components/SwippyListItem/SwippyListItem";
import React, {useState} from "react";
import {OrdersAggregatedByDate} from "./ordersAggregatedByDate";
import {CompensationReportModal} from "./CompensationReportModal";

type OrderByDateItemProps = {
    ordersByDate: OrdersAggregatedByDate
}

export function OrderByDateItem(props: OrderByDateItemProps) {


    const [showModal, setShowModal] = useState(false)

    function openReportModal() {
        setShowModal(true)
    }

    function closeReportModal() {
        setShowModal(false)
    }

    return <>
        <CompensationReportModal
            onCancel={closeReportModal}
            visible={showModal}
            orders={props.ordersByDate.orders} date={props.ordersByDate.date} />
            <SwippyListItem
                onClick={openReportModal}

            >

                <div>
                    {props.ordersByDate.date.format("DD MMM YYYY")}
                </div>


            </SwippyListItem>
        </>

}