import {Product} from "../../menu/ProductsScreen/classes/Product";
import React from "react";
import styles from "./ProductPickerItem.module.scss"
import {formatPrice} from "../../formatPrice/formatPrice";
type PropTypes = {
    product: Product,
    onSelected?: (p: Product) => void
    qty?: number
}
export function ProductPickerItem(props: PropTypes) {


    const imgSrc = props.product.getImageSrc()

    return <div onClick={() => {
        if (props.onSelected) props.onSelected(props.product)
    }} className={styles.wrapper}>
        <div className={styles.leftContainer}>
            <div className={styles.productImage}
                 style={{
                     backgroundImage: imgSrc ? `url("${imgSrc}")` : undefined
                 }}
            />
            <h5 className={styles.name}>
                {props.qty ? <span className={styles.qty}>x{props.qty}</span> : null} {props.product.name}
            </h5>
        </div>
        <div className={styles.rightContainer}>
            <span className={styles.price}>{formatPrice(props.product.price)} €</span>
        </div>
    </div>
}