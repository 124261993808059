import {useHistory} from "react-router-dom"
export function useToModifiersScreen() {
    const history = useHistory()


    function toModifiers() {
        history.push(`/product-modifiers`)
    }

    return {
        toModifiers
    }
}