import React from "react";

import {useFetchInStructureOrdersToBeHandled} from "./useFetchInStructureOrdersToBeHandled";
import {InStructureOrdersToBeHandledCtx} from "./InStructureOrdersToBeHandledCtx";
import {useSwippyLoggedUserCtx} from "../swippyUserCtx/useSwippyLoggedUserCtx";


type PropTypes = {
    children?: React.ReactNode
}

export function InStructureOrdersToBeHandledCtxProvider(props: PropTypes) {



    const {loggedUser} = useSwippyLoggedUserCtx()

    const {orders, loading, refetch,lastQueryExecutedAt} = useFetchInStructureOrdersToBeHandled({
        skip: !loggedUser
    })



    async function refetchOrders() {
        await refetch()
    }



    return <InStructureOrdersToBeHandledCtx.Provider value={{
        orders,
        loading,
        refetch: refetchOrders,
        lastQueryExecutedAt
    }}>
        {props.children || null}
    </InStructureOrdersToBeHandledCtx.Provider>


}