import React from "react";
import styles from "./OrderActionsWrapper.module.scss"

type PropTypes = {
    children?: React.ReactNode
}

export function OrderActionsWrapper (props: PropTypes) {
    return <div className={[styles.wrapper, "flex flex-col md:flex-row"].join(" ")}>
        {props.children || null}
    </div>

}