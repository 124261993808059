import React, {useEffect, useState} from "react";
import "./GetTokenScreen.scss"
import {Button, notification} from "antd";
import copy from 'copy-to-clipboard';
import {useSwippyLoggedUserCtx} from "../../swippyUserCtx/useSwippyLoggedUserCtx";

export function GetTokenScreen() {

    const [currentIdToken, setCurrentIdToken] = useState<string>()
    const {loggedUser} = useSwippyLoggedUserCtx()


    async function loadIdToken() {
        if (!loggedUser) return
        setCurrentIdToken(await loggedUser.getIdToken(true))
    }

    useEffect(() => {
        loadIdToken().then(() => {
            //void
        })
    }, [loggedUser ? loggedUser.id : undefined])

    if (!loggedUser) return null
    return <div>
        <span className={"tokenWrapper"}>{currentIdToken}</span>
        <Button onClick={() => {
            if (currentIdToken) {
                copy(currentIdToken)
                notification.open({
                    message: 'Copiato',
                });
            }
        }}>
            Copy
        </Button>
    </div>
}