import React, {useRef} from "react";
import ReactDatePicker from "react-datepicker";
import {TimeFromDateExtractor} from "./TimeFromDateExtractor";
import moment, {Moment} from "moment-timezone"
import {SwippySelect} from "./SwippySelect/SwippySelect";
import {Select, Spin} from "antd";
import {Time} from "../ristopub/Time";
import {LoadingOutlined} from "@ant-design/icons";
import styles from "./SwippyDateTimeInput.module.scss"
type TimeSlot = {
    localFrom: Time,
    localTo: Time
}

type PropTypes = {
    date?: Moment
    onChange: (newDate: Moment) => void
    timeSlots: TimeSlot[]
    dateLabel: string
    timeLabel: string
    loadingSlots?: boolean
    disabled?: boolean
    required?: boolean
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function SwippyDateTimeInput(props: PropTypes) {


    const {current: extractor} = useRef(new TimeFromDateExtractor())

    const timePart = props.date ? extractor.extractTime(moment(props.date), "Europe/Rome") : undefined




    function onChangeDate(
        newDate: Moment
    ) {
        const time = Time.fromString("00:00:00")

        props.onChange(time.getMoment(newDate, "Europe/Rome"))
    }


    function onChangeTime({ value, label }: { value: string, label: string }) {
        const time = Time.fromString(value)

        props.onChange(time.getMoment(props.date || moment(), "Europe/Rome"))
    }

    return <div>
        <div className={"flex flex-col"}>
            <label className={"flex items-center"}>{props.required ? <span className={styles.requiredMark}>*</span> : null}{props.dateLabel}</label>
            <ReactDatePicker
                disabled={props.disabled}
                className={[
                    "w-full mt-2 flex items-center border-gray-300 rounded border p-2 leading-none",
                    props.disabled ? "cursor-not-allowed" : ""
                ].join(" ")}
                locale={"it"}
                dateFormat={"dd MMMM yyyy"}
                selected={props.date?.toDate() || moment().toDate()}
                onChange={(date) => {
                    onChangeDate(date ? moment(date as Date) : moment())
                }} />
        </div>
        <div className={"flex flex-col mt-2"}>
            <label className={"flex items-center"}>{props.required ? <span className={styles.requiredMark}>*</span> : null}{props.timeLabel}</label>
            <Spin wrapperClassName={"mt-2"} indicator={antIcon} spinning={props.loadingSlots}>
                <SwippySelect
                    className={[
                        props.disabled ? "" : ""
                    ].join(" ")}
                    labelInValue={true}
                    disabled={props.disabled}
                    placeholder={"Scegli orario"}
                    value={timePart ? {
                        value: timePart.toString(),
                        label: `${timePart.formatTime()} - ${Time.fromString(timePart.getMoment().add(10, "minutes").format("HH:mm")).formatTime()}`
                    } : undefined}
                    optionFilterProp={"key"}
                    onChange={onChangeTime}>
                    {
                        props.timeSlots.map(s => {
                            return <Select.Option key={s.localFrom.toString()} value={s.localFrom.toString()} >
                                {s.localFrom.formatTime()} - {s.localTo.formatTime()}
                            </Select.Option>
                        })
                    }
                </SwippySelect>
            </Spin>
        </div>

    </div>
}