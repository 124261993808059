import moment, {Moment} from "moment-timezone"

type CreateTimeInput = {
    time: string
}
export class Time {

    private readonly hours: number
    private readonly minutes: number
    private readonly seconds: number

    private constructor(public time: string) {
        const {hours,minutes,seconds} = Time.getNumericTimeParts(time)
        this.hours = hours
        this.minutes = minutes
        this.seconds = seconds
    }

    public formatTime() {
        return this.getMoment().format("HH:mm")
    }






    public static create({time}: CreateTimeInput): Time {
        return new Time(time)
    }


    public static fromString(timeString: string) {
        return new Time(timeString)
    }


    public static fromMoment(date: Moment): Time {
        const hours = date.hours()
        const minutes = date.minutes()
        const seconds = date.seconds()



        const timeString = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`


        return this.create({
            time: timeString
        })

    }


    private static pad(number: number) {
        return number < 10 ? `0${number}` : number.toString()
    }


    public toString() {
        return `${Time.pad(this.hours)}:${Time.pad(this.minutes)}:${Time.pad(this.seconds)}`
    }



    public isAfter(time: Time) {
        const currTime = this.getMoment()

        const otherTime = time.getMoment()

        return currTime.isAfter(otherTime)
    }

    public getMoment(date: Moment = moment(), timezone = "Europe/Rome") {
        return moment(date).tz(timezone).startOf("day").hours(this.getHours())
            .minutes(this.getMinutes())
            .seconds(this.getSeconds())
    }








    public valueOf() {
        return (this.hours * 1000000) + (this.minutes * 1000) + this.seconds
    }


    public getHours() {
        return this.hours
    }

    public getMinutes() {
        return this.minutes
    }

    public getSeconds() {
        return this.seconds
    }


    private static getNumericTimeParts(time: string) {
        const timeParts = time.split(":")
        if (timeParts.length <= 0) throw new Error("Time not valid " + time)

        const [hoursString, minutesString, secondsString] = timeParts

        const hours = parseInt(hoursString || "0") || 0
        const minutes = parseInt(minutesString || "0") || 0
        const seconds = parseInt(secondsString || "0") || 0


        return {
            hours,
            minutes,
            seconds
        }
    }


    public static fromLocalTime(localTime: string, timezone: string) {
        const parts = this.getNumericTimeParts(localTime)
        const date = moment().tz(timezone).hours(parts.hours).minutes(parts.minutes).seconds(parts.seconds)
        return Time.create({
            time: date.local().format("HH:mm:ss")
        })
    }


    public static fromUtcTimeInLocalTime(utcTime: string, date: Moment): Time {
        const parts = this.getNumericTimeParts(utcTime)
        const m = moment(date).utc()
            .startOf("day")
            .hours(parts.hours)
            .minutes(parts.minutes)
            .seconds(parts.seconds)

        return new Time(
            moment(m).tz("Europe/Rome").format("HH:mm:ss")
        )
    }
}