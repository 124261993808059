import {gql, useMutation} from "@apollo/client";
import {UpdateDeliveryOrderInput} from "./UpdateDeliveryOrderInput";
import {Moment} from "moment-timezone";
import {OrderItemInput} from "./OrderItemInput";
import {useRef} from "react";
import {OrderDataMapper} from "../../virtualTable/Order/OrderDataMapper";
import {OrderItemDataMapper} from "../../virtualTable/Order/OrderItem/OrderItemDataMapper";
import {ProductDataMapper} from "../../menu/ProductsScreen/classes/ProductDataMapper";
import {OrderDTO} from "../../virtualTable/Order/OrderDTO";

const BACKOFFICE_UPDATE_DELIVERY_ORDER = gql`
    mutation backofficeUpdateDeliveryOrder($input: BackofficeUpdateDeliveryOrderInput!) {
        backofficeUpdateDeliveryOrder(input: $input) {
            id
            cartId

            orderItems {
                id
                qty
                product {
                    price
                    id
                }
            }

            virtualTable {
                id
            }
        }
    }
`


export function useBackofficeUpdateDeliveryOrder() {


    const {current: orderDataMapper} = useRef(new OrderDataMapper(new OrderItemDataMapper(new ProductDataMapper())))


    const [updateDeliveryOrderMutation, {
        loading
    }] = useMutation<{
        backofficeUpdateDeliveryOrder: OrderDTO
    }>(BACKOFFICE_UPDATE_DELIVERY_ORDER)


    async function updateDeliveryOrder(
        input: UpdateDeliveryOrderInput
    ) {
        const res = await updateDeliveryOrderMutation({
            variables: {
                input: {
                    orderId: input.orderId,
                    customerName: input.customerName,
                    customerNotes: input.customerNotes,
                    customerPhone: input.customerPhone,
                    deliveryAddress: input.deliveryAddress,
                    deliveryDate: input.deliveryDate,
                    items: input.items?.map(i => ({qty: i.qty, productId: i.productId})),
                }
            }
        })


        return res.data?.backofficeUpdateDeliveryOrder ? orderDataMapper.toOrder(res.data.backofficeUpdateDeliveryOrder) : undefined
    }


    return {
        updateDeliveryOrder,
        loading
    }
}