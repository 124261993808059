import React from "react";
import {Col, Divider, Row} from "antd";
import {RistopubAddressInput} from "./RistopubAddressInput/RistopubAddressInput";
import {DeliverySettings} from "./DeliverySettings/DeliverySettings";
import {useSwippyLoggedUserCtx} from "../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {ListLoading} from "../../../components/Loading/ListLoading";
import {User} from "../../../auth/user/User";
import styles from "./DeliverySettingsTab.module.scss"

function MissingGeolocation() {
    return <Col span={24}>
        <p className={styles.missingGeolocation}>
            Per poter abilitare l&apos;opzione "Consegna a domicilio" devi fornire un indirizzo
            valido. Se hai già inserito un indirizzo è possibile che il nostro sistema non sia riuscito
            a localizzarlo. In tal caso ti preghiamo di contattare il supporto.
        </p>
    </Col>

}



export function DeliverySettingsTab() {
    const {loadingUser, loggedUser} = useSwippyLoggedUserCtx()

    if (loadingUser) return <ListLoading/>

    if (!loggedUser) return null

    return <DeliverySettingsTabComponent user={loggedUser} />


}



type PropTypes = {
    user: User
}

export function DeliverySettingsTabComponent(props: PropTypes) {
    return <div>
        <Row gutter={16}>
            <Col  span={24}>
                <Divider>
                    <h4>Indirizzo del tuo locale</h4>
                </Divider>
            </Col>
            <Col span={24}>
                <RistopubAddressInput ristopub={props.user.getRistopub()}/>
            </Col>

            {
                !props.user.getRistopub().address?.geolocation
                ? <MissingGeolocation/>
                : <>
                    <Col  span={24}>
                        <Divider>
                            <h4>Impostazioni consegna a domicilio</h4>
                        </Divider>
                    </Col>
                    <DeliverySettings user={props.user}/>
                </>
            }
        </Row>

    </div>
}