import React, {useState} from "react";
import styles from "./PaymentsSettings.module.scss"
import {User} from "../../../auth/user/User";
import {MissingStripeConnection} from "./MissingStripeConnection/MissingStripeConnection";
import {StripeAccountConnected} from "./StripeAccountConnected/StripeAccountConnected";
import {StripeAccountDTO} from "../../../auth/user/UserDTO";
import {SwippyCheckbox} from "../../../components/SwippyCheckbox/SwippyCheckbox";
import {SwippyButton} from "../../../components/SwippyButton/SwippyButton";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import _ from "lodash";
import {GenericErrorHandler} from "../../../classes/GenericErrorHandler";
import {useUpsertMyRistopub} from "../../../ristopub/useUpsertMyRistopub";
import {Divider} from "antd";


type PropTypes = {
    user: User
}

export function PaymentsSettings(props: PropTypes) {


    const [enableInAppPayments, setEnableInAppPayments] = useState(props.user.getRistopub().enableInAppPayments)
    const [enableCashPayments, setEnableCashPayments] = useState(props.user.getRistopub().enableCashPayments)

    const missingStripeAccountConnection = !props.user.stripeAccount
    const [dirty, setDirty] = useState(false)
    const {upsertMyRistopub, upsertingMyRistopub} = useUpsertMyRistopub()

    async function save() {
        try {
            const ristopub = _.cloneDeep(props.user.getRistopub())
            ristopub.enableInAppPayments = enableInAppPayments
            ristopub.enableCashPayments = enableCashPayments
            await upsertMyRistopub(ristopub)
            setDirty(false)
        } catch (e) {
            const genericErrorHandler = new GenericErrorHandler()
            genericErrorHandler.handleError(e as Error)

        }
    }

    function onChangeInAppPayments(e: CheckboxChangeEvent) {
        setEnableInAppPayments(e.target.checked)
        setDirty(true)
    }

    function onChangeCashPayments(e: CheckboxChangeEvent) {
        setEnableCashPayments(e.target.checked)
        setDirty(true)
    }




    return <div className={styles.wrapper}>
        {missingStripeAccountConnection ? <MissingStripeConnection/> : null}
        {!missingStripeAccountConnection ? <StripeAccountConnected stripeAccount={props.user.stripeAccount as StripeAccountDTO}/> : null}
        <div className={styles.optionsWrapper}>
            <Divider>Opzioni di pagamento</Divider>
            {missingStripeAccountConnection ?
                <span className={styles.missingAccountStripeInfo}>Devi connettere un account
                    Stripe per poter gestire le opzioni di pagamento</span> : null}

            <div>
                <SwippyCheckbox
                    onChange={onChangeCashPayments}
                    disabled={!enableInAppPayments}
                    checked={enableCashPayments || !enableInAppPayments}>
                    Consenti pagamenti alla cassa.
                    {enableInAppPayments ? <span>(Abilitando questa opzione i tuoi clienti potranno scegliere se pagare in App oppure in cassa)</span> : null}
                </SwippyCheckbox>
            </div>

            <div>
                <SwippyCheckbox
                    disabled={missingStripeAccountConnection}
                    onChange={onChangeInAppPayments}
                    checked={enableInAppPayments}>
                    Consenti pagamenti in App
                </SwippyCheckbox>
            </div>

            <div className={styles.saveWrapper}>
                <SwippyButton
                    onClick={save}
                    loading={upsertingMyRistopub}
                    disabled={!dirty} type={"primary"}>
                    Salva
                </SwippyButton>
            </div>
        </div>
    </div>
}