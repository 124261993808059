import {IApolloCredentials} from "./IApolloCredentials";
import Cookies from "js-cookie"
import {ApolloLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

export const ADMIN_LOGIN_AS_USER_COOKINE_NAME = "admin-login-as-user"

export class AdminLoginAsUserCredentials implements IApolloCredentials {

    private getToken() {

        return Cookies.get(ADMIN_LOGIN_AS_USER_COOKINE_NAME)
    }

    getHash(): string {
        return `admin-login-as-user-credential-${this.getToken()}`;
    }

    injectAuth(link: ApolloLink): ApolloLink {
        const token = this.getToken()
        if (!token) return link


        const setHeaderLink = setContext(async (request, previousContext) => {
            return {
                headers: {
                    ...previousContext.headers || {},
                    "x-swippy-login-as-user": token
                }
            }
        });

        return setHeaderLink.concat(link)
    }

}