import {useContext} from "react";
import {TakeawayOrdersToBeHandledCtx} from "./TakeawayOrdersToBeHandledCtx";

export function useTakeawayOrdersToBeHandledCtx() {
    const {orders,loading,refetch,lastQueryExecutedAt} = useContext(TakeawayOrdersToBeHandledCtx)
    return {
        orders,
        loading,
        refetch,
        lastQueryExecutedAt
    }

}