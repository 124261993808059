import {OrdersListItem} from "../../OrdersListItem";
import {PendingOrderActions} from "./PendingOrderActions/PendingOrderActions";
import React from "react";
import {AcceptedOrderActions} from "./AcceptedOrderActions/AcceptedOrderActions";
import {ProposedDateChangeOrderActions} from "./ProposedDateChangeOrderActions/ProposedDateChangeOrderActions";
import {PaidInAppActions} from "./PaidInAppActions/PaidInAppActions";

type PropTypes = {
    order: OrdersListItem
    withAcceptedActions?: boolean
    showMarkAsPaidAction?: boolean
    showMarkAsDeliveredAction?: boolean
}

export function OrderActions(props: PropTypes) {

    const order = props.order.order
    const vt = props.order.virtualTable


    if (order.isPaidInApp() || order.isPaidInAppDelivered()) {
        return <PaidInAppActions
            order={order}
            orderId={order.id}
            virtualTableId={vt.id}
            total={order.total}
            showMarkAsDelivered={order.isPaidInApp()}
            showProposeDateChangeAction={order.isTakeAway() && order.isPaidInApp()}
            customerDisplayName={vt.getDisplayName()}
        />
    } else if (order.isPending()) {
        return <PendingOrderActions
            orderId={order.id}
            order={order}
            showProposeDateChangeAction={order.isTakeAway() || order.isDelivery()}
            virtualTableId={vt.id}
        />
    } else if (order.isAccepted() && props.withAcceptedActions) {
        return <AcceptedOrderActions
            order={order}
            showMarkAsDeliveredAction={props.showMarkAsDeliveredAction}
            showMarkAsPaidAction={props.showMarkAsPaidAction}
            orderId={order.id}
            virtualTableId={vt.id}
        />
    } else if (order.isDelivered() && props.withAcceptedActions) {
        return <AcceptedOrderActions
            order={order}
            showMarkAsPaidAction={true}
            orderId={order.id}
            virtualTableId={vt.id}
        />
    } else if (order.isInProposedDateChangeState()) {
        return <ProposedDateChangeOrderActions
            orderId={order.id}
            virtualTableId={vt.id}
        />
    } else {
        return null
    }


}