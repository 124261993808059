import {useLocation} from "react-router-dom";
import React, {useMemo} from "react";
import {ActiveRouteIdFactory} from "../../../menu/classes/ActiveRouteIdFactory";
import {BreadcrumbsComponent} from "./BreadcrumbsComponent/BreadcrumbsComponent";
import {BreadcrumbsProductsPage} from "./BreadcrumbsProductsPage/BreadcrumbsProductsPage";

export function Breadcrumbs() {
    const location = useLocation()
    const factory = useMemo(() => {
        return new ActiveRouteIdFactory(location)
    }, [location])

    const id = factory.create()

    switch (id) {
        case "takeaway-orders":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Ordini da asporto o consegna"
                }
            ]}/>
        case "orders-to-be-paid":

            return <BreadcrumbsComponent paths={[
                {
                    label: "Ordini da pagare"
                }
            ]}/>
        case "in-structure-orders":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Ordini"
                }
            ]}/>
        case "menu":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Menu"
                }
            ]}/>
        case "productsList":
            return <BreadcrumbsProductsPage/>
        case "settings":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Impostazioni"
                }
            ]}/>
        case "qr-codes-page":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Gestione codici QR"
                }
            ]}/>
        case "virtual-tables-archive":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Storico ordini"
                }
            ]}/>
        case "product-modifiers":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Modificatori prodotto"
                }
            ]}/>
        case "compensation-report":
            return <BreadcrumbsComponent paths={[
                {
                    label: "Corrispettivi"
                }
            ]}/>
        default:
            return null
    }
}