import {useHistory} from "react-router-dom"

export function useToCompensationReport() {

    const history = useHistory()

    function toCompensationReport() {
        history.push("/compensation-report")
    }

    return {
        toCompensationReport
    }
}