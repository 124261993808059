import React, {useState} from "react";
import {SwippySelect} from "../../../../components/SwippySelect/SwippySelect";
import {Time} from "../../../../ristopub/Time";
import {getRangeOptions} from "./getRangeOptions";
import styles from "./SlotRangeInput.module.scss"
import {Moment} from "moment-timezone";

type PropTypes = {
    defaultValue?: [Moment, Moment] | null
    onChange?: (value: [Moment, Moment]) => void
}

export function SlotRangeInput(props: PropTypes) {

    const [start, setStart] = useState(Time.create({
        time: props.defaultValue ? props.defaultValue[0].format("HH:mm:ss") : "00:00:00"
    }))
    const [end, setEnd] = useState(Time.create({
        time: props.defaultValue ? props.defaultValue[1].format("HH:mm:ss") : "00:00:00"
    }))


    function onChange([start,end]: [Time, Time]) {
        if (props.onChange) props.onChange([start.getMoment(), end.getMoment()])
    }





    const options = getRangeOptions()

    return <div className={styles.wrapper}>
        <div className={styles.selectWrapper}>
            <SwippySelect
                onChange={v => {

                    const newStart = Time.create({
                        time: v
                    })

                    setStart(newStart)
                    onChange([newStart,end])
                }}
                value={start.getMoment().format("HH:mm")}>
                {options}
            </SwippySelect>
        </div>
        <div className={styles.selectWrapper}>
            <SwippySelect

                onChange={v => {

                    const newEnd = Time.create({
                        time: v
                    })

                    setEnd(newEnd)
                    onChange([start,newEnd])
                }}
                value={end.getMoment().format("HH:mm")}>
                {options}
            </SwippySelect>
        </div>
    </div>

}