import React from "react";
import {Col, Form, Row, Select} from "antd";
import {SwippyInput} from "../../../components/SwippyInput/SwippyInput";
import {SwippyTextarea} from "../../../components/SwippyTextarea/SwippyTextarea";
import {FormInstance} from "antd/es/form";
import {SwippySelect} from "../../../components/SwippySelect/SwippySelect";
import {OrderType} from "../../../virtualTable/Order/OrderDTO";
import {Product} from "../../../menu/ProductsScreen/classes/Product";
import {EditOrderItemsList} from "../EditOrderItemsList/EditOrderItemsList";
import {EditOrderFormFields} from "./EditOrderFormFields";

import _ from "lodash";
import {useSwippyLoggedUserCtx} from "../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {VirtualTableType} from "../../../virtualTable/VirtualTableDTO";
import {EditOrderErrorKey} from "../EditOrderErrorKey";
import {RistopubAvailabilityInput} from "../../../ristopub/RistopubAvailabilityInput";
import {AvailabilityType} from "../../../ristopub/useRistopubAvailability";

type PropTypes = {
    form: FormInstance<EditOrderFormFields>
    addProduct: (p: Product) => void
    removeProduct: (p: Product) => void
    createdByBackoffice?: boolean
    createOrderAvailableTypes: VirtualTableType[]
    isNew: boolean
    errors: Record<EditOrderErrorKey, boolean>
    onValuesChange?: (values: EditOrderFormFields) => void
}


const {Option} = Select




export function EditOrderForm(props: PropTypes) {

    const {loggedUser: user} = useSwippyLoggedUserCtx()


    if (!user) return null


    return <Form
        preserve={false}
        onValuesChange={(changedValues, values) => {
            if (props.onValuesChange) props.onValuesChange(values)
        }}
        form={props.form}
        layout={"vertical"}


    >
        <Row gutter={16}>
            {
                !props.createdByBackoffice ?
                    <Col span={24}>
                        <div className={"p-2 bg-yellow-200 w-100 mb-2 rounded"}>
                            Quest'ordine è stato effettuato in autonomia dal cliente tramite web app
                        </div>
                    </Col> : null
            }

            <Col span={24}>
                <Form.Item name={"orderType"} label={"Tipologia di ordine"}>
                    <SwippySelect disabled={!props.isNew}>
                        {props.createOrderAvailableTypes.includes("TAKEAWAY") ? <Select.Option value={"TAKEAWAY"}>
                            Da asporto
                        </Select.Option> : null }
                        {props.createOrderAvailableTypes.includes("DELIVERY") ? <Select.Option value={"DELIVERY"}>
                            Consegna a domicilio
                        </Select.Option> : null}
                        {props.createOrderAvailableTypes.includes("ONTABLE") ? <Select.Option value={"ONTABLE"}>
                            Da tavolo
                        </Select.Option> : null}
                    </SwippySelect>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.orderType !== currentValues.orderType}
                >
                    {({ getFieldValue }) => {

                        const orderType = getFieldValue("orderType") as OrderType | undefined

                        return orderType === "TAKEAWAY" || orderType === "DELIVERY" ? <Form.Item name={"customerName"} label={"Nome cliente"}>
                                <SwippyInput disabled={!orderType}/>
                            </Form.Item>
                            :
                            <Form.Item required={true} name={"tableName"} label={"Tavolo"}>
                                <SwippyInput className={[
                                    props.errors.tableName ? "border-red-400" : ""
                                ].join(" ")} disabled={!orderType || !props.isNew}/>
                            </Form.Item>
                    }}


                </Form.Item>
            </Col>



            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.orderType !== currentValues.orderType}
            >
                {({ getFieldValue }) => {
                    const orderType = getFieldValue("orderType") as OrderType | undefined

                    return orderType === "TAKEAWAY" || orderType === "DELIVERY" ? <Col span={24}>
                        <Form.Item name={"customerPhone"} label={"Telefono cliente"}>
                            <SwippyInput hideNumberArrows type={"number"} />
                        </Form.Item>
                    </Col> : null
                }}
            </Form.Item>







            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.orderType !== currentValues.orderType}
            >
                {({ getFieldValue }) => {

                    const orderType = getFieldValue("orderType") as OrderType | undefined

                    return orderType === "TAKEAWAY" ? (
                        <Col span={24}>
                            <Form.Item required={true} name={"dueDate"}
                            help={props.createdByBackoffice ? null : <p className={"mb-2 mt-1 text-red-600"}>
                                Per modificare l'orario di un ordine ricevuto tramite web app richiedi un cambio orario
                                tramite il bottone che trovi nella lista degli ordini
                            </p>}
                            >
                                <RistopubAvailabilityInput
                                    required={true}
                                    disabled={!props.createdByBackoffice}
                                    type={AvailabilityType.TAKEAWAY}
                                    dateLabel={"Data ritiro"}
                                    timeLabel={"Orario ritiro"}/>
                                {/*<SwippySelect*/}

                                {/*    hasError={props.errors.dueDate}*/}
                                {/*    disabled={!props.createdByBackoffice}*/}
                                {/*    labelInValue={true}*/}
                                {/*    optionFilterProp={"key"}*/}
                                {/*    showSearch={true}>*/}
                                {/*    {takeawayAvailableSlots}*/}
                                {/*</SwippySelect>*/}
                            </Form.Item>
                        </Col>
                    ) : null
                }}
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.orderType !== currentValues.orderType}
            >
                {({ getFieldValue }) => {

                    const orderType = getFieldValue("orderType") as OrderType | undefined

                    return orderType === "DELIVERY" ? (
                        <Col span={24}>
                            <Form.Item
                                required={true}
                                name={"deliveryDate"}
                                help={

                                    <div className={"flex flex-col"}>
                                        {/*<p className={"mb-0"}>*/}
                                        {/*    Orario di consegna*/}
                                        {/*</p>*/}
                                        {props.createdByBackoffice ? null : <p className={"mb-2 mt-1 text-red-600"}>
                                            Per modificare l'orario di un ordine ricevuto tramite web app richiedi un cambio orario
                                            tramite il bottone che trovi nella lista degli ordini
                                        </p>}
                                    </div>
                                    }>

                                <RistopubAvailabilityInput
                                    required={true}
                                    disabled={!props.createdByBackoffice}
                                    type={AvailabilityType.DELIVERY}
                                    dateLabel={"Data consegna"}
                                    timeLabel={"Orario consegna"}/>


                                {/*<SwippySelect*/}
                                {/*    hasError={props.errors.deliveryDate}*/}
                                {/*    disabled={!props.createdByBackoffice}*/}
                                {/*    labelInValue={true}*/}
                                {/*    optionFilterProp={"key"}*/}
                                {/*    showSearch={true}>*/}
                                {/*    {deliveryAvailableSlots}*/}
                                {/*</SwippySelect>*/}
                            </Form.Item>
                        </Col>
                    ) : null
                }}
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.orderType !== currentValues.orderType}
            >
                {({ getFieldValue }) => {

                    const orderType = getFieldValue("orderType") as OrderType | undefined

                    return orderType === "DELIVERY" ? (
                        <Col span={24}>
                            <Form.Item required={true} name={"deliveryAddress"} label={
                                <div className={"flex flex-col"}>
                                    <p className={"mb-0"}>
                                        Indirizzo di consegna
                                    </p>

                                </div>
                            }
                            help={props.createdByBackoffice ? null : <p className={"mb-2 mt-1 text-red-600"}>
                                Non è possibile modificare l'indirizzo di consegna di un ordine ricevuto tramite web app
                            </p>}
                            >
                                <SwippyTextarea className={[
                                    props.errors.deliveryAddress ? "border-red-400" : ""
                                ].join(" ")} disabled={!props.createdByBackoffice}  />
                            </Form.Item>
                        </Col>
                    ) : null
                }}
            </Form.Item>

            <Col span={24}>
                <Form.Item name={"customerNotes"} label={"Note cliente"}>
                    <SwippyTextarea />
                </Form.Item>
            </Col>
            <Col span={24}>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.orderItems !== currentValues.orderItems}
                >
                    {({ getFieldValue }) => {

                        return <EditOrderItemsList
                            removeProduct={props.removeProduct}
                            items={getFieldValue("orderItems") || []}
                            addProduct={props.addProduct}
                            renderEmptyList={<p className={"text-red-400"}>
                                Un ordine deve avere dei prodotti associati.<br/>
                                Clicca sul + in alto a destra per aggiungere dei prodotti a quest'ordine
                            </p>}
                        />


                    }}

                </Form.Item>

            </Col>
        </Row>
    </Form>
}