import React, {useEffect, useState} from "react";
import styles from "./ProductImagePreview.module.scss"

type PropTypes = {
    className?: string
    src?: string
    imageFile?: File
    width?: number
    height?: number
}
export function ProductImagePreview(props: PropTypes) {

    const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>()


    function loadImageFileContent(file: File) {
        const reader = new FileReader();

        reader.onload = function(e) {
            if (!e.target) return
            setFileContent(e.target.result)
        }

        reader.readAsDataURL(file);
    }

    useEffect(() => {
        if (props.imageFile) loadImageFileContent(props.imageFile)
        else setFileContent(undefined)
    }, [props.imageFile])

    if (!fileContent && !props.src) return null


    function openImage(src: string) {
        window.open(src, "_blank","noopener")
    }


    const classes = [styles.productImage]
    if (props.className) classes.push(props.className)

    return <div

        onClick={!fileContent ? () => openImage(props.src as string) : undefined}
        className={classes.join(" ")}
        style={{
            width: props.width,
            height: props.height,
            backgroundImage: `url(${fileContent as string || props.src as string})`
        }}/>
}