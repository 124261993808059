import React, {useRef} from 'react';
import 'antd/dist/antd.css';
import 'react-image-crop/dist/ReactCrop.css';
import './App.scss';
import {ApolloProvider} from "@apollo/client";
import {FirebaseUserCredentials} from "./apolloClient/credentials/FirebaseUserCredentials";
import {RootComponent} from "./rootComponent/RootComponent";
import {AdminLoginAsUserCredentials} from "./apolloClient/credentials/AdminLoginAsUserCredentials";
import {ApolloClientFactory} from "./apolloClient/ApolloClientFactory";


function App() {

    const {current: factory} = useRef(new ApolloClientFactory())

    return (
        <ApolloProvider client={factory.create([
            new FirebaseUserCredentials(),
            new AdminLoginAsUserCredentials()
        ])}>
          <RootComponent/>
        </ApolloProvider>
    );
}

export default App;
