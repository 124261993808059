import {Geolocation} from "./AddressDTO";


type CreateAddressInput = {
    address?: string
    postalCode?: string
    city?: string
    province?: string
    country?: string,
    geolocation?: Geolocation
}


export class Address {
    private constructor(
        public address?: string,
        public postalCode?: string,
        public city?: string,
        public province?: string,
        public country?: string,
        public readonly geolocation?: Geolocation
    ) {

    }


    public static create({address,postalCode,city,province,country,geolocation}: CreateAddressInput) {
        return new Address(address,postalCode,city,province,country,geolocation)
    }

    public hasAddress() {
        return !!this.address
    }

    public hasCity() {
        return !!this.city
    }

    public hasProvince() {
        return !!this.province
    }

    public hasCountry() {
        return !!this.country
    }

    public hasPostalCode() {
        return !!this.postalCode
    }


    public toString() {

        const addressComponents: string[] = []
        if (this.hasAddress()) addressComponents.push(this.address as string)
        if (this.hasCity()) addressComponents.push(this.city as string)
        if (this.hasPostalCode()) addressComponents.push(this.postalCode as string)
        if (this.hasProvince()) addressComponents.push(this.province as string)
        if (this.hasCountry()) addressComponents.push(this.country as string)

        return addressComponents.join(", ").trim()
    }
}