import styles from "./EditOrderItemsList.module.scss";
import {ProductPickerModal} from "../../../ProductPickerModal/ProductPickerModal";
import React from "react";
import {Product} from "../../../menu/ProductsScreen/classes/Product";
import {OrderItem} from "../../../virtualTable/Order/OrderItem/OrderItem";
import _ from "lodash";
import {EditOrderItemsListItem} from "./EditOrderItemsListItem/EditOrderItemsListItem";

type PropTypes = {
    addProduct: (p: Product) => void
    removeProduct: (p: Product) => void
    items: OrderItem[]
    renderEmptyList?: React.ReactNode
}

export function EditOrderItemsList(props: PropTypes) {
    return <div>
        <div className={styles.productsListTitleWrapper}>
            <h4 className={styles.name}>Prodotti</h4>
            <ProductPickerModal
                qtyMap={_.reduce(props.items, (res: Record<string,number>,i) => {
                    res[i.product.id as string] = i.qty
                    return res
                }, {})}
                onSelected={props.addProduct}/>
        </div>
        <div className={styles.listWrapper}>
            {
                _.map(props.items, i => {
                    return <EditOrderItemsListItem
                        key={i.product.id}
                        onDelete={() => {
                            props.removeProduct(i.product)
                        }}
                        orderItem={i}/>
                })
            }
            { props.items.length <= 0 ? <>{props.renderEmptyList || null}</> : null}
        </div>
    </div>
}