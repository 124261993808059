import {OrderDataMapper} from "../virtualTable/Order/OrderDataMapper";
import {OrdersAggregatedByDateDTO} from "./OrdersAggregatedByDateDTO";
import {OrdersAggregatedByDate} from "./ordersAggregatedByDate";
import dayjs from "dayjs";

export class OrderByDateDataMapper {

    constructor(
        private orderDataMapper: OrderDataMapper
    ) {
    }

    toEntity(d: OrdersAggregatedByDateDTO): OrdersAggregatedByDate {
        return new OrdersAggregatedByDate(
            dayjs(d.date),
            d.orders.map(o => this.orderDataMapper.toOrder(o))
        )
    }
}