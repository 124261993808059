import {AddressDTO} from "./AddressDTO";
import {Address} from "./Address";

export class AddressDataMapper {
    public toEntity(d: AddressDTO): Address {
        return Address.create({
            city: d.city,
            province: d.province,
            postalCode: d.postalCode,
            address: d.address,
            country: d.country,
            geolocation: d.geolocation
        })
    }
    public toDto(e: Address): AddressDTO {
        return {
            city: e.city,
            province: e.province,
            address: e.address,
            country: e.country,
            postalCode: e.postalCode
        }
    }
}