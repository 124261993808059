import {ProductModifierChoice} from "./ProductModifierChoice";
import React from "react";
import _ from "lodash";
import {nanoid} from "nanoid";
import {SwippyInput} from "../components/SwippyInput/SwippyInput";
import {SwippyInputNumber} from "../components/SwippyInputNumber/SwippyInputNumber";
import {DeleteIcon} from "./DeleteIcon";
import {Warning} from "../components/Warning";
import {SwippySelect} from "../components/SwippySelect/SwippySelect";
import {Select} from "antd";

type Props = {
    value: ProductModifierChoice[]
    onChange: (newValue: ProductModifierChoice[]) => void
    withMaxQty?: boolean
}

export function ProductModifierChoicesInput(props: Props) {

    function addOption() {

        const newChoices = _.cloneDeep(props.value)

        newChoices.push(
            new ProductModifierChoice(
                nanoid(),
                undefined,
                undefined,
                0,
                true
            )
        )

        props.onChange(newChoices)
    }








    function removeOption(id: string) {
        const newChoices = _.cloneDeep(props.value)
        _.remove(newChoices, c => c.id === id)
        props.onChange(newChoices)
        console.log("Remove")
    }

    function getOptionId(id: string) {
        return _.findIndex(props.value, c => c.id === id)
    }
    function onChangeName(id: string, name: string) {
        const newChoices = _.cloneDeep(props.value)
        const choiceId = getOptionId(id)
        if (choiceId < 0) throw new Error("Choice not found")
        newChoices[choiceId].name = name
        props.onChange(newChoices)
    }
    function onChangePrice(id: string, price: number) {
        const newChoices = _.cloneDeep(props.value)
        const choiceId = getOptionId(id)
        if (choiceId < 0) throw new Error("Choice not found")
        newChoices[choiceId].price = price
        props.onChange(newChoices)
    }
    function onChangeUnlimitedChoices(id: string, v: boolean) {
        const newChoices = _.cloneDeep(props.value)
        const choiceId = getOptionId(id)
        if (choiceId < 0) throw new Error("Choice not found")
        newChoices[choiceId].maxQty = v ? -1 : 3
        props.onChange(newChoices)
    }
    function onChangeMaxChoices(id: string, v: number) {
        const newChoices = _.cloneDeep(props.value)
        const choiceId = getOptionId(id)
        if (choiceId < 0) throw new Error("Choice not found")
        newChoices[choiceId].maxQty = v
        props.onChange(newChoices)
    }

    return <div>

        { props.value.length <= 0 ? <Warning
            title={"seleziona almeno un'opzione"}
        /> : null }

        {
            props.value.map((c, index) => {
                return <div key={c.id} className={"mb-2 border border-gray-200 rounded p-3"}>
                    <div className={"flex items-center justify-between"}>
                        <label className={"mb-1 font-bold"}>Opzione #{index+1}</label>
                        <DeleteIcon
                            onClick={() => {
                                removeOption(c.id)
                            }}
                            className={"text-red-500 w-5 h-5 cursor-pointer hover:text-red-700"}/>
                    </div>
                    <div className={"flex-col flex mb-4"}>
                        <label className={"mb-1"}>
                            Nome opzione
                        </label>
                        <SwippyInput
                            onChange={e => onChangeName(c.id, e.target.value)}
                            value={c.name} />
                    </div>
                    <div className={"flex-col flex mb-4"}>
                        <label className={"mb-1"}>
                            Prezzo opzione
                        </label>
                        <SwippyInputNumber
                            formatter={value => `€ ${value}`}
                            parser={value => value ? value.replace(/€\s*/g, '').replace(",", ".") : 0}
                            step={0.01}
                            min={0}
                            onChange={v => onChangePrice(c.id, parseFloat(v as string))}
                            value={c.price} />
                    </div>
                    {
                        props.withMaxQty ? <>
                            <div className={"flex-col flex mb-4"}>
                                <label className={"mb-1"}>
                                    Scelte illimitate
                                </label>
                                <SwippySelect
                                    onChange={v => onChangeUnlimitedChoices(c.id, v === "yes")}
                                    value={c.hasUnlimitedChoices() ? "yes" : "no"}>
                                    <Select.Option value={"yes"}>Sì</Select.Option>
                                    <Select.Option value={"no"}>No</Select.Option>
                                </SwippySelect>
                            </div>
                            <div className={"flex-col flex"}>
                                <label className={"mb-1"}>
                                    Massimo numero di scelte
                                </label>
                                <SwippyInputNumber
                                    disabled={c.hasUnlimitedChoices()}
                                    onChange={v => onChangeMaxChoices(c.id, parseInt(v as string))}
                                    value={c.maxQty}
                                />
                            </div>
                        </> : null
                    }
                </div>
            })
        }
        <div className={"flex justify-center mt-2"}>
            <a
                onClick={e => {
                e.preventDefault()
                addOption()
            }} className={"font-bold cursor-pointer text-swippy-blue hover:text-swippy-dark-blue"}>
                Aggiungi opzione
            </a>
        </div>
    </div>
}