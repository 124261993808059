import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    dsn: "https://b90462fc5fec4db387e77f9a32062f03@o482948.ingest.sentry.io/5534205",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    enabled: process.env.REACT_APP_SENTRY_RELEASE !== "test-release",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});


export {
    Sentry
}
