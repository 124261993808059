import {gql, useMutation} from "@apollo/client";
import {NotificationConfigDTO} from "../../ristopub/NotificationConfigDTO";
import {GraphqlServiceName} from "../../apolloClient/GraphqlServiceName";

const UPSERT_NOTIFICATION_CONFIG_MUTATION = gql`
    mutation upsertRistopubNotificationConfig($ristopubId: String!, $slackPushUrl: String!) {
        upsertRistopubNotificationConfig(ristopubId: $ristopubId, slackPushUrl: $slackPushUrl) {
            slackPushUrl
            ristopubId
        }
    }
`
export function useUpsertNotificationSettings() {
    const [upsertRistopubNotificationConfigMutation, {
        loading
    }] = useMutation<{
        upsertRistopubNotificationConfig: NotificationConfigDTO
    }, {
        ristopubId: string,
        slackPushUrl: string
    }>(UPSERT_NOTIFICATION_CONFIG_MUTATION, {
        context: {
            serviceName: GraphqlServiceName.NOTIFICATION_SERVER
        }
    })


    async function upsertNotificationSettings(
        ristopubId: string,
        slackPushUrl: string
    ) {
        await upsertRistopubNotificationConfigMutation({
            variables: {
                ristopubId,
                slackPushUrl
            }
        })
    }


    return {
        upsertNotificationSettings,
        loading
    }
}