import React from "react";
import {SwippyListItem} from "../../../../components/SwippyListItem/SwippyListItem";
import styles from "./TimeSlotItemComponent.module.scss"

type PropTypes = {
    start: string
    end: string
    onDelete?: () => Promise<void> | void
    onClick?: () => Promise<void> | void
}

export function TimeSlotItemComponent(props: PropTypes) {
    return <SwippyListItem
        onClick={props.onClick}
        onDelete={props.onDelete}>
        <div className={styles.wrapper}>
            <p className={styles.timeWrapper}>
                <span className={styles.start}>{props.start}</span> - <span className={styles.end}>{props.end}</span>
            </p>
        </div>
    </SwippyListItem>
}
