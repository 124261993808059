import {OrderActionsWrapper} from "../OrderActionsWrapper/OrderActionsWrapper";
import {SwippyButton} from "../../../../../components/SwippyButton/SwippyButton";
import React, {useState} from "react";
import {useOrdersActions} from "../useOrdersActions";
import {GenericErrorHandler} from "../../../../../classes/GenericErrorHandler";
import {Modal} from "antd"
import {formatPrice} from "../../../../../formatPrice/formatPrice";
import {ProposeDateChangeModal} from "../ProposeDateChangeModal/ProposeDateChangeModal";

import {useSwippyLoggedUserCtx} from "../../../../../swippyUserCtx/useSwippyLoggedUserCtx";
import {Order} from "../../../../../virtualTable/Order/Order";
import {AvailabilityType} from "../../../../../ristopub/useRistopubAvailability";

type PropTypes = {
    order: Order
    orderId?: string
    total?: number
    customerDisplayName?: string
    virtualTableId?: string
    showProposeDateChangeAction?: boolean
    showMarkAsDelivered?: boolean
}
export function PaidInAppActions(props: PropTypes) {

    const {refundOrderMutation, markOrderAsDeliveredMutation} = useOrdersActions()
    const [refunding, setRefunding] = useState(false)
    const {loggedUser: user} = useSwippyLoggedUserCtx()
    const [showProposeChangeDateModal, setShowProposeChangeDateModal] = useState(false)
    const [markingAsDelivered, setMarkingAsDelivered] = useState(false)


    async function markAsDelivered() {
        setMarkingAsDelivered(true)
        try {
            await markOrderAsDeliveredMutation({
                variables: {
                    input: {
                        virtualTableId: props.virtualTableId,
                        orderId: props.orderId
                    }
                }
            })
        } finally {
            setMarkingAsDelivered(false)
        }
    }


    async function refund() {
        setRefunding(true)
        try {
            await refundOrderMutation({
                variables: {
                    input: {
                        orderId: props.orderId
                    }
                }
            })
        } catch (e) {
            const errorHandler = new GenericErrorHandler()
            errorHandler.handleError(e as Error)
        } finally {
            setRefunding(false)
        }
    }


    function requestConfirmation() {
        Modal.confirm({
            title: "Sei sicuro di voler procedere?",
            content: `Stai per rimborsare ${formatPrice(props.total)} € a ${props.customerDisplayName}`,
            onOk: refund
        })
    }

    function getMarkAsDeliveredLabel() {
        if (props.order.selfPickup) return "Pronto per il ritiro"
        else return "Contrassegna come consegnato"
    }

    function closeProposeChangeDateModal() {
        setShowProposeChangeDateModal(false)
    }

    function openProposeChangeDateModal() {
        setShowProposeChangeDateModal(true)
    }

    if (!props.virtualTableId || !props.orderId || !user) return null


    const buttons = [
        <SwippyButton
            key={0}
            gaCategory={"order_management"}
            gaLabel={"order_management_refund_order"}
            onClick={requestConfirmation}
            loading={refunding}
            type={"link"}>
            Rimborsa
        </SwippyButton>
    ]


    if (props.showProposeDateChangeAction) {
        buttons.push(
            <SwippyButton
                key={1}
                gaCategory={"order_management"}
                gaLabel={"order_management_propose_date_change_action_paid_in_app"}
                onClick={openProposeChangeDateModal}
                type={"link"}>
                Proponi cambio orario
            </SwippyButton>
        )
    }
    if (props.showMarkAsDelivered) {
        buttons.push(
            <SwippyButton
                key={2}
                loading={markingAsDelivered}
                gaCategory={"order_management"}
                gaLabel={"order_management_mark_as_delivered_action_paid_in_app"}
                onClick={markAsDelivered}
                type={"link"}>
                {getMarkAsDeliveredLabel()}
            </SwippyButton>
        )
    }



    const ristopub = user.getRistopub()

    return <OrderActionsWrapper>
        <ProposeDateChangeModal
            initialDate={props.order?.isDelivery() ? props.order?.deliveryDate : props.order?.dueDate}
            type={props.order?.isDelivery() ? AvailabilityType.DELIVERY : AvailabilityType.TAKEAWAY}
            ristopub={ristopub}
            onCancel={closeProposeChangeDateModal}
            visible={showProposeChangeDateModal}
            orderId={props.orderId}
            virtualTableId={props.virtualTableId}
        />
        <div
            className={[
                "flex flex-col md:flex-row items-center w-full",
                buttons.length > 1 ? "md:justify-between" : "md:justify-center"
            ].join(" ")}>
            {buttons}
        </div>
    </OrderActionsWrapper>
}