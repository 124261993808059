import {gql, useMutation} from "@apollo/client";
import {OpenDayItem} from "./OpenDayItem";
import {useCurrentUser} from "../../../auth/hooks/useCurrentUser";

const UPSERT_OPEN_DAYS = gql`
    mutation upsertOpenDays($input: UpsertOpenDaysInput!) {
        upsertOpenDays(input: $input)
    }
    
`

export function useUpsertOpenDays() {
    const [upsertOpenDaysMutation, {
        loading
    }] = useMutation(UPSERT_OPEN_DAYS)

    async function upsertOpenDays(items: OpenDayItem[]) {


        await upsertOpenDaysMutation({
            variables: {
                input: {
                    openDays: items.map(i => {
                        return {
                            dayOfWeek: i.dayOfWeek,
                            closeAllDay: i.closeAllDay,
                            timeSlots: i.timeSlots.map(ts => {
                                return {
                                    localFrom: ts.localFrom,
                                    localTo: ts.localTo,
                                }
                            }),
                            timezone: "Europe/Rome"
                        }
                    }),
                }
            }
        })

    }


    return {
        upsertOpenDays,
        loading
    }
}