import {CustomerEmailTemplateRendererDTO} from "./CustomerEmailTemplateRendererDTO";
import {CustomerEmailTemplateRenderer} from "./CustomerEmailTemplateRenderer";

export class CustomerEmailTemplateRendererDataMapper {
    toEntity(d: CustomerEmailTemplateRendererDTO): CustomerEmailTemplateRenderer {
        return new CustomerEmailTemplateRenderer(
            d.customerEmailType,
            d.variables
        )
    }
}