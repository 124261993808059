import Axios from "axios"
import _ from "lodash";


type AwsPostUrlResponse = {
    url: string,
    fields: Record<string, any>
}


export class ImageUploader {

    private static async getPresignedPostUrl(): Promise<AwsPostUrlResponse> {
        const url = `${process.env.REACT_APP_SWIPPY_IMAGE_SERVICE_BASE_URL}/createPresignedPost`
        return (
            await Axios.post(url)
        ).data as AwsPostUrlResponse
    }

    public async uploadImage(image: File) {
        const postUrl = await ImageUploader.getPresignedPostUrl()


        const formData = new FormData()
        formData.append("acl", "public-read")

        _.forEach(postUrl.fields, (v,k) => {
            formData.append(k, v)
        })

        formData.append("file", image)


        console.log("data", formData)





        await Axios.post(postUrl.url, formData, {
            headers: {
                "Content-Type": image.type
            }
        })


        return {
            s3Key: postUrl.fields.key,
            src: `https://${postUrl.fields.bucket}.s3.eu-central-1.amazonaws.com/${postUrl.fields.key}`
        }


    }


}
