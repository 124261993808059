import {IErrorHandler} from "../interfaces/IErrorHandler";
import {Modal} from "antd"
import React from "react";

const {error} = Modal
export class GenericErrorHandler implements IErrorHandler {
    handleError(e: Error): void {
        error({
            okButtonProps: {
                className: "rounded-full"
            },
            title: "Ops, qualcosa è andato storto.",
            content: <div>
                <p>Puoi segnalare questo errore agli amministratori.</p>
                <p>
                    {e.message}
                </p>
            </div>

        })
    }

}