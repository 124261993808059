import {AvailabilityType, useRistopubAvailability} from "./useRistopubAvailability";
import React, {useState} from "react";
import {Moment} from "moment-timezone";
import {SwippyDateTimeInput} from "../components/SwippyDateTimeInput";
import moment from "moment-timezone";
import _ from "lodash";

import {Time} from "./Time";
type PropTypes = {
    type: AvailabilityType
    dateLabel: string
    timeLabel: string
    value?: Moment
    onChange?: (newDate: Moment) => void
    disabled?: boolean
    required?: boolean
}

export function RistopubAvailabilityInput(props: PropTypes) {


    const {availabilities, loading} = useRistopubAvailability(props.type, props.value ? moment(props.value).hours(12) : undefined)


    function onChange(newDate: Moment) {
        if (props.onChange) props.onChange(newDate)
    }
    return <SwippyDateTimeInput
        required={props.required}
        disabled={props.disabled}
        loadingSlots={loading}
        date={props.value}
        onChange={onChange}
        timeSlots={_.map(availabilities, av => {
            return {
                localFrom: av.start,
                localTo: av.end
            }
        })}
        dateLabel={props.dateLabel}
        timeLabel={props.timeLabel} />
}