import styles from "./SwippyListItem.module.scss"
import {Dropdown, Menu, Modal} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {SwippyButton} from "../SwippyButton/SwippyButton";
import {Loading} from "../Loading/Loading";
import {GaProps} from "../../safeGtag/types";
import {TrackingContainer} from "../TrackingContainer/TrackingContainer";

type PropTypes = {
    children?: React.ReactNode
    onClick?: () => void
    onDelete?: () => Promise<void> | void
    onEdit?: () => Promise<void> | void

    editGaProps?: GaProps
    deleteGaProps?: GaProps
    clickGaProps?: GaProps

}


const {confirm} = Modal

export function SwippyListItem(props: PropTypes) {

    const [deleting,setDeleting] = useState(false)

    const menu = (
        <Menu>
            {props.onEdit ? <Menu.Item>
                <SwippyButton
                    {...props.editGaProps || {}}
                    type={"link"}
                    onClick={async () => {
                        if (props.onEdit) await props.onEdit()

                    }}>
                    Modifica
                </SwippyButton>
            </Menu.Item> : null}
            {props.onDelete ? <Menu.Item>
                <SwippyButton
                    {...props.deleteGaProps || {}}
                    type={"link"}
                    onClick={() => {
                        if (!props.onDelete) return
                        confirm({
                            title: "Sicuro di voler procedere",
                            content: "Se procedi non potrai tornare indietro",
                            okText: "Sì, elimina",
                            cancelText: "No, annulla",
                            onOk: async () => {
                                if (!props.onDelete) return
                                setDeleting(true)

                                try {
                                    await props.onDelete()
                                } finally {
                                    setDeleting(false)
                                }
                            }
                        })
                    }}>
                    Elimina
                </SwippyButton>
            </Menu.Item> : null}

        </Menu>
    );

    return <div className={styles.itemWrapper}>

        <TrackingContainer
            style={{
                width: "100%"
            }}
            {...props.clickGaProps || {}}

        >
            <div onClick={props.onClick} className={[styles.rightContainer, styles.clickable].join(" ")}>
                {props.children || null}
            </div>
        </TrackingContainer>

        {props.onDelete || props.onEdit ? <Dropdown trigger={["click"]} overlay={menu}>
                <div className={[styles.actionMenu, styles.clickable].join(" ")}>
                    {deleting ? <Loading size={18}/> : <EllipsisOutlined className={styles.itemMenuDots}/>}
                </div>
            </Dropdown> : null
        }
    </div>
}