import {ProductModifierType} from "./ProductModifierType";
import {ProductModifierChoice} from "./ProductModifierChoice";

export class ProductModifier {
    constructor(
        public id: string,
        public type: ProductModifierType,
        public name?: string,
        public description?: string,
        public choices: ProductModifierChoice[] = [],
        public maxSelectableChoices?: number,
        public required?: boolean

    ) {
    }
}