import {OrdersListItem} from "../OrdersList/OrdersListItem";
import {Order} from "../../virtualTable/Order/Order";
import {Moment} from "moment-timezone";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {VirtualTable} from "../../virtualTable/VirtualTable";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import styles from "./OrdersListPage.module.scss"
import {SwippyTitleWithCta} from "../../components/MainLayout/SwippyTitleWithCta/SwippyTitleWithCta";
import {ListLoading} from "../../components/Loading/ListLoading";
import {OrdersList} from "../OrdersList/OrdersList";
import {EditOrderModal} from "../EditOrderModal/EditOrderModal";
import {OrderType} from "../../virtualTable/Order/OrderDTO";
import {VirtualTableType} from "../../virtualTable/VirtualTableDTO";

type PropTypes = {
    loading: boolean,
    orders: OrdersListItem[]
    lastQueryExecutedAt: Moment
    refetch: () => Promise<void>
    title: string
    defaultNewOrderType?: VirtualTableType
    createOrderAvailableTypes: VirtualTableType[]
}

export function OrdersListPage(props: PropTypes) {
    const [order, setOrder] = useState<OrdersListItem | undefined>(undefined)


    useEffect(() => {
        props.refetch().then(() => {})
    }, [])


    const openNewOrderModal = useCallback(
        () => {
            const vt = VirtualTable.create({
                name: "",
                status: "OPENED",
                type: props.defaultNewOrderType || "ONTABLE"
            })


            const order = Order.create({
                status: {
                    id: "PENDING"
                },
                createdByBackoffice: true
            })


            setOrder({
                order,
                virtualTable: vt
            })
        },
        [],
    );

    const closeNewOrderModal = useCallback(() => {
        setOrder(undefined)
    }, [])


    const orders = useMemo(() => props.orders, [props.orders])


    return <MainLayout withHeader={true} withSider={true}>
        {
            <div className={styles.wrapper}>
                <EditOrderModal
                    createOrderAvailableTypes={props.createOrderAvailableTypes}
                    isNew={true}
                    defaultNewOrderType={props.defaultNewOrderType}
                    onCancel={closeNewOrderModal}
                    order={order}
                />
                <SwippyTitleWithCta
                    title={props.title}
                    ctaLabel={"Nuovo ordine"}
                    onClick={openNewOrderModal}
                />
                {props.loading ? <div className={styles.loadingWrapper}>
                    <ListLoading/>
                </div> : <OrdersList
                    showAcceptedActions={true}
                    showMarkAsDeliveredAction={true}
                    showNewTag={true}
                    showMinutesCounter={true}
                    orders={orders}/>}
            </div>
        }
    </MainLayout>
}